import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { TextField } from '@material-ui/core';
import { Rule, validate, validatePassword } from '../../utils/formValidator';
import validator from 'validator';

const PasswordField = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const [value, setValue] = useState('');

  const [validations, setValidations] = useState({
    rules: [
      Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
      Rule(
        'matches',
        ['true'],
        true,
        'A senha deve possuir no mínimo 8 caracteres e incluir símbolos, letras e números e não pode conter espaços',
        value => {
          return String(validatePassword(value));
        }
      ),
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const onChange = e => {
    setValue(e.target.value);
  };
  const onBlur = () => {
    validateField();
  };

  const validateField = () => {
    let currentRules = props.required || value ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(value, currentRules),
    });
  };

  const setFieldValue = value => {
    setValue(value);
  };

  const resetField = () => {
    setValue('');
    setValidations({
      ...validations,
      alreadyValidated: false,
      isInvalid: false,
      message: '',
    });
  };

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: validator.trim(value),
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    resetField,
  }));

  return (
    <TextField
      {...props}
      type={'password'}
      ref={inputRef}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={validations.isInvalid}
      helperText={(validations.isInvalid && validations.message) || ' '}
      style={{ minHeight: 68 }}
    />
  );
});

export default PasswordField;
