import React, { useReducer, useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import Typography from '@material-ui/core/Typography';

import Report from 'components/Report';
import {
  EventReport,
  BatteryReport,
  ObservationsReport,
  HistoryReport,
  PositionsReport,
  BatteryChartReport,
  TemperatureChartReport,
  PathReport,
  LogsReport,
  EquipmentReport,
} from 'pages/Reports';
import SubBar from 'components/SubBar';
import SubRoutes from 'components/SubRoutes';
import { getField, boolToString } from 'utils/helpers';

import ReportFilterContext from 'pages/Reports/ReportFilterContext';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import HomeFilterContext from 'pages/HomeFilterContext';

moment.locale('pt-br');

const pages = [
  {
    name: 'Histórico',
    path: '/historico',
    component: HistoryReport,
    permission: 'ViewHistoryReport',
  },
  {
    name: 'Observações',
    path: '/observacoes',
    component: ObservationsReport,
    permission: 'ViewObservationReport',
  },
  {
    name: 'Bateria',
    path: '/bateria',
    component: BatteryReport,
    permission: 'ViewBatteryReport',
  },
  {
    name: 'Posições',
    path: '/posicoes',
    component: PositionsReport,
    permission: 'ViewPositionReport',
  },
  {
    name: 'Gráfico de Bateria',
    path: '/grafico-bateria',
    component: BatteryChartReport,
    permission: 'ViewBatteryChartReport',
  },
  {
    name: 'Gráfico de Temperatura',
    path: '/grafico-temperature',
    component: TemperatureChartReport,
    permission: 'ViewTemperatureChartReport',
  },
  {
    name: 'Trajeto',
    path: '/trajeto',
    component: PathReport,
    permission: 'ViewPathReport',
  },
  {
    name: 'Logs',
    path: '/logs',
    component: LogsReport,
    permission: 'ViewLogReport',
  },
  {
    name: 'Equipamentos',
    path: '/equipment',
    component: EquipmentReport,
    permission: 'ViewEquipmentReport',
  },
];

const ACTIONS = {
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATE_EQUIPMENT: 'UPDATE_EQUIPMENT',
  UPDATE_INITIAL_DATE: 'UPDATE_INITIAL_DATE',
  UPDATE_FINAL_DATE: 'UPDATE_FINAL_DATE',
};
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_COMPANY:
      return { ...state, company: action.payload };
    case ACTIONS.UPDATE_EQUIPMENT:
      return { ...state, equipment: action.payload };
    case ACTIONS.UPDATE_INITIAL_DATE:
      return { ...state, initialDate: action.payload };
    case ACTIONS.UPDATE_FINAL_DATE:
      return { ...state, finalDate: action.payload };
    default:
      return state;
  }
};

function Relatorios(props) {
  const { classes, match } = props;

  const homeFilterContext = useContext(HomeFilterContext);

  const [filters, dispatch] = useReducer(reducer, {
    company: homeFilterContext ? homeFilterContext.company : null,
    equipment: homeFilterContext ? homeFilterContext.equipment : null,
    finalDate: moment().endOf('day'),
    initialDate: moment().subtract(moment.duration(7, 'days')).startOf('day'),
  });

  const updateCompany = company => {
    dispatch({ type: ACTIONS.UPDATE_COMPANY, payload: company });
  };

  const updateEquipment = equipment => {
    dispatch({ type: ACTIONS.UPDATE_EQUIPMENT, payload: equipment });
  };

  const updateInitialDate = initialDate => {
    dispatch({ type: ACTIONS.UPDATE_INITIAL_DATE, payload: initialDate });
  };

  const updateFinalDate = finalDate => {
    dispatch({ type: ACTIONS.UPDATE_FINAL_DATE, payload: finalDate });
  };

  useEffect(() => {
    homeFilterContext.resetFilters();
  }, []);

  return (
    <div className={classes.root}>
      <SubBar items={pages} match={match} />
      {/* Para definir as rotas precisamos importar o compoenente SubRoutes */}
      <ReportFilterContext.Provider
        value={{
          ...filters,
          updateCompany,
          updateEquipment,
          updateInitialDate,
          updateFinalDate,
        }}
      >
        <SubRoutes defaultPage={0} pages={pages} match={match} />
      </ReportFilterContext.Provider>
    </div>
  );
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
});
export default withRouter(withStyles(styles)(Relatorios));
