import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import { AutocompleteField } from 'components/fields';
import { useFetch, useAuthenticated } from 'hooks/fetch';

const inputHeight = 38;

const styles = theme => ({
  inputContainer: {
    marginRight: 17,
    borderRadius: 16,
    backgroundColor: theme.palette.background.white,
    minHeight: inputHeight,
    width: 300,
    paddingRight: 10,
  },
  inputContainerBig: {
    marginRight: 17,
    borderRadius: 16,
    width: 399,
    minHeight: inputHeight,
    backgroundColor: theme.palette.background.white,
  },
  placeholder: {
    fontWeight: 600,
    color: theme.palette.gray.gray_5,
    opacity: 1,
    fontSize: 14,
    paddingLeft: 18,
  },
  input: {
    paddingLeft: 20,
    height: inputHeight,
  },
  inputRoot: {
    marginTop: '0px !important',
  },
  inputLabel: {
    paddingLeft: 20,
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.gray.gray_5,
    marginTop: -12,
  },
  shrink: {
    fontSize: 17,
    marginTop: -17,
  },
});

const FilterAutoComplete = (props) => {
  const {
    classes,
    name,
    isLoading,
    setIsLoading,
    fields,
    label,
    path,
    getOptionLabel,
    noOptionsMessage,
    loadingMessage,
    onChange,
    whenIsLoading,
    filterHandler,
    placeholder,
    showPlaceholder,
    hideLabel,
    additionalStartPath,
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const [fetchUrl, setFetchUrl] = useState(`${path}${additionalStartPath ? additionalStartPath : ''}`);

  // Load data
  const { data } = useAuthenticated(useFetch, fetchUrl, {
    afterFetch() {
      setIsLoading({ ...isLoading, [name]: false });
    },
  });

  // Sort  data
  useEffect(() => {
    // const c = data && data.data.sort((a, b) => a.name > b.name ? 1 : -1) || [];
    if (Array.isArray(data)) {
      setOptions(data);
    } else {
      setOptions((data && data.data) || []);
    }
  }, [data]);

  // detects when someone set me to isLoading=true
  useEffect(() => {
    if (isLoading[name]) {
      whenIsLoading({ ...props, fetchUrl, setFetchUrl });
    }
  }, [isLoading[name]]);

  return (
    <AutocompleteField
      {...rest}
      label={!hideLabel && label}
      placeholder={(showPlaceholder && placeholder) || ''}
      name={name}
      options={options}
      isLoading={isLoading[name]}
      getOptionLabel={opt => getOptionLabel(opt)}
      getOptionValue={opt => opt._id}
      onChange={(opt) => {
        onChange({ ...props, opt });
      }}
      innerRef={fields[name]}
      noOptionsMessage={() => noOptionsMessage}
      loadingMessage={() => loadingMessage}
      isClearable={true}
      menuContainerStyle={{
        ...props.menuContainerStyle,
        zIndex: 999,
      }}
      disableUnderline={true}
      fullWidth={false}
      classes={{ placeholder: classes.placeholder }}
      InputProps={{
        ...props.inputProps,
        disableUnderline: true,
        className: classes.input,
        classes: {
          root: classes.inputRoot,
          // input: classes.input,
        },
      }}
      InputLabelProps={{
        className: classes.inputLabel,
        classes: {
          shrink: classes.shrink,
        },
      }}
      InputContainerClass={classes.inputContainer}
      value={props.value || undefined}
    />
  );
};

export default withRouter(withStyles(styles)(FilterAutoComplete));
