import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Rule, validate } from '../../utils/formValidator';
import Typography from '@material-ui/core/Typography';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    width: 28,
    height: 28,
    borderRadius: 7,
    marginRight: 21,
  },
  checkboxLabel: {
    fontSize: 16,
    lineHeight: 1,
    whiteSpace: 'nowrap',
  },
}));

const CheckboxField = forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const classes = useStyles();

  const { defaultValue = true, label, ...rest } = props;
  const [value, setValue] = useState(defaultValue);

  const onChange = e => {
    setValue(e.target.checked);
  };

  const [validations, setValidations] = useState({
    rules: [Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório')],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const onBlur = () => {
    validateField();
  };

  const validateField = () => {
    let currentRules = rest.required || value ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(value, currentRules),
    });
  };

  const setFieldValue = value => {
    setValue(value);
  };

  useImperativeHandle(ref, () => ({
    inputRef,
    raw: value,
    value,
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue,
  }));

  return (
    <div className={classes.container}>
      <Checkbox
        {...rest}
        onBlur={onBlur}
        className={classes.checkbox}
        ref={inputRef}
        onChange={onChange}
        checked={value}
        color="primary"
        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 28 }} />}
        checkedIcon={<CheckBoxIcon style={{ fontSize: 28 }} />}
      />
      <Typography className={classes.checkboxLabel}>{label}</Typography>
    </div>
  );
});

export default CheckboxField;
