import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Modal from './Modal';
import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { validatePassword } from 'utils/formValidator';

const ERROR_MESSAGES = {
  REQUIRED: 'Campo obrigatório',
  MATCH: 'Valores informados não são os mesmos',
  STRENGTH:
    'A senha deve possuir no mínimo 8 caracteres e incluir símbolos, letras e números e não pode conter espaços',
  PASSWORD_ALREADY_USED: 'As suas últimas 5 senhas não podem ser utilizadas',
};

const PasswordChangeSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .test('password-strength', ERROR_MESSAGES.STRENGTH, password => {
      return validatePassword(password);
    }),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], ERROR_MESSAGES.MATCH)
    .required(ERROR_MESSAGES.REQUIRED),

  currentPassword: Yup.string().required(ERROR_MESSAGES.REQUIRED),
});

function ModalChangePassword({
  isVisible,
  setIsVisible,
  isForced = false,
  setIsForced = () => {},
}) {
  let response;

  const subtitle = useMemo(() => {
    return (
      isForced &&
      'Por questões de segurança, é necessário alterar sua senha a cada dois meses'
    );
  }, [isForced]);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      response = await (
        await fetchAuthenticated('put', 'user/change-password', values)
      ).json();

      if (!response.ok) {
        throw new Error();
      } else {
        emitEvent('showSnack', {
          message: 'Senha alterada com sucesso',
          type: 'success',
        });
        setSubmitting(false);
        setIsVisible(false);
        setIsForced(false);
      }
    } catch (err) {
      emitEvent('showSnack', {
        message:
          ERROR_MESSAGES[response.error] || 'Não foi possível alterar a senha',
        type: 'error',
      });
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={{
        newPassword: '',
        confirmPassword: '',
        currentPassword: '',
      }}
      validationSchema={PasswordChangeSchema}
      onSubmit={onSubmit}
      render={({
        errors,
        touched,
        handleChange,
        handleSubmit,
        submitForm,
        isSubmitting,
      }) => (
        <Grid
          container
          title="Alterar senha"
          subtitle={subtitle}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          buttonLabel="Salvar"
          preventDefaultSubmit
          handleSubmit={submitForm}
          component={Modal}
          isLoading={isSubmitting}
          hideCloseButton={isForced}
        >
          <Grid
            container
            direction="column"
            justify="space-between"
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid container justify="space-between" wrap="nowrap">
              <Grid
                item
                xs={6}
                component={TextField}
                type="password"
                name="currentPassword"
                label="Senha atual"
                onChange={handleChange}
                error={
                  touched.currentPassword && Boolean(errors.currentPassword)
                }
                helperText={
                  touched.currentPassword ? errors.currentPassword : ''
                }
                style={{ paddingRight: 20 }}
              />
            </Grid>
            <Grid container justify="space-between" wrap="nowrap">
              <Grid
                item
                xs={6}
                component={TextField}
                type="password"
                name="newPassword"
                label="Nova senha"
                onChange={handleChange}
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword ? errors.newPassword : ''}
                style={{ marginRight: 20 }}
              />

              <Grid
                item
                xs={6}
                component={TextField}
                type="password"
                name="confirmPassword"
                label="Confirmar nova senha"
                onChange={handleChange}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={
                  touched.confirmPassword ? errors.confirmPassword : ''
                }
                style={{ marginLeft: 20 }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    />
  );
}

ModalChangePassword.propTypes = {
  setIsVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

ModalChangePassword.defaultProps = {
  isVisible: true,
};

export default ModalChangePassword;
