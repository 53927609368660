import {
  datetimeFormatter,
  durationFormatter,
  percentageFormatter,
  temperatureFormatter,
  speedFormatter,
  defaultFormatter,
  imeiFormatter,
  latlongFormatter,
  lastValidPositionDateFormatter,
} from 'utils/formatters';
import { minutesToTimeString, boolToString } from 'utils/helpers';

export const REPORT_COLUMNS = [
  {
    label: 'Empresa',
    value: ({ equipment }) =>
      equipment
        ? defaultFormatter(
            equipment.currentCompany ? equipment.currentCompany.name : undefined
          )
        : '',
  },
  {
    label: 'Equipamento',
    value: ({ equipment, imei }) => (equipment ? equipment.shortImei : imei),
    sort: 'equipment.shortImei',
  },
  {
    label: 'Data/Hora',
    value: ({ timestamp }) => datetimeFormatter(timestamp),
    sort: 'timestamp',
  },
  {
    label: 'Intervalo',
    value: ({ positionSendInterval }) =>
      minutesToTimeString(positionSendInterval),
    sort: 'positionSendInterval',
  },
  {
    label: 'Bat.',
    value: ({ adjustedInternalBatteryPercentage, internalBatteryPercentage }) =>
      percentageFormatter(
        Number(
          (adjustedInternalBatteryPercentage || internalBatteryPercentage) / 100
        )
      ),
    sort: 'internalBatteryPercentage',
  },
  {
    label: 'Velocidade',
    value: ({ gpsSpeed }) => speedFormatter(gpsSpeed),
    sort: 'gpsSPeed',
  },
  {
    label: 'Satélites',
    value: ({ gpsSatelliteNumber }) => defaultFormatter(gpsSatelliteNumber),
    sort: 'gpsSatelliteNumber',
  },
  {
    label: 'Posição',
    value: ({ positionIndicator }) =>
      (positionIndicator && positionIndicator[0]) || '-',
    sort: '',
  },
  {
    label: 'Comunicação',
    value: ({ communicationType }) => communicationType || '-',
    sort: '',
  },
  {
    label: 'RF',
    value: ({ status }) =>
      status && (status.transmitter434Mhz || status.transmitter928Mhz)
        ? 'Ligado'
        : 'Desligado',
    sort: '',
  },
  {
    label: 'Umidade/T.Baú',
    value: ({ temperatureHumidity }) =>
      (temperatureHumidity &&
        `${temperatureHumidity.humidity} / ${temperatureHumidity.temperature}`) ||
      '-',
    sort: '',
  },
  {
    label: 'Temperatura',
    value: ({ temperature }) => temperatureFormatter(temperature),
    sort: 'temperature',
  },
  {
    label: 'Sinal',
    value: ({ gsmModelSignal }) =>
      percentageFormatter(Number(gsmModelSignal / 31)),
    sort: 'gsmModelSignal',
  },
  {
    label: 'Lat/Long',
    value: ({ latitude, longitude }) => latlongFormatter(latitude, longitude),
  },
  {
    label: 'Local',
    value: ({ local }) => defaultFormatter(local),
    sort: 'local',
  },
  {
    label: 'Jammer',
    // status.jammingDetected = protocolV2
    // jamming = protocolV1
    value: ({ status, jamming }) =>
      boolToString((status && status.jammingDetected) || jamming || false),
  },
  {
    label: 'Km Rodado',
    value: ({ kmDistance }) => defaultFormatter(kmDistance),
    sort: 'kmDistance',
  },
  {
    label: 'Última posição válida',
    value: ({ lastValidPositionDate, lastValidPositionHour }) =>
      lastValidPositionDateFormatter(
        lastValidPositionDate,
        lastValidPositionHour
      ),
  },
];
