import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';

import { useFields } from '../../hooks/fields';
import { useEffectSkipFirst } from 'hooks/common';
import { defaultQuery } from 'utils/query';
import FilterHandler from 'utils/FilterHandler';

const inputHeight = 38;

const styles = theme => ({
  root: {
    paddingTop: theme.main.padding.top,
    paddingBottom: theme.main.padding.bottom,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  inputContainerBig: {
    marginRight: 17,
    borderRadius: 16,
    width: 399,
    minHeight: inputHeight,
    backgroundColor: theme.palette.background.white,
  },
  input: {
    paddingLeft: 20,
    height: inputHeight,
  },
  inputPlaceholder: {
    '&::placeholder': {
      fontWeight: 600,
      color: theme.palette.gray.gray_5,
      opacity: 1,
      fontSize: 14,
    },
  },
  inputRoot: {
    marginTop: '0px !important',
  },
  inputLabel: {
    paddingLeft: 20,
    fontWeight: 600,
    color: theme.palette.gray.gray_5,
    fontSize: 14,
    marginTop: -12,
  },
  shrink: {
    fontSize: 17,
    marginTop: -17,
  },
  submitButton: {
    minWidth: 125,
    minHeight: inputHeight,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    boxShadow: 'unset',
  },
  chip: {
    marginTop: 10,
    marginRight: 10,
  },
});

function Filter(props) {
  const {
    classes,
    location,
    query,
    setQuery,
    searchPlaceHolder,
    noSearch,
    buttonText,
    genericSearchFields,
    ButtonProps,
    defaultChips,
  } = props;

  const asyncFields = props.asyncFields || [];
  const containerStyle = props.containerStyle || {};
  const inputContainerStyle = props.inputContainerStyle || {};
  const filterHandler = props.filterHandler || new FilterHandler(query, setQuery, location);

  const [searchText, setSearchText] = useState('');
  const [chips, setChips] = useState(defaultChips || []);

  const [isLoading, setIsLoading] = useState(
    ...asyncFields.map(item => {
      return { [item.name]: false };
    }),
  );

  const fields = useFields(
    asyncFields.map(item => {
      return item.name;
    }),
  );

  // update filters when chips change
  useEffectSkipFirst(() => {
    filterHandler.updateQueryFilter({
      searchText: chips,
      genericSearchFields: genericSearchFields.map(field => field.name),
    });
  }, [chips]);

  const handleSearchChange = e => {
    const text =  e.target.value || '';
    setSearchText(text);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (searchText) {
      let _chips = [...chips, searchText];
      setChips([..._chips]);
    }
    setSearchText('');
  };

  const handleDelete = chip => {
    const _chips = chips.filter(item => {
      return item !== chip;
    });
    setChips(_chips);
  };

  return (
    <div className={classes.root} style={containerStyle}>
      <div style={{ display: 'inline-flex', flex: 1 }}>
        {/* Render async fields  */}
        {asyncFields.map((field, index) => (
          <div key={index}>
            {field.component({
              fields,
              isLoading,
              setIsLoading,
              filterHandler,
            })}
          </div>
        ))}
        <form onSubmit={handleSubmit} style={{ flex: 1 }}>
          {!noSearch && (
            <TextField
              className={classes.inputContainerBig}
              label={!props.hideLabel && searchPlaceHolder}
              placeholder={(props.showPlaceholder && searchPlaceHolder) || ''}
              name="search"
              onChange={handleSearchChange}
              value={searchText}
              InputProps={{
                ...props.inputProps,
                disableUnderline: true,
                className: classes.input,
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputPlaceholder,
                },
              }}
              InputLabelProps={{
                className: classes.inputLabel,
                classes: {
                  shrink: classes.shrink,
                },
              }}
              style={inputContainerStyle}
            />
          )}
          {!props.hideButton && (
            <Button
              className={classes.submitButton}
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              {...ButtonProps}
            >
              {buttonText || 'Filtrar'}
            </Button>
          )}
        </form>
      </div>
      <div>
        {chips.map((item, index) => {
          return (
            <Fade key={item + index} in={true} timout={1000}>
              <Chip
                label={item}
                clickable
                color="primary"
                onDelete={() => {
                  handleDelete(item);
                }}
                deleteIcon={<CloseIcon style={{ width: 15 }} />}
                variant="outlined"
                className={classes.chip}
              />
            </Fade>
          );
        })}
      </div>
    </div>
  );
}

Filter.defaultProps = {
  path: '',
  query: defaultQuery,
  setQuery: () => {},
  genericSearchFields: [],
  ButtonProps: {},
  defaultChips: []
};

export default withRouter(withStyles(styles)(Filter));
