import React, { useReducer, useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import FilterAutoComplete from 'components/filter/FilterAutoComplete';
import Map from '../components/map/Map';
import Permission from 'components/permissions/Permission';
import Filter from 'components/filter/Filter';
import Search from '@material-ui/icons/Search';
import SimpleTable from '../components/table/Table';
import TableMenu, { TableColumn } from 'components/table/TableMenu';
import ModalObs from 'components/modals/ModalObs';
import { CommandGprs, CommandAdmin } from '../components/commands/Commands';
import CommandSafeloggyModal from '../components/commands/CommandSafeloggyModal';
import CommandSafeloggySOSAndBlockModal from '../components/commands/CommandSafeloggySOSAndBlockModal';
import InputAdornment from '@material-ui/core/InputAdornment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { StatusColumn } from 'components/table/columns';
import theme from 'styles/theme';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';

import BatteryIcon from '../assets/icon/battery.svg';

import { fetchAuthenticated } from 'services/fetch';
import { useAccessLog } from 'hooks/logs';
import { useQuery } from 'hooks/fetch';
import { emitEvent } from 'utils/events';

import { saveAs } from 'file-saver';
import { logAction } from 'utils/logs';

import {
  datetimeFormatter,
  percentageFormatter,
  latlongFormatter,
  poffFormatter,
} from 'utils/formatters';
import {
  isRfOn,
  getPositionIndicator,
  minutesToTimeString,
  getBatteryStatus,
} from 'utils/helpers';

import HomeFilterContext from 'pages/HomeFilterContext';

async function fetchData(path) {
  let data = null;
  emitEvent('showGlobalLinearProgress');

  const response = await fetchAuthenticated('GET', path);
  if (response.ok) data = await response.json();

  emitEvent('hideGlobalLinearProgress');
  return data;
}

function reduce(state, action) {
  switch (action.type) {
    case 'SET':
      return action.payload;
    default:
      return state;
  }
}

function Mapa(props) {
  const filterContext = useContext(HomeFilterContext);

  const genericSearchFields = [
    { label: 'Imei', name: 'imei' },
    { label: 'Código', name: 'code' },
    { label: 'Descrição', name: 'description' },
    { label: 'Tipo', name: 'equipmentType.name' },
    { label: 'Nome da Empresa', name: 'currentCompany.name' },
    // { label: 'Cidade', name: 'currentCompany.addressCity.nome', },
    // { label: 'Estado', name: 'currentCompany.addressState.nome', },
    { label: 'Placa', name: 'vehicle' },
    { label: 'Local', name: 'lastEvent.local' },
    { label: 'Observação', name: 'lastObservation.text' },
  ];

  useAccessLog('Acesso ao mapa');
  const { classes, location } = props;
  const path = 'equipment';
  // const [fetchPath, setFetchPath] = useState(buildUrl(path, location, {sort: {'lastEvent.timestamp': -1}}));

  // const [query, setQuery] = useState(buildQuery(location, {filter: {currentCompany: {'$exists': true}}}))

  const [reload, setReload] = useState(false);
  // Fetch and re-fetch data every time `fetchPath` changes
  const [data, dispatch] = useReducer(reduce, false);

  const [chips, setChips] = useState(
    (filterContext && filterContext.searchTerm) || []
  );

  const [query, setQuery, isLoading, refreshQuery] = useQuery(
    path,
    data => dispatch({ type: 'SET', payload: data }),
    undefined,
    {
      filter: {
        'currentCompany._id': {
          ...(filterContext.company
            ? { value: filterContext.company._id, toObjectId: false }
            : { $exists: true }),
        },
        lastEvent: { $exists: true },
        isActive: true,
        ...(filterContext.equipment && { _id: filterContext.equipment._id }),
        ...(filterContext.searchTerm && {
          searchText: filterContext.searchTerm,
          genericSearchFields: genericSearchFields.map(field => field.name),
        }),
      },
      sort: {
        'lastEvent.timestamp': -1,
      },
    },
    undefined,
    reload
  );
  const [centerMapOn, setCenterMapOn] = useState(null);

  const onModalObsSubmit = () => {
    setReload(!reload);
  };

  useEffect(() => {
    const INTERVAL = 1000 * 60;
    let RELOAD_INTERVAL_REFERENCE = null;

    if (filterContext) {
      filterContext.updateSearchTerm(query.queryObject.filter.searchText);
    }

    const fn = async () => {
      RELOAD_INTERVAL_REFERENCE = setInterval(async () => {
        refreshQuery(query);
      }, INTERVAL);
    };
    fn();

    return () => clearInterval(RELOAD_INTERVAL_REFERENCE);
  }, [query]);

  const rowActions = [
    {
      label: 'Comandos GPRS',
      permission: 'SendGPRSCommand',
      component: props => (
        <CommandGprs showStatus={true} isVisible={true} {...props} />
      ),
    },
    {
      label: 'Comandos Administrativos',
      permission: 'SendAdminCommand',
      component: props => (
        <CommandAdmin showStatus={true} isVisible={true} {...props} />
      ),
    },
    {
      label: 'Observações',
      permission: 'EditEquipmentObservations',
      component: props => (
        <ModalObs
          onSubmit={onModalObsSubmit}
          equipment={props}
          isVisible={true}
          {...props}
        />
      ),
    },
  ];

  const safeloggyRowActions = [
    {
      label: 'Comandos Bloqueador',
      permission: 'SendSafeloggyCommand',
      component: (props, setCurrentAction) => (
        <CommandSafeloggyModal
          isOpen
          setCurrentAction={setCurrentAction}
          {...props}
        />
      ),
    },
    {
      label: 'Bloqueador SOS e Bloqueio',
      permission: 'SendSafeloggyCommand',
      component: (props, setCurrentAction) => (
        <CommandSafeloggySOSAndBlockModal
          isOpen
          setCurrentAction={setCurrentAction}
          {...props}
        />
      ),
    },
    {
      label: 'Observações',
      permission: 'EditEquipmentObservations',
      component: props => (
        <ModalObs
          onSubmit={onModalObsSubmit}
          equipment={props}
          isVisible={true}
          {...props}
        />
      ),
    },
  ];

  const columns = [
    { label: 'Equip.', value: row => row.shortImei, sort: 'shortImei' },
    { label: 'Placa', value: row => row.vehicle, sort: 'vehicle' },
    {
      label: 'Tipo',
      value: row => row.equipmentType && row.equipmentType.name,
      sort: 'equipmentType.name',
    },
    {
      label: 'Empresa',
      value: row => row.currentCompany && row.currentCompany.name,
      sort: 'currentCompany.name',
    },
    {
      label: 'V. FW',
      value: row => (row.lastEvent && row.lastEvent.firmwareVersion) || '-',
      sort: 'lastEvent.firmwareVersion',
    },
    {
      label: 'Bat.',
      value: row => (
        <div className={classes.chargingBattery}>
          <span>
            {(row.lastEvent &&
              percentageFormatter(
                Number(
                  (row.lastEvent.adjustedInternalBatteryPercentage ||
                    row.lastEvent.internalBatteryPercentage) / 100
                )
              )) ||
              '-'}
          </span>
          {getBatteryStatus(row.lastEvent) && (
            <img
              src={BatteryIcon}
              style={{ marginLeft: '10px' }}
              alt="Carregando"
            />
          )}
        </div>
      ),
      sort: 'lastEvent.internalBatteryPercentage',
    },
    { label: 'Exp.', value: row => poffFormatter(row) },
    {
      label: 'Posição',
      value: row => row.lastEvent && getPositionIndicator(row.lastEvent, true),
    },
    {
      label: 'Sinal',
      value: row =>
        row.lastEvent &&
        row.lastEvent.gsmModelSignal &&
        `${Math.round(
          (parseInt(row.lastEvent.gsmModelSignal, 10) / 31) * 100
        )}%`,
      sort: 'lastEvent.gsmModelSignal',
    },
    {
      label: 'Data/hora',
      value: row =>
        (row.lastEvent && `${datetimeFormatter(row.lastEvent.timestamp)}`) ||
        '-',
      sort: 'lastEvent.timestamp',
    },
    { label: 'RF', value: row => isRfOn(row.lastEvent) },
    {
      label: 'FPP.',
      value: row =>
        row.lastEvent &&
        minutesToTimeString(row.lastEvent.positionSendInterval),
      sort: 'lastEvent.positionSendInterval',
    },
    {
      label: 'Local',
      value: row => row.lastEvent && row.lastEvent.local,
      sort: 'lastEvent.local',
    },
    {
      label: 'Lat/Long',
      value: row =>
        (row.lastEvent &&
          `${latlongFormatter(
            row.lastEvent.latitude,
            row.lastEvent.longitude
          )}`) ||
        '-/-',
      sort: '',
    },
    {
      label: 'Status',
      component: row => (
        <TableColumn>
          <StatusColumn edit={false} status={row.handlingStatus} />
        </TableColumn>
      ),
    },
    {
      label: '',
      component: (row, col) => (
        <Permission
          key={row._id}
          names={[
            'SendGPRSCommand',
            'SendAdminCommand',
            'EditEquipmentObservations',
          ]}
        >
          <TableMenu
            row={row}
            rowActions={
              row.equipmentType.name === 'Bloqueador'
                ? safeloggyRowActions
                : rowActions
            }
            icon={<MoreVertIcon />}
          />
        </Permission>
      ),
    },
  ];

  const filterAsyncFields = [
    {
      name: 'companies',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Empresa"
          showPlaceholder={true}
          hideLabel={true}
          path="company?notPaginated=true&fields=_id,name"
          name="companies"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={args => {
            const { opt, fields, setIsLoading, isLoading, filterHandler } =
              args;
            // reset equipments filter
            fields.equipments.current.resetField();
            setIsLoading({ ...isLoading, equipments: true });
            filterContext.updateCompany(opt);
            if (!opt) {
              filterHandler.removeFilter('currentCompany._id');
            } else {
              // update equipments available for filtering
              filterHandler.updateQueryFilter({
                currentCompany: { $exists: true },
                'currentCompany._id': { value: opt._id, toObjectId: false },
                isActive: true,
              });
            }
          }}
          value={filterContext.company}
        />
      ),
    },
    {
      name: 'equipments',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Equipamentos"
          showPlaceholder={true}
          hideLabel={true}
          path="equipment?notPaginated=true&fields=_id,imei&"
          additionalStartPath='filter={"isActive":true}'
          name="equipments"
          getOptionLabel={opt => opt.imei}
          loadingMessage="Carregando equipamentos..."
          noOptionsMessage="Nenhum equipamento encontrado."
          onChange={args => {
            const { opt, filterHandler } = args;
            filterContext.updateEquipment(opt);
            if (!opt) {
              filterHandler.removeFilter('_id');
            } else {
              filterHandler.updateQueryFilter({
                currentCompany: { $exists: true },
                _id: opt._id,
                isActive: true,
              });
            }
          }}
          whenIsLoading={({ path: defaultPath, setFetchUrl, fields }) => {
            const companyField = fields.companies.current.value;
            if (companyField) {
              const _filter = {
                'currentCompany._id': {
                  value: companyField._id,
                  toObjectId: false,
                },
                isActive: true,
              };
              setFetchUrl(`${defaultPath}filter=${JSON.stringify(_filter)}`);
            } else {
              setFetchUrl(
                `${defaultPath}filter=${JSON.stringify({ isActive: true })}`
              );
            }
          }}
          value={filterContext.equipment}
        />
      ),
    },
  ];

  const Popover = props => (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography style={{ fontSize: 15 }}>
          <b>Última observação:</b>{' '}
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            marginLeft: 34,
            color: '#8e96b0',
            fontWeight: 'bold',
          }}
        >
          {datetimeFormatter(props.lastObservation.date)}
        </Typography>
      </div>
      <Typography style={{ fontSize: 14, marginTop: 17, color: '#41475a' }}>
        {props.lastObservation && props.lastObservation.text}
      </Typography>
    </>
  );

  const handleExportXLS = async query => {
    try {
      const response = await fetchAuthenticated(
        'GET',
        `equipment/report/xls${query.queryString}`
      );

      const blob = await response.blob();
      saveAs(blob, 'Relatorio_Equipamentos.xls');
      logAction('Exportou relatório de expirados em XLS');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Filter
        searchPlaceHolder="Buscar (cidade, estado, equipamento...)"
        path={path}
        query={query}
        setQuery={setQuery}
        containerStyle={{ paddingLeft: 0 }}
        asyncFields={filterAsyncFields}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.searchIcon}>
              <Search />
            </InputAdornment>
          ),
        }}
        showPlaceholder={true}
        hideLabel={true}
        genericSearchFields={genericSearchFields}
        defaultChips={chips}
      />
      <Map
        height={320}
        containerStyle={{}}
        equipments={data}
        centerMapOn={centerMapOn}
        // showAllFences={true}
        showCurrentEquipmentFences={true}
      />
      <SimpleTable
        columns={columns}
        path={path}
        data={data}
        setQuery={setQuery}
        query={query}
        containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
        onRowClick={(row, col) => {
          setCenterMapOn(row);
        }}
        HeaderComponent={
          <TableHeader
            headerActions={[
              <TableHeaderAction
                title="Exportar XLS"
                IconProps={{ style: theme.custom.icon }}
                Icon={props => <Typography {...props}>XLS</Typography>}
                disabled={data && data.totalItems === 0}
                onClick={() => handleExportXLS(query)}
              />,
            ]}
          />
        }
        popover={Popover}
        isPopoverVisible={row =>
          row.lastObservation && !row.lastObservation.archived
        }
      />
    </div>
  );
}

const styles = theme => ({
  root: {
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  mapClass: {
    marginTop: 50,
  },
  searchIcon: {
    color: theme.palette.gray.gray_5,
  },
  chargingBattery: {
    display: 'flex',
    alignItems: 'center',
  },
});
export default withRouter(withStyles(styles)(Mapa));
