import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';

import EquipmentForm from 'components/forms/EquipmentForm';
import ModalRemove from 'components/modals/ModalRemove';
import ModalConfirm from "components/modals/ModalConfirm";

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

export default function EditEquipment(props) {
  const classes = useStyles();
  const { match, history, setBreadcrumbs } = props;
  const [equipment, setEquipment] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  const [showEnableDisableDialog, setShowEnableDisableDialog,] = useState(false);

  // Use base equipment url
  const equipmentURL = `equipment/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/equipamentos', label: 'Equipamentos' },
      { path: '', label: `${equipment && equipment.shortImei || ''}` }
    ]);
    return () => { setBreadcrumbs([]); }
  }, [setBreadcrumbs, match.params.id, equipment]);

  // Load equipment info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const response = await fetchAuthenticated('get', equipmentURL);

      // Any error will redirect to equipments list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/empresas');
      } else {
        const data = await response.json();

        // Update protocol version to expected format
        data.protocolVersion = { id: data.protocolVersion, name: `Protocolo ${data.protocolVersion}` }

        setEquipment(data);
        logAction(`Acesso ao cadastro do equipamento ${data.shortImei}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, equipmentURL]);

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', equipmentURL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();

      // Update protocol version to expected format
      const protocolVersion = data.protocolVersion;
      data.protocolVersion = { id: protocolVersion, name: 'Protocolo ' + protocolVersion }

      setEquipment(data);
      logAction(`Edição do equipamento ${data.shortImei}`);
      emitEvent('showSnack', { message: 'Equipamento atualizado com sucesso', type: 'success' });
    }
  }

  // Handle cancel button click
  const handleCancel = () => {
    history.push('/cadastros/equipamentos');
  }

  // Handle remove button click
  const handleRemove = () => {
    console.log('Handle remove');
    setShowDialog(true);
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  // Handle remove confirmation
  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', equipmentURL);

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover o equipamento', type: 'error' });
      } else {
        logAction(`Remoção do equipamento ${equipment.shortImei}`);
        emitEvent('showSnack', { message: 'Equipamento removido com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/equipamentos');
      }
    }
  }

  const handleEnableDisable = () => {
    setShowEnableDisableDialog(true);
  }
  const confirmEnableDisable = async () => {
    const method = equipment.isActive ? 'deactivate'  : 'activate';
    const path = `${equipmentURL}/${method}`;
    try {
      const res = await fetchAuthenticated('put', path);
      const eqp = await res.json();
      setEquipment(eqp);
      setShowEnableDisableDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <EquipmentForm
        title='Detalhes do Equipamento'
        data={equipment}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
        handleEnableDisable={handleEnableDisable}
      />

      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='do equipamento'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='o equipamento'
        extraContent='Ao realizar está ação o equipamento não estará mais disponível'
      />

      <ModalConfirm
        isOpen={showEnableDisableDialog}
        title={`Confirmar ${equipment.isActive ? 'desativação' : 'ativação'}`}
        confirmButtonLabel="Sim, confirmar"
        onConfirm={confirmEnableDisable}
        onClose={() => setShowEnableDisableDialog(false)}
      >
        <Typography style={{
          fontSize: 16,
          color: '#41475a',
        }}>
          {
            equipment.isActive
            ? "Ao desativar este equipamento o mesmo não poderá mais ser visualizado no mapa e no dashboard do sistema"
            : "Ao ativar este equipamento o mesmo poderá ser visualizado no mapa e no dashboard do sistema"
          }

        </Typography>
      </ModalConfirm>
    </div>
  );
}
