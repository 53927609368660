import React, { useState, useEffect } from 'react';
import { Grid, FormControl } from '@material-ui/core';
import {
  ButtonSubmit,
  ButtonRemove,
  ButtonCancel,
} from 'components/buttons/ButtonsForm';
import Form, { FormSection } from 'components/forms/Form';
import { useFields } from 'hooks/fields';
import { emitEvent } from 'utils/events';
import { getItem, setItem } from 'services/local-storage';
import { useFetch, useAuthenticated } from 'hooks/fetch';
import { withStyles } from '@material-ui/core';

import {
  AutocompleteField,
  TextField,
  CpfField,
  ZipcodeField,
  PhoneField,
  EmailField,
  PasswordField,
  ConfirmPasswordField,
} from 'components/fields';

import CheckboxList from 'components/lists/CheckboxList';
import CheckboxField from 'components/fields/Checkbox.field';

const UserForm = props => {
  const { classes, edit } = props;
  const [isLoading, setIsLoading] = useState({
    form: false,
    companies: false,
    states: false,
    cities: false,
  });
  const [isDisabled, setDisabled] = useState({ form: false });
  const [submitForm, setSubmitForm] = useState(false);

  // Select fields options
  const [companies, setCompanies] = useState([]);
  const [statesFromIBGE, setStatesFromIBGE] = useState([]);
  const [citiesFromIBGE, setCitiesFromIBGE] = useState([]);

  // Declare form fields
  const _fields = [
    // Personal information
    'company',
    'code',
    'name',
    'idCardNumber',
    'idCardIssuer',
    'cpf',
    'nationality',
    // Address information
    'address',
    'addressHouseNumber',
    'addressAdditionalInfo',
    'addressZipcode',
    'addressNeighborhood',
    'addressState',
    'addressCity',
    // General information
    'jobRole',
    'phone',
    'phoneExtension',
    'radio',
    'email',
    'isActive',
    'inactivityLogout',
    // Login fields
    'username',
  ];

  if (!edit) {
    _fields.push('password', 'passwordConfirm');
  }
  const fields = useFields(_fields);
  const [profiles, setProfiles] = useState([]);

  const { data: allProfiles } = useAuthenticated(
    useFetch,
    'authorization?page=1&limit=100',
    {
      beforeFetch: () => {
        emitEvent('showGlobalLinearProgress');
      },
      afterFetch: () => {
        emitEvent('hideGlobalLinearProgress');
      },
    }
  );

  useEffect(() => {
    if (allProfiles && props.data) {
      allProfiles.data.forEach(prof => {
        prof.checked = props.data.profiles.some(p => {
          return p._id == prof._id;
        });
      });
      setProfiles(allProfiles.data);
    }
  }, [allProfiles, props.data]);

  /**==========================
   *
   * LOAD SELECT FIELDS OPTIONS
   * ========================== */

  // Load companies
  const { data } = useAuthenticated(useFetch, 'company?notPaginated=true', {
    beforeFetch() {
      setIsLoading({ ...isLoading, companies: true });
    },
  });

  // Sort companies
  useEffect(() => {
    const c =
      (data && data.data.sort((a, b) => (a.name > b.name ? 1 : -1))) || [];
    setCompanies(c);
    setIsLoading({ ...isLoading, companies: false });
  }, [data]);

  // Load states
  useEffect(() => {
    setIsLoading({ ...isLoading, states: true });
    let statesFromIBGE = getItem('statesFromIBGE');
    if (!statesFromIBGE) {
      const fn = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_IBGE_STATES_API}/estados`
        );
        statesFromIBGE = await response.json();
        statesFromIBGE = statesFromIBGE.sort((a, b) =>
          a.nome > b.nome ? 1 : -1
        );
        setItem('statesFromIBGE', statesFromIBGE);
        setStatesFromIBGE(statesFromIBGE);
        setIsLoading({ ...isLoading, states: false });
      };
      fn();
    } else {
      setStatesFromIBGE(statesFromIBGE);
      setIsLoading({ ...isLoading, states: false });
    }
  }, []);

  // Load cities
  useEffect(() => {
    if (isLoading.cities) {
      // Clear options
      let cities = [];
      setCitiesFromIBGE(cities);

      if (fields.addressState.current.value) {
        setCitiesFromIBGE(cities);
        setIsLoading({ ...isLoading, cities: true });

        const fn = async () => {
          const result = await fetch(
            `${process.env.REACT_APP_IBGE_STATES_API}/estados/${fields.addressState.current.value.id}/municipios`
          );
          cities = await result.json();
          cities = cities.sort((a, b) => (a.nome > b.nome ? 1 : -1));

          setCitiesFromIBGE(cities);
          setIsLoading({ ...isLoading, cities: false });
        };
        fn();
      } else {
        setIsLoading({ ...isLoading, cities: false });
      }
    } else {
      setIsLoading({ ...isLoading, cities: false });
    }
  }, [isLoading.cities]);

  /**======================
   * LOAD INITIAL FORM DATA
   * ====================== */
  useEffect(() => {
    setIsLoading({ ...isLoading, form: true });
    setDisabled({ form: true });
    if (props.data) {
      for (const [key, value] of Object.entries(props.data)) {
        if (fields[key] && fields[key].current) {
          if (fields[key].current.setFieldValue) {
            fields[key].current.setFieldValue(value);
          }
        }
      }
      if (!edit) {
        fields.password.current.resetField();
        fields.passwordConfirm.current.resetField();
      }
    }
    setIsLoading({ ...isLoading, form: false });
    setDisabled({ form: false });
  }, [props.data]);

  /**================
   * FORM SUBMISSION
   * ================ */

  // Validate all fields when form is submited
  const validateForm = async event => {
    event.preventDefault();
    const nonValidatedFields = Object.values(fields).filter(
      f => !f.current.alreadyValidated
    );
    nonValidatedFields.map(f => f.current.validateField());
    setSubmitForm(true);
    console.log('Validating');
  };

  // Handle form submission
  useEffect(() => {
    if (submitForm) {
      (async () => {
        await handleSubmit();
      })();
    }
  }, [submitForm]);
  const handleSubmit = async () => {
    // Check for invalid fields
    const invalidFields = Object.values(fields).filter(
      f => f.current.isInvalid
    );
    if (invalidFields.length > 0) {
      emitEvent('showSnack', {
        message: 'Erro ao validar formulário',
        type: 'error',
      });
      setSubmitForm(false);
    }

    // All fields are valid
    // Commit changes to the server
    else {
      setDisabled({ ...isDisabled, form: true });
      setIsLoading({ ...isLoading, form: true });

      // Get all fiels
      const data = {};
      for (const [key, value] of Object.entries(fields)) {
        data[key] = value.current.raw;
      }

      // data.profiles = profiles;

      const profileIds = profiles.reduce((a, profile) => {
        if (profile.checked) return [...a, profile._id];
        else return [...a];
      }, []);
      data.profiles = profileIds;

      data.company = data.company._id;
      delete data.passwordConfirm;
      delete data.code;

      // Send to server
      try {
        await props.handleSubmit(data);
        setDisabled({ ...isDisabled, form: false });
        setIsLoading({ ...isLoading, form: false });
        setSubmitForm(false);
      } catch (err) {
        console.log(err);
        let message = 'Erro ao processar formulário';
        if (err.message) {
          message = err.message;
        }
        emitEvent('showSnack', { message, type: 'error' });
        setDisabled({ ...isDisabled, form: false });
        setIsLoading({ ...isLoading, form: false });
        setSubmitForm(false);
      }
    }
  };

  return (
    <Form title={props.title || 'Novo Usuário'}>
      <form onSubmit={validateForm} noValidate>
        <FormSection title={'Informações pessoais'}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  required
                  innerRef={fields.company}
                  label="Empresa"
                  name="company"
                  options={companies}
                  isLoading={isLoading.companies}
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt._id}
                  noOptionsMessage={() => 'Nenhuma empresa encontrada'}
                  loadingMessage={() => 'Carregando empresas...'}
                  isClearable={true}
                  disabled={isDisabled.form}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                ref={fields.name}
                fullWidth
                className={classes.fullWidthField}
                label="Nome"
                name="name"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                disabled
                ref={fields.code}
                fullWidth
                className={classes.fullWidthField}
                label="Código"
                name="code"
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CpfField
                ref={fields.cpf}
                fullWidth
                className={classes.fullWidthField}
                label="CPF"
                name="cpf"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                type={'number'}
                ref={fields.idCardNumber}
                fullWidth
                className={classes.fullWidthField}
                label="RG/RNE"
                name="idCardNumber"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.idCardIssuer}
                fullWidth
                className={classes.fullWidthField}
                label="Orgão Emissor"
                name="idCardIssuer"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.nationality}
                fullWidth
                className={classes.fullWidthField}
                label="Nacionalidade"
                name="nationality"
                disabled={isDisabled.form}
              />
            </Grid>
          </Grid>
        </FormSection>

        <FormSection divider={true}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CheckboxField
                ref={fields.isActive}
                label="Usuário ativo"
                name="isActive"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CheckboxField
                ref={fields.inactivityLogout}
                label="Desconectar após inatividade"
                defaultValue
                name="inactivityLogout"
                disabled={isDisabled.form}
              />
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title={'Endereço'} divider={true}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.address}
                label="Endereço"
                name="address"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.addressHouseNumber}
                label="Número"
                name="addressHouseNumber"
                type="number"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.addressAdditionalInfo}
                label="Complemento"
                name="addressAdditionalInfo"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ZipcodeField
                ref={fields.addressZipcode}
                label="CEP"
                name="addressZipcode"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.addressNeighborhood}
                label="Bairro"
                name="addressNeighborhood"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.addressState}
                  label="UF"
                  name="addressState"
                  options={statesFromIBGE}
                  isLoading={isLoading.states}
                  getOptionLabel={opt => `[${opt.sigla}] ${opt.nome}`}
                  getOptionValue={opt => opt.id}
                  onChange={opt => {
                    fields.addressCity.current.resetField();
                    setIsLoading({ ...isLoading, cities: true });
                  }}
                  disabled={isDisabled.form}
                  noOptionsMessage={() => 'Nenhum estado encontrado'}
                  loadingMessage={() => 'Carregando estados...'}
                  isClearable={true}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.addressCity}
                  label="Cidade"
                  name="addressCity"
                  options={citiesFromIBGE}
                  isLoading={isLoading.cities}
                  getOptionLabel={opt => `${opt.nome}`}
                  getOptionValue={opt => opt.id}
                  loadingMessage={() => 'Carregando cidades...'}
                  noOptionsMessage={() => 'Nenhuma cidade encontrada'}
                  isClearable={true}
                  disabled={isDisabled.form}
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title={'Informações gerais'} divider={true}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.jobRole}
                label="Cargo"
                name="jobRole"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <PhoneField
                ref={fields.phone}
                label="Telefone"
                name="phone"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.phoneExtension}
                type={'number'}
                label="Ramal"
                name="phoneExtension"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.radio}
                type={'number'}
                label="Rádio"
                name="radio"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <EmailField
                required
                ref={fields.email}
                label="Email"
                name="email"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title={'Informações de acesso'} divider={true}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                ref={fields.username}
                label="Usuário"
                name="username"
                fullWidth
                className={classes.fullWidthField}
                disabled={(props.data && true) || false}
              />
            </Grid>
            {!edit && (
              <>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <PasswordField
                    required
                    ref={fields.password}
                    label="Senha"
                    name="password"
                    fullWidth
                    className={classes.fullWidthField}
                    disabled={isDisabled.form}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ConfirmPasswordField
                    required
                    ref={fields.passwordConfirm}
                    label="Repetir Senha"
                    name="passwordConfirm"
                    fullWidth
                    className={classes.fullWidthField}
                    disabled={isDisabled.form}
                    passwordRef={fields.password}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormSection>

        <FormSection title={'Perfil de acesso'} divider={false}>
          <Grid container justify="flex-start">
            <Grid item xs={12} md={'auto'}>
              <CheckboxList
                data={profiles}
                setData={setProfiles}
                getName={item => item.name}
              />
            </Grid>
          </Grid>
        </FormSection>

        <Grid container justify="flex-end" spacing={1}>
          {props.handleCancel && (
            <Grid item xs={12} md={'auto'}>
              <ButtonCancel
                onClick={props.handleCancel}
                disabledCondition={isDisabled.form}
              ></ButtonCancel>
            </Grid>
          )}
          {props.handleRemove && (
            <Grid item xs={12} md={'auto'}>
              <ButtonRemove
                onClick={props.handleRemove}
                disabledCondition={isDisabled.form}
              ></ButtonRemove>
            </Grid>
          )}
          {props.handleSubmit && (
            <Grid item xs={12} md={'auto'}>
              <ButtonSubmit conditionDisabled={isDisabled.form}></ButtonSubmit>
            </Grid>
          )}
        </Grid>
      </form>
    </Form>
  );
};

const styles = theme => ({
  btnRoot: {
    minWidth: 125,
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    // Small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
});

UserForm.defaultProps = {
  edit: false,
};

export default withStyles(styles)(UserForm);
