import React, { useState, useEffect } from 'react';
import { Grid, FormControl } from '@material-ui/core';
import {
  ButtonSubmit,
  ButtonRemove,
  ButtonCancel,
} from 'components/buttons/ButtonsForm';
import Form, { FormSection } from 'components/forms/Form';
import { useFields } from 'hooks/fields';
import { emitEvent } from 'utils/events';
import { getItem, setItem } from 'services/local-storage';
import { useFetch, useAuthenticated } from 'hooks/fetch';
import { withStyles } from '@material-ui/core';
import { fetchAuthenticated, get } from 'services/fetch';

import {
  AutocompleteField,
  TextField,
  CpfField,
  ZipcodeField,
  PhoneField,
  EmailField,
} from 'components/fields';

import CheckboxList from 'components/lists/CheckboxList';

import ClockIcon from 'assets/img/clock.png';
import IconText from 'components/icons/IconText';

const ProfileEdit = props => {
  const { classes, match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [statesFromIBGE, setStatesFromIBGE] = useState([]);
  const [citiesFromIBGE, setCitiesFromIBGE] = useState([]);
  const [data, setData] = useState(null);
  const userUrl = `user/${match.params.id}`;

  // Declare form fields
  const fields = useFields([
    'code',
    'name',
    'idCardNumber',
    'idCardIssuer',
    'cpf',
    'nationality',
    // Address information
    'address',
    'addressHouseNumber',
    'addressAdditionalInfo',
    'addressZipcode',
    'addressNeighborhood',
    'addressState',
    'addressCity',
    // General information
    'jobRole',
    'phone',
    'phoneExtension',
    'radio',
    'email',
    // Login fields
    'username',
    'password',
    'passwordConfirm',
    'name',
  ]);

  useEffect(() => {
    const _fetch = async () => {
      // Load user details
      const response = await fetchAuthenticated('get', userUrl);

      // Load user password validity info
      const pwdValidity = await fetchAuthenticated(
        'get',
        'user/check-validity'
      );

      setData({ ...(await response.json()), ...(await pwdValidity.json()) });
    };
    _fetch();
  }, []);

  // Load states
  useEffect(() => {
    setIsLoading({ ...isLoading, states: true });
    let statesFromIBGE = getItem('statesFromIBGE');
    if (!statesFromIBGE) {
      console.debug('Loading states from IBGE');
      const fn = async () => {
        const response = await fetch(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
        );
        statesFromIBGE = await response.json();
        statesFromIBGE = statesFromIBGE.sort((a, b) =>
          a.nome > b.nome ? 1 : -1
        );
        setItem('statesFromIBGE', statesFromIBGE);
        setStatesFromIBGE(statesFromIBGE);
        setIsLoading({ ...isLoading, states: false });
      };
      fn();
    } else {
      setStatesFromIBGE(statesFromIBGE);
      setIsLoading({ ...isLoading, states: false });
    }
  }, []);

  // Load cities
  useEffect(() => {
    if (isLoading.cities) {
      // Clear options
      let cities = [];
      setCitiesFromIBGE(cities);

      if (fields.addressState.current.value) {
        console.log('Loading cities from IBGE');
        setCitiesFromIBGE(cities);
        setIsLoading({ ...isLoading, cities: true });

        const fn = async () => {
          const result = await fetch(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${fields.addressState.current.value.id}/municipios`
          );
          cities = await result.json();
          cities = cities.sort((a, b) => (a.nome > b.nome ? 1 : -1));

          setCitiesFromIBGE(cities);
          setIsLoading({ ...isLoading, cities: false });
        };
        fn();
      } else {
        setIsLoading({ ...isLoading, cities: false });
      }
    } else {
      setIsLoading({ ...isLoading, cities: false });
    }
  }, [isLoading.cities]);

  /**======================
   * LOAD INITIAL FORM DATA
   * ====================== */
  useEffect(() => {
    if (data) {
      setIsLoading(false);
      setDisabled(true);
      for (const [key, value] of Object.entries(data)) {
        if (fields[key] && fields[key].current) {
          if (fields[key].current.setFieldValue) {
            fields[key].current.setFieldValue(value);
          }
        }
      }
      setIsLoading(false);
      setDisabled(false);
    }
  }, [data]);

  /**================
   * FORM SUBMISSION
   * ================ */

  // Validate all fields when form is submited
  const validateForm = async event => {
    event.preventDefault();
    const nonValidatedFields = Object.values(fields).filter(
      f => f.current && !f.current.alreadyValidated
    );
    nonValidatedFields.map(f => f.current.validateField());
    setSubmitForm(true);
    console.log('Validating');
  };

  const submit = async data => {
    try {
      const response = await fetchAuthenticated('put', userUrl, data);
      console.log(response);
      if (!response.ok) {
        throw new Error('');
      } else {
        emitEvent('showSnack', {
          message: 'Usuário atualizado com sucesso',
          type: 'success',
        });
      }
    } catch (err) {
      throw err;
    }
  };

  // Handle form submission
  useEffect(() => {
    if (submitForm) {
      (async () => {
        await handleSubmit();
      })();
    }
  }, [submitForm]);
  const handleSubmit = async () => {
    // Check for invalid fields
    const invalidFields = Object.values(fields).filter(
      f => f.current && f.current.isInvalid
    );
    if (invalidFields.length > 0) {
      emitEvent('showSnack', {
        message: 'Erro ao validar formulário',
        type: 'error',
      });
      setSubmitForm(false);
    }

    // All fields are valid
    // Commit changes to the server
    else {
      setDisabled(true);
      setIsLoading(true);

      // Get all fields
      const data = {};
      for (const [key, value] of Object.entries(fields)) {
        if (value.current) data[key] = value.current.raw;
      }

      // Send to server
      try {
        await submit(data);
        setDisabled(false);
        setIsLoading(false);
        setSubmitForm(false);
      } catch (err) {
        console.log(err);
        emitEvent('showSnack', {
          message: 'Erro ao processar formulário',
          type: 'error',
        });
        setDisabled(false);
        setIsLoading(false);
        setSubmitForm(false);
      }
    }
  };

  return (
    <Form title={'Editar Perfil'}>
      <form onSubmit={validateForm} noValidate>
        <FormSection title={'Informações gerais'} divider={true}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                ref={fields.name}
                fullWidth
                className={classes.fullWidthField}
                label="Nome"
                name="name"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CpfField
                ref={fields.cpf}
                fullWidth
                className={classes.fullWidthField}
                label="CPF"
                name="cpf"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                type={'number'}
                ref={fields.idCardNumber}
                fullWidth
                className={classes.fullWidthField}
                label="RG/RNE"
                name="idCardNumber"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.idCardIssuer}
                fullWidth
                className={classes.fullWidthField}
                label="Orgão Emissor"
                name="idCardIssuer"
                disabled={isDisabled.form}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.nationality}
                fullWidth
                className={classes.fullWidthField}
                label="Nacionalidade"
                name="nationality"
                disabled={isDisabled.form}
              />
            </Grid>

            {data && data.expiresIn && (
              <Grid item xs={12}>
                <IconText icon={ClockIcon}>
                  Senha expira em: {data.expiresIn} dias
                </IconText>
              </Grid>
            )}
          </Grid>
        </FormSection>

        <FormSection title={'Endereço'} divider={true}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.address}
                label="Endereço"
                name="address"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.addressHouseNumber}
                label="Número"
                name="addressHouseNumber"
                type="number"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.addressAdditionalInfo}
                label="Complemento"
                name="addressAdditionalInfo"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ZipcodeField
                ref={fields.addressZipcode}
                label="CEP"
                name="addressZipcode"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.addressNeighborhood}
                label="Bairro"
                name="addressNeighborhood"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.addressState}
                  label="UF"
                  name="addressState"
                  options={statesFromIBGE}
                  isLoading={isLoading.states}
                  getOptionLabel={opt => `[${opt.sigla}] ${opt.nome}`}
                  getOptionValue={opt => opt.id}
                  onChange={opt => {
                    fields.addressCity.current.resetField();
                    setIsLoading({ ...isLoading, cities: true });
                  }}
                  disabled={isDisabled.form}
                  noOptionsMessage={() => 'Nenhum estado encontrado'}
                  loadingMessage={() => 'Carregando estados...'}
                  isClearable={true}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.addressCity}
                  label="Cidade"
                  name="addressCity"
                  options={citiesFromIBGE}
                  isLoading={isLoading.cities}
                  getOptionLabel={opt => `${opt.nome}`}
                  getOptionValue={opt => opt.id}
                  loadingMessage={() => 'Carregando cidades...'}
                  noOptionsMessage={() => 'Nenhuma cidade encontrada'}
                  isClearable={true}
                  disabled={isDisabled.form}
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title={'Informações pessoais'} divider={true}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <PhoneField
                ref={fields.phone}
                label="Telefone"
                name="phone"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <EmailField
                required
                ref={fields.email}
                label="Email"
                name="email"
                fullWidth
                className={classes.fullWidthField}
                disabled={isDisabled.form}
              />
            </Grid>
          </Grid>
        </FormSection>

        <Grid container justify="flex-end" spacing={1}>
          <Grid item xs={12} md={'auto'}>
            <ButtonSubmit conditionDisabled={isDisabled.form}></ButtonSubmit>
          </Grid>
        </Grid>
      </form>
    </Form>
  );
};

const styles = theme => ({
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
});

export default withStyles(styles)(ProfileEdit);
