import React, { useState, useEffect } from 'react';
import CommandModal from './CommandModal';
import { MenuItem, Grid, TextField, Button, FormControl, Typography } from '@material-ui/core';
import CircularLoading from 'components/loading/CircularLoading';
import { AutocompleteField } from 'components/fields';
import { get, post } from 'services/fetch';
import { makeStyles } from '@material-ui/core/styles';

const SOS_AND_BLOCK = 'c1c4';

// Helper functions
const loadCommands = async (seriesNumber) => {
  const { data } = await get(
    `equipment-event/command?filter={"seriesNumber":"${seriesNumber}","wasSent":false,"canceled":false,"safeloggy.type":"${SOS_AND_BLOCK}"}&sort={"createdAt": -1}`
  );
  return data;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: -15,
    color: theme.palette.gray.gray_5,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  value: {
    fontSize: 12,
  }
}))

const CommandSafeloggySOSAndBlockModal = ({ isOpen, setCurrentAction, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [command, setCommand] = useState({ type: SOS_AND_BLOCK, parameters: {} });


  /** Handle close modal action */
  const handleClose = () => {
    setOpen(false);
    setCurrentAction && setCurrentAction(null);
  };

  // Valores de intervalo
  // IPS = Intervalo de posição em modo sleep (minuto 5 - 255)
  // IPN = Intervalo de posição em modo normal (segundos 15 - 120)
  // IPSOS =Intervalo de posição em modo SOS (segundos 15 - 120)
  // IIS = tempo para inicio modo sleep (minutos 0 - 15)
  const setValue = (e) => {
    setCommand({ ...command, [e.target.name]: e.target.value });
  };

  // Parâmetros de configuração
  // Jammer, Tamper, SimCard, Violação, Saída de Rota
  // Global Rotas/Cercas, Pânico, Atuadores, Odômetro
  const setParameterValue = (name, value) => {
    setCommand({ ...command, parameters: { ...command.parameters, [name]: value } })
  }

  /** Creates a new safeloggy command */
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      action: 'GPRS',
      seriesNumber: rest.seriesNumber,
      safeloggy: { ...command }
    }

    try {
      setLoading(true);
      await post('equipment-event/command/safeloggy', data)
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      const commands = await loadCommands(rest.seriesNumber);

      if (commands.length) {
        setCommand({
          ...command,
          ...(commands[0].safeloggy || {}),
          type: SOS_AND_BLOCK
        });
      }
      setLoading(false)
    })();

  }, [])


  return (
    <CommandModal title="Comandos Bloqueador" isOpen={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Grid container justify="flex-start">
          <Typography style={{ fontSize: 14, fontWeight: 'bold', width: '100%' }}>SOS</Typography>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="SOS Manual"
              name="sosManual"
              style={{ width: '75%' }}
              value={command.parameters['sosManual'] || ''}
              onChange={(evt) => setParameterValue('sosManual', evt.target.value)}
            >
              <MenuItem value='00'>Sem efeito</MenuItem>
              <MenuItem value='01'>Desativar SOS Manual</MenuItem>
              <MenuItem value='10'>Ativar SOS Manual</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="SOS JAMMER"
              name="sosJammer"
              style={{ width: '75%' }}
              value={command.parameters['sosJammer'] || ''}
              onChange={(evt) => setParameterValue('sosJammer', evt.target.value)}
            >
              <MenuItem value='0'>Sem efeito</MenuItem>
              <MenuItem value='1'>Desativar SOS Jammer</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="SOS TAMPER"
              name="sosTamper"
              style={{ width: '75%' }}
              value={command.parameters['sosTamper'] || ''}
              onChange={(evt) => setParameterValue('sosTamper', evt.target.value)}
            >
              <MenuItem value='0'>Sem efeito</MenuItem>
              <MenuItem value='1'>Desativar SOS Tamper</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="SOS SIM CARD"
              name="sosSimCard"
              style={{ width: '75%' }}
              value={command.parameters['sosSimCard'] || ''}
              onChange={(evt) => setParameterValue('sosSimCard', evt.target.value)}
            >
              <MenuItem value='0'>Sem efeito</MenuItem>
              <MenuItem value='1'>Desativar SOS Sim Card</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="SOS Violação"
              name="sosViolation"
              style={{ width: '75%' }}
              value={command.parameters['sosViolation'] || ''}
              onChange={(evt) => setParameterValue('sosViolation', evt.target.value)}
            >
              <MenuItem value='0'>Sem efeito</MenuItem>
              <MenuItem value='1'>Desativar SOS Violação</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="SOS Rota/Cerca"
              name="sosRouteAndFence"
              style={{ width: '75%' }}
              value={command.parameters['sosRouteAndFence'] || ''}
              onChange={(evt) => setParameterValue('sosRouteAndFence', evt.target.value)}
            >
              <MenuItem value='0'>Sem efeito</MenuItem>
              <MenuItem value='1'>Destaivar SOS Rota/Cerca</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="SOS Pânico"
              name="sosPanic"
              style={{ width: '75%' }}
              value={command.parameters['sosPanic'] || ''}
              onChange={(evt) => setParameterValue('sosPanic', evt.target.value)}
            >
              <MenuItem value='0'>Sem efeito</MenuItem>
              <MenuItem value='1'>Desativar SOS Pânico</MenuItem>
            </TextField>
          </Grid>

        </Grid>

        <Grid container justify="flex-start">
          <Typography style={{ fontSize: 14, fontWeight: 'bold', width: '100%' }}>Bloqueio</Typography>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Bloqueio"
              name="block"
              style={{ width: '75%' }}
              value={command.parameters['block'] || ''}
              onChange={(evt) => setParameterValue('block', evt.target.value)}
            >
              <MenuItem value='00'>Sem efeito</MenuItem>
              <MenuItem value='01'>Desbloquear</MenuItem>
              <MenuItem value='10'>Bloquear</MenuItem>
              <MenuItem value='11'>Bloquear Temporizado</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Bloqueio Temporizado"
              name="blockTime"
              style={{ width: '75%' }}
              value={command.parameters['blockTime'] || ''}
              onChange={(evt) => setParameterValue('blockTime', evt.target.value)}
            >
              <MenuItem value='00'>15 min</MenuItem>
              <MenuItem value='01'>30 min</MenuItem>
              <MenuItem value='10'>60 min</MenuItem>
              <MenuItem value='11'>120 min</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container justify="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ padding: '15px 40px' }}
          >Enviar comando</Button>
        </Grid>

      </form>
      <CircularLoading isLoading={loading} />
    </CommandModal>
  );
};

CommandSafeloggySOSAndBlockModal.defaultProps = {
};

export default CommandSafeloggySOSAndBlockModal;
