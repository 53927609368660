import React, { useState } from "react";

import CommandModal from "./CommandModal";
import CommandPv2GprsFields from "./CommandPv2GprsFields";
import { emitEvent } from "utils/events";
import { logAction } from "utils/logs";
import { postWithErrorHandler } from "services/fetch";

const CommandPv2Gprs = (props) => {
  const [imei, setImei] = useState(props.imei || "");
  const [showModal, setShowModal] = useState(true);

  const [commandsV2ToLog, setCommandsV2ToLog] = useState();

  const createLogMessage = () => {
    const message = [];

    for (const c in commandsV2ToLog) {
      message.push(`${commandsV2ToLog[c].label}: ${commandsV2ToLog[c].value}`);
    }

    return message.join(" / ");
  };

  const handleModifyV2Commands = (newCommandsState) => {
    setCommandsV2ToLog(newCommandsState);
  };

  const handleSubmit = (data) => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }

    const logMessage = `Comando GPRS enviado ao equipamento ${imei.slice(-8)}`;
    const successMessage = `Comando enviado com sucesso.`;
    const errorMessage = `Erro ao tentar enviar comando.`;
    const response = postWithErrorHandler(
      "equipment-event/command",
      {
        imei,
        ...data,
        action: "GPRS",
        logMessage: createLogMessage(),
      },
      {
        successMessage,
        errorMessage,
        logMessage,
      }
    );
  };

  const onClose = () => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }
  };

  return (
    <CommandModal
      isOpen={showModal}
      onClose={onClose}
      title="Comandos GPRS Protocolo 2"
    >
      <CommandPv2GprsFields
        {...props}
        handleSubmit={handleSubmit}
        handleModifyCommands={handleModifyV2Commands}
        defaultEnableCommands
      />
    </CommandModal>
  );
};
export default CommandPv2Gprs;
