import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import {
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  Typography,
} from '@material-ui/core';

import theme from 'styles/theme';
import { fetchAuthenticated } from 'services/fetch';
import { buildUrl, defaultQuery, buildQuery } from 'utils/query';
import { emitEvent } from 'utils/events';

import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import { KeyboardArrowDown } from '@material-ui/icons';

import Filter from 'components/filter/Filter';
import FilterHandler from 'utils/FilterHandler';
import FilterAutoComplete from 'components/filter/FilterAutoComplete';

import { mapPoffStatus } from 'utils/formatters';
import { useAccessLog } from 'hooks/logs';
import { logAction } from 'utils/logs';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
  paper: {
    borderRadius: theme.border.radius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  filters: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  button: {
    marginLeft: 20,
  },
  shrink: {
    color: theme.palette.gray.gray_5,
    fontWeight: 'bold',
    fontSize: 16,
    transform: 'translate(0, 1.5px)',
    whiteSpace: 'nowrap',
  },
}));

const FILTERS = [
  { label: 'Qualquer', value: 'any' },
  { label: '0 - 10 dias', value: '00-10' },
  { label: '11 - 20 dias', value: '11-20' },
  { label: '21 - 30 dias', value: '21-30' },
  { label: '31 - 50 dias', value: '31-50' },
  { label: '51 - 70 dias', value: '51-70' },
  { label: '71 - 90 dias', value: '71-90' },
  { label: '+90 dias', value: '91' },
];

const EQUIPMENTS_PATH = 'equipment';
const AwaitingActivation = props => {
  useAccessLog('Acesso a ferramenta de equipamentos aguardando ativação');
  const classes = useStyles();
  const { location, match, history } = props;

  const [path, setPath] = useState(buildUrl(EQUIPMENTS_PATH, location));
  const [query, setQuery] = useState(defaultQuery);
  const [equipments, setEquipments] = useState(null);

  const [filter, setFilter] = useState(FILTERS[0].value);
  const filterHandler = new FilterHandler(query, setQuery, location);

  const returnDays = date => {
    const given = moment(date);
    const current = moment().startOf('day');
    return current.diff(given, 'days', false);
  };

  const daysToDate = days => {
    return moment().subtract(days, 'd').format('YYYY-MM-DD[T00:00:00.000Z]');
  };

  // Initial filter setup
  useEffect(() => {
    filterHandler.updateQueryFilter({
      status: 'registered',
      'poffCommands.0.status': 'SCHEDULED',
      'baitType.name': {
        $in: ['Descartável', 'Retornável', 'Teste', 'Customizada'],
      },
    });
  }, []);

  // Load data
  useEffect(() => {
    const queryPath = `${EQUIPMENTS_PATH}${query.queryString || ''}`;
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const data = await (await fetchAuthenticated('get', queryPath)).json();
      setEquipments(data);
      emitEvent('hideGlobalLinearProgress');
    };
    fn();
  }, [query]);

  const COLUMNS = [
    { label: 'IMEI', sort: 'shortImei', value: row => row.shortImei },
    {
      label: 'Empresa',
      sort: 'currentCompany.name',
      value: row =>
        row.currentCompany ? row.currentCompany.name : 'Desconhecida',
    },
    {
      label: 'Operadora',
      sort: 'mobileOperator.name',
      value: row => (row.mobileOperator && row.mobileOperator.name) || '',
    },
    {
      label: 'Status Poff',
      value: row => mapPoffStatus(row.poffCommands[0]),
    },
    {
      label: 'Tipo',
      sort: 'baitType.name',
      value: row => (row.baitType && row.baitType.name) || '',
    },
    {
      label: 'Dias em Poff',
      sort: 'poffCommands.0.receivedOn',
      defaultSort: true,
      value: row =>
        (row.poffCommands[0] &&
          `${returnDays(row.poffCommands[0].receivedOn)} dias`) ||
        '-',
    },
    {
      label: 'Data/hora da última posição',
      sort: 'lastEvent.lastValidPositionDate',
      value: row =>
        (row.lastEvent &&
          `${row.lastEvent.lastValidPositionDate} ${row.lastEvent.lastValidPositionHour}`) ||
        'Indisponível',
    },
  ];

  const handleCompanySelect = ({
    opt,
    filterHandler,
    setIsLoading,
    isLoading,
  }) => {
    if (opt) {
      filterHandler.updateQueryFilter({
        'currentCompany._id': { value: opt ? opt._id : '', toObjectId: false },
      });
    } else {
      filterHandler.removeFilter('currentCompany._id');
    }
    setIsLoading({ ...isLoading, equipments: true });
  };

  const handleOperatorSelect = ({
    opt,
    filterHandler,
    setIsLoading,
    isLoading,
  }) => {
    if (opt) {
      filterHandler.updateQueryFilter({
        'mobileOperator.name': {
          value: opt ? opt.name : '',
          toObjectId: false,
        },
      });
    } else {
      filterHandler.removeFilter('mobileOperator.name');
    }
  };

  const checkFieldType = field => {
    return {
      $cond: {
        if: {
          $eq: [
            {
              $type: field,
            },
            'date',
          ],
        },
        then: {
          $dateToString: {
            date: field,
          },
        },
        else: field,
      },
    };
  };

  const handleDaysFilter = event => {
    const filterVal = event.target.value;

    setFilter(filterVal);
    filterHandler.removeFilters(['poffCommands.0.receivedOn', '$expr']);

    let minDate = {};
    let maxDate = {};

    if (event.target.value === 'any') return;

    minDate = {
      $lte: [checkFieldType('$$this.receivedOn'), daysToDate(filterVal)],
    };

    if (filterVal.includes('-')) {
      minDate = {
        $lte: [
          checkFieldType('$$this.receivedOn'),
          daysToDate(filterVal.substr(0, filterVal.indexOf('-'))),
        ],
      };

      maxDate = {
        $gte: [
          checkFieldType('$$this.receivedOn'),
          daysToDate(filterVal.substr(filterVal.indexOf('-') + 1)),
        ],
      };
    }

    filterHandler.updateQueryFilter({
      'poffCommands.0.receivedOn': {
        $exists: true,
      },
      $expr: {
        $eq: [
          {
            $reduce: {
              input: [
                {
                  $arrayElemAt: ['$poffCommands', 0],
                },
              ],
              initialValue: false,
              in: {
                $and: [minDate, maxDate],
              },
            },
          },
          true,
        ],
      },
    });
  };

  const handleExportXLS = async query => {
    console.log('Handle export xls');

    try {
      const response = await fetchAuthenticated(
        'GET',
        `equipment/report/awaiting-activation/xls${query.queryString}`
      );

      let filterType = FILTERS.filter(opt => opt.value === filter)[0];
      filterType = filterType.label.replace(' ', '_');

      const blob = await response.blob();
      saveAs(blob, `Relatorio_${filterType}.xls`);
      logAction(
        'Exportou relatório de equipamentos aguardando ativação em XLS'
      );
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const filterFields = [
    {
      name: 'companies',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Empresa"
          showPlaceholder
          hideLabel
          path="company?notPaginated=true"
          name="companies"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={handleCompanySelect}
        />
      ),
    },
    {
      name: 'equipments',
      component: args => {
        return (
          <FilterAutoComplete
            {...args}
            menuContainerStyle={{
              marginTop: 5,
            }}
            placeholder="Equipamentos"
            showPlaceholder={true}
            hideLabel={true}
            path="equipment?notPaginated=true&fields=_id,imei&"
            additionalStartPath='filter={"isActive":true}'
            name="equipments"
            getOptionLabel={opt => opt.imei}
            loadingMessage="Carregando equipamentos..."
            noOptionsMessage="Nenhum equipamento encontrado."
            onChange={args => {
              const { opt, filterHandler } = args;
              if (!opt) {
                filterHandler.removeFilter('_id');
              } else {
                filterHandler.updateQueryFilter({
                  currentCompany: { $exists: true },
                  _id: opt._id,
                  isActive: true,
                });
              }
            }}
            whenIsLoading={({ path: defaultPath, setFetchUrl, fields }) => {
              const companyField = fields.companies.current.value;
              if (companyField) {
                const _filter = {
                  'currentCompany._id': {
                    value: companyField._id,
                    toObjectId: false,
                  },
                  isActive: true,
                };
                setFetchUrl(defaultPath + `filter=${JSON.stringify(_filter)}`);
              } else {
                setFetchUrl(
                  defaultPath + `filter=${JSON.stringify({ isActive: true })}`
                );
              }
            }}
          />
        );
      },
    },
    {
      name: 'operators',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Operadora"
          showPlaceholder
          hideLabel
          path="equipment-mobile-operator?notPaginated=true"
          name="operators"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando operadoras..."
          noOptionsMessage="Nenhuma operadora encontrada."
          onChange={handleOperatorSelect}
        />
      ),
    },
  ];

  const EquipmentFilter = props => {
    const classes = useStyles();
    const { value, onChange, options, query } = props;
    return (
      <FormControl>
        <InputLabel classes={{ shrink: classes.shrink }}>
          Dias em Poff
        </InputLabel>
        <Select
          disableUnderline
          IconComponent={KeyboardArrowDown}
          style={{ marginTop: 20 }}
          onChange={e => onChange(e, query)}
          value={value}
        >
          {options.map(o => (
            <MenuItem value={o.value} key={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <Filter
          containerStyle={{ paddingLeft: 0, paddingTop: 0 }}
          asyncFields={filterFields}
          noSearch
          hideButton
          path={path}
          query={query}
          setQuery={setQuery}
          filterHandler={filterHandler}
        />
      </Grid>

      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Table
          columns={COLUMNS}
          data={equipments}
          query={query}
          setQuery={setQuery}
          HeaderComponent={
            <TableHeader
              headerFilters={[
                <EquipmentFilter
                  value={filter}
                  query={query}
                  options={FILTERS}
                  onChange={handleDaysFilter}
                />,
              ]}
              headerActions={[
                <TableHeaderAction
                  title="Exportar XLS"
                  IconProps={{ style: theme.custom.icon }}
                  Icon={props => <Typography {...props}>XLS</Typography>}
                  disabled={equipments && equipments.totalItems === 0}
                  onClick={() => handleExportXLS(query)}
                />,
              ]}
            />
          }
        />
      </Paper>
    </Grid>
  );
};

export default AwaitingActivation;
