import {
  datetimeFormatter,
  durationFormatter,
  percentageFormatter,
  temperatureFormatter,
  latlongFormatter,
  speedFormatter,
  defaultFormatter,
  imeiFormatter,
} from 'utils/formatters';

import {minutesToTimeString} from 'utils/helpers'

export const REPORT_COLUMNS = [
  {
    label: 'Empresa',
    value: row => `${row.currentCompany && row.currentCompany.name || ''}`,
    sort: 'currentCompany.name',
  },
  {
    label: 'Equipamento',
    value: row => row.shortImei,
    sort: 'shortImei',
  },
  {
    label: 'Veículo',
    value: row => `${row.vehicle || '-'}`,
    sort: 'vehicle',
  },
  {
    label: 'Última Transmissão',
    value: row => `${datetimeFormatter(row.lastEvent.timestamp)}`,
    sort: 'lastEvent.timestamp',
  },
  {
    label: 'Intervalo',
    value: row => `${minutesToTimeString(row.lastEvent.positionSendInterval)}`,
    sort: 'lastEvent.positionSendInterval',
  },
  {
    label: 'Bat.',
    value: row => `${percentageFormatter(Number((row.lastEvent.adjustedInternalBatteryPercentage || row.lastEvent.internalBatteryPercentage) / 100))}`,
    sort: 'lastEvent.internalBatteryPercentage',
  },
  {
    label: 'Umidade',
    value: () => '-',
    sort: '',
  },
  {
    label: 'Temperatura',
    value: row => `${temperatureFormatter(row.lastEvent.temperature)}`,
    sort: 'lastEvent.temperature',
  },
  {
    label: 'Sinal',
    value: row => `${percentageFormatter(Number(row.lastEvent.gsmModelSignal / 31))}`,
    sort: 'lastEvent.gsmModelSignal',
  },
  {
    label: 'Jaming',
    value: row => `${row.situation === 'JAMMING_DETECTED' ? 'Sim' : 'Não'}`,
    sort: '',
  },
  {
    label: 'Lat/Long',
    value: row => `${latlongFormatter(row.lastEvent.latitude, row.lastEvent.longitude)}`,
    sort: '',
  },
  {
    label: 'Velocidade',
    value: row => `${speedFormatter(row.lastEvent.gpsSpeed)}`,
    sort: 'lastEvent.gpsSpeed',
  },
  {
    label: 'Curso',
    value: () => '-',
    sort: '',
  },
  {
    label: 'Satélites',
    value: row => defaultFormatter(row.lastEvent.gpsSatelliteNumber),
    sort: 'lastEvent.gpsSatelliteNumber',
  },
  {
    label: 'Local',
    value: row => row.lastEvent.local,
    sort: 'lastEvent.local',
  },
];
