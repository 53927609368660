import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, InputAdornment, Typography } from '@material-ui/core';
import {
  ButtonSubmit,
  ButtonRemove,
  ButtonCancel,
  ButtonGeneric,
} from 'components/buttons/ButtonsForm';
import { FormControl } from '@material-ui/core';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';

import Permission from 'components/permissions/Permission';
import Form, { FormSection } from './Form';
import { TextField } from '../fields';
import { AutocompleteField, RadioGroupField } from '../fields';
import { useFields } from '../../hooks/fields';
import { useFetch, useAuthenticated } from '../../hooks/fetch';
import { emitEvent } from '../../utils/events';

import theme from 'styles/theme';
import { EQUIPMENT_STATUS } from 'utils/statuses';
import NumberInput from 'components/fields/NumberInput.field';

const EquipmentForm = props => {
  const { classes } = props;
  const [isLoading, setIsLoading] = useState({
    companies: false,
    mobileOperators: false,
    colors: false,
    exemplaries: false,
    packings: false,
    models: false,
    equipmentType: false,
    baitType: false,
    form: false,
  });
  const [submitForm, setSubmitForm] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [baitTypes, setBaitTypes] = useState([]);

  const fields = useFields([
    'code',
    'imei',
    'iccid',
    'lineNumber',
    'currentCompany',
    'equipmentType',
    'baitType',
    'expirationDate',
    'rfNumber',
    'protocolVersion',
    'vehicle',
    'color',
    'packing',
    'model',
    'mobileOperator',
    'exemplary',
    'antiJamming',
    'battery',
    'shortImei',
  ]);

  // Load companies
  const { data } = useAuthenticated(useFetch, 'company?notPaginated=true', {
    beforeFetch() {
      setIsLoading({ ...isLoading, companies: true });
    },
  });

  // Sort companies
  useEffect(() => {
    const sortedCompanies =
      (data && data.data.sort((a, b) => (a.name > b.name ? 1 : -1))) || [];
    setCompanies(sortedCompanies);
    setIsLoading({ ...isLoading, companies: false });
  }, [data]);

  // Load colors
  const { data: colors } = useAuthenticated(useFetch, 'equipment-color/list', {
    beforeFetch() {
      setIsLoading({ ...isLoading, colors: true });
    },
    afterFetch() {
      setIsLoading({ ...isLoading, colors: false });
    },
    defaultValue: [],
  });

  // Load exemplaries
  const { data: exemplaries } = useAuthenticated(
    useFetch,
    'equipment-exemplary/list',
    {
      beforeFetch() {
        setIsLoading({ ...isLoading, exemplaries: true });
      },
      afterFetch() {
        setIsLoading({ ...isLoading, exemplaries: false });
      },
      defaultValue: [],
    }
  );

  // Load colors
  const { data: models } = useAuthenticated(useFetch, 'equipment-model/list', {
    beforeFetch() {
      setIsLoading({ ...isLoading, models: true });
    },
    afterFetch() {
      setIsLoading({ ...isLoading, models: false });
    },
    defaultValue: [],
  });

  // Load pakckings
  const { data: packings } = useAuthenticated(
    useFetch,
    'equipment-packing/list',
    {
      beforeFetch() {
        setIsLoading({ ...isLoading, packings: true });
      },
      afterFetch() {
        setIsLoading({ ...isLoading, packings: false });
      },
      defaultValue: [],
    }
  );

  // Load mobile operators
  const { data: mobileOperators } = useAuthenticated(
    useFetch,
    'equipment-mobile-operator/list',
    {
      beforeFetch() {
        setIsLoading({ ...isLoading, mobileOperators: true });
      },
      afterFetch() {
        setIsLoading({ ...isLoading, mobileOperators: false });
      },
      defaultValue: [],
    }
  );

  // Load equipment types
  useEffect(() => {
    setIsLoading({ ...isLoading, equipmentType: true });

    const eTypes = [
      { id: 1, name: 'Isca' },
      { id: 2, name: 'Veículo Passeio' },
      { id: 3, name: 'Truck' },
      { id: 4, name: 'Carreta' },
      { id: 5, name: 'Moto' },
      { id: 6, name: 'Cavalo' },
      { id: 7, name: 'Bloqueador' },
    ].sort((a, b) => (a.name > b.name ? 1 : -1));

    setEquipmentTypes(eTypes);
    setIsLoading({ ...isLoading, equipmentType: false });
  }, []);

  // Load bait types
  useEffect(() => {
    setIsLoading({ ...isLoading, baitType: true });

    const bTypes = [
      { id: 1, name: 'Retornável' },
      { id: 2, name: 'Descartável' },
      { id: 3, name: 'Customizada' },
      { id: 4, name: 'Teste' },
    ].sort((a, b) => (a.name > b.name ? 1 : -1));

    setBaitTypes(bTypes);
    setIsLoading({ ...isLoading, baitType: false });
  }, []);

  /**======================
   * LOAD INITIAL FORM DATA
   * ====================== */
  useEffect(() => {
    setFormDisabled({ form: true });
    if (props.data) {
      for (const [key, value] of Object.entries(props.data)) {
        if (fields[key] && fields[key].current) {
          if (fields[key].current.setFieldValue) {
            fields[key].current.setFieldValue(value);
          }
        }
      }
    }
    setFormDisabled(false);
  }, [props.data]);

  const validateForm = async event => {
    event.preventDefault();
    const nonValidatedFields = Object.values(fields).filter(
      f => !f.current.alreadyValidated
    );
    nonValidatedFields.map(f => f.current.validateField());
    setSubmitForm(true);
  };

  // Handle form submission
  useEffect(() => {
    if (submitForm) {
      (async () => {
        await handleSubmit();
      })();
    }
  }, [submitForm]);
  const handleSubmit = async () => {
    // Check for invalid fields
    const invalidFields = Object.values(fields).filter(
      f => f.current.isInvalid
    );
    if (invalidFields.length > 0) {
      emitEvent('showSnack', {
        message: 'Erro ao validar formulário',
        type: 'error',
      });
      setSubmitForm(false);
    }

    // All fields are valid
    // Commit changes to the server
    else {
      setFormDisabled(true);
      setIsLoading({ ...isLoading, form: true });

      // Get all fiels
      const data = {};
      for (const [key, value] of Object.entries(fields)) {
        data[key] = value.current.raw;
      }

      // Update select fields
      data['currentCompany'] = {
        _id: data.currentCompany._id,
        name: data.currentCompany.name,
      };
      data['protocolVersion'] = data.protocolVersion.id;

      data['status'] = EQUIPMENT_STATUS.REGISTERED;

      try {
        console.log(data);
        await props.handleSubmit(data);
        setFormDisabled(false);
        setIsLoading({ ...isLoading, form: false });
        setSubmitForm(false);
      } catch (err) {
        emitEvent('showSnack', {
          message: 'Erro ao processar formulário',
          type: 'error',
        });
        setFormDisabled(false);
        setIsLoading({ ...isLoading, form: false });
        setSubmitForm(false);
      }
    }
  };

  return (
    <Form title={props.title}>
      <form onSubmit={validateForm} noValidate>
        <FormSection title={'Informações gerais'}>
          {props.data && (
            <Grid container alignItems="flex-start">
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{
                      transform: 'translate(0, 1.5px) scale(0.75)',
                      transformOrigin: 'top left',
                    }}
                  >
                    Status
                  </FormLabel>

                  <Typography className={classes.label}>
                    {props.data.isActive ? 'Ativo' : 'Inativo'}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                ref={fields.code}
                fullWidth
                className={classes.fullWidthField}
                label="Código"
                name="code"
                disabled={formDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                type="number"
                ref={fields.imei}
                fullWidth
                className={classes.fullWidthField}
                label="IMEI"
                name="imei"
                disabled={formDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                type="number"
                ref={fields.shortImei}
                fullWidth
                className={classes.fullWidthField}
                label="Imei curto"
                name="shortImei"
                disabled={formDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                type="number"
                ref={fields.iccid}
                fullWidth
                className={classes.fullWidthField}
                label="ICCID"
                name="iccid"
                disabled={formDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                type="number"
                ref={fields.lineNumber}
                fullWidth
                className={classes.fullWidthField}
                label="Número da Linha"
                name="lineNumber"
                disabled={formDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <NumberInput
                required
                ref={fields.rfNumber}
                fullWidth
                className={classes.fullWidthField}
                label="RF Número"
                name="rfNumber"
                disabled={formDisabled}
                minValue={1}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  required
                  innerRef={fields.currentCompany}
                  label="Empresa"
                  name="currentCompany"
                  options={companies}
                  isLoading={isLoading.companies}
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt._id}
                  noOptionsMessage={() => 'Nenhuma empresa encontrada'}
                  loadingMessage={() => 'Carregando empresas...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.mobileOperator}
                  label="Operadora"
                  name="mobileOperator"
                  required
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt._id}
                  options={mobileOperators}
                  isLoading={isLoading.mobileOperators}
                  noOptionsMessage={() => 'Nenhuma operadora encontrada'}
                  loadingMessage={() => 'Carregando operadoras...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.exemplary}
                  label="Exemplar de equipamento"
                  name="exemplary"
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt._id}
                  options={exemplaries}
                  isLoading={isLoading.exemplaries}
                  noOptionsMessage={() => 'Nenhum exemplar encontrado'}
                  loadingMessage={() => 'Carregando exemplares...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.color}
                  label="Cor"
                  name="color"
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt._id}
                  options={colors}
                  isLoading={isLoading.colors}
                  noOptionsMessage={() => 'Nenhuma cor encontrada'}
                  loadingMessage={() => 'Carregando cores...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.model}
                  label="Modelo"
                  name="model"
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt._id}
                  options={models}
                  isLoading={isLoading.models}
                  noOptionsMessage={() => 'Nenhum modelo encontrado'}
                  loadingMessage={() => 'Carregando modelos...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  innerRef={fields.packing}
                  label="Embalagem"
                  name="packing"
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt._id}
                  options={packings}
                  isLoading={isLoading.packings}
                  noOptionsMessage={() => 'Nenhuma embalagem encontrada'}
                  loadingMessage={() => 'Carregando embalagem...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  required
                  innerRef={fields.equipmentType}
                  label="Tipo de Equipamento"
                  name="equipmentType"
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt.id}
                  options={equipmentTypes}
                  isLoading={isLoading.equipmentType}
                  noOptionsMessage={() => 'Nenhuma tipo encontrado'}
                  loadingMessage={() => 'Carregando tipos de equipamento...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  required
                  innerRef={fields.baitType}
                  label="Tipo de Isca"
                  name="baitType"
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt.id}
                  options={baitTypes}
                  isLoading={isLoading.baitType}
                  noOptionsMessage={() => 'Nenhuma tipo encontrado'}
                  loadingMessage={() => 'Carregando tipos de iscas...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.fullWidthField}>
                <AutocompleteField
                  required
                  innerRef={fields.protocolVersion}
                  label="Protocolo"
                  name="protocolVersion"
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt.id}
                  options={[
                    {
                      id: 'V1',
                      name: 'Protocolo V1',
                    },
                    {
                      id: 'V2',
                      name: 'Protocolo V2',
                    },
                  ]}
                  noOptionsMessage={() => 'Nenhum protocolo encontrado'}
                  loadingMessage={() => 'Carregando protocolos...'}
                  isClearable={true}
                  disabled={formDisabled}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                type="number"
                ref={fields.expirationDate}
                fullWidth
                className={classes.fullWidthField}
                label="Tempo para Expiração"
                name="expirationDate"
                disabled={formDisabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">dias</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.vehicle}
                fullWidth
                className={classes.fullWidthField}
                label="Veículo"
                name="vehicle"
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                ref={fields.battery}
                fullWidth
                className={classes.fullWidthField}
                label="Bateria (mAh)"
                name="battery"
                disabled={formDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <RadioGroupField
                ref={fields.antiJamming}
                row
                required
                title="Anti-Jammer"
                default="Não"
                options={[
                  { value: 'Não', label: 'Não' },
                  { value: 'Sim', label: 'Sim' },
                ]}
                labelProps={{
                  style: {
                    transform: 'translate(0, 1.5px) scale(0.75)',
                    transformOrigin: 'top left',
                  },
                }}
                formControlLabelProps={{
                  labelPlacement: 'start',
                }}
              />
            </Grid>
          </Grid>
        </FormSection>

        <Grid container justify="flex-end" spacing={1}>
          {props.handleEnableDisable && (
            <Permission name="EquipmentEnableDisable">
              <Grid item xs={12} md="auto">
                <ButtonGeneric
                  disabled={formDisabled}
                  onClick={props.handleEnableDisable}
                >
                  {props.data.isActive ? 'Desativar' : 'Ativar'}
                </ButtonGeneric>
              </Grid>
            </Permission>
          )}

          {props.handleCancel && (
            <Grid item xs={12} md="auto">
              <ButtonCancel
                onClick={props.handleCancel}
                disabledCondition={formDisabled}
              ></ButtonCancel>
            </Grid>
          )}

          {props.handleRemove && (
            <Grid item xs={12} md="auto">
              <ButtonRemove
                onClick={props.handleRemove}
                conditionDisabled={formDisabled}
              ></ButtonRemove>
            </Grid>
          )}

          {props.handleSubmit && (
            <Grid item xs={12} md="auto">
              <ButtonSubmit conditionDisabled={formDisabled}></ButtonSubmit>
            </Grid>
          )}
        </Grid>
      </form>
    </Form>
  );
};

const styles = theme => ({
  btnRoot: {
    minWidth: 125,
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    // Small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
});
export default withStyles(styles)(EquipmentForm);
