import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Chip } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import { post, put } from 'services/fetch';
import Modal from 'components/modals/Modal';
import { getUser } from 'services/user';

import * as moment from 'moment';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  textarea: {
    borderRadius: 22,
    border: 'solid 1px #808492',
    resize: 'none',
    outline: 'none',
    padding: 22,
    fontFamily: 'Raleway',
    fontSize: 15,
    width: '100%',
    fontWeight: '400',
  },
  lastObs: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
    margin: '0.5em 0',
  },
  obsText: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
    wordWrap: 'break-word',
  },
  obsDate: {
    fontSize: 15,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  archiveButton: {
    color: '#7f869d',
    padding: '1em',
    fontSize: '16px',
  },
  archiveChip: {
    color: '#7f869d',
    fontSize: '16px',
    marginLeft: '1em',
  },
  lastObsWrapper: {
    minHeight: '5em',
  },
}));

const ModalObs = props => {
  const { isVisible, setIsVisible, equipment, onSubmit } = props;
  const classes = useStyles();
  const [lastObs, setLastObs] = useState({});
  const [text, setText] = useState('');
  const [archived, setArchived] = useState(
    equipment.lastObservation && equipment.lastObservation.archived
  );

  const [showModal, setShowModal] = useState(isVisible);

  useEffect(() => {
    if (equipment && equipment.lastObservation) {
      setLastObs(equipment.lastObservation);
    }
  }, [equipment]);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleSubmit = async () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
    const user = await getUser();
    const response = await post('equipment-observation', {
      text,
      archived: false,
      equipment: equipment._id,
      user: user._id,
    });
    if (
      equipment.lastObservation &&
      equipment.lastObservation.archived !== archived
    ) {
      await put(`equipment-observation/${equipment.lastObservation._id}`, {
        text: equipment.lastObservation.text,
        archived,
        equipment: equipment._id,
        user: user._id,
      });

      await put(`equipment/${equipment._id}`, {
        lastObservation: {
          text,
          archived,
          equipment: equipment._id,
          user: user._id,
        },
      });
    }

    const newObs = await response.json();
    if (newObs) {
      logAction(
        `Adicionou uma observação ao equipamento ${equipment.shortImei}`
      );
      if (onSubmit) {
        onSubmit();
      }
      setLastObs(newObs);
      return true;
    }
  };
  const onChange = e => {
    setText(e.target.value);
  };
  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
  };

  const handleArchiveButton = () => {
    setArchived(!archived);
  };

  return (
    <Modal
      handleSubmit={handleSubmit}
      onSuccessMessage="Observação salva com sucesso."
      isVisible={showModal}
      setIsVisible={setShowModal}
      title="Observações"
      buttonLabel="Salvar"
      onClose={onClose}
    >
      <textarea
        onChange={onChange}
        placeholder="Observação sobre o equipamento"
        className={classes.textarea}
        rows="7"
      />
      <div style={{ paddingTop: 34 }}>
        <Grid container direction="row" alignItems="center">
          <Typography className={classes.lastObs}>Última observação</Typography>
          {archived && (
            <Chip className={classes.archiveChip} label="Arquivada" />
          )}
        </Grid>
        <Grid container className={classes.lastObsWrapper}>
          <Grid item xs={9}>
            <Typography className={classes.obsText}>
              {lastObs.text || 'Nenhuma observação foi realizada.'}
            </Typography>
          </Grid>
          {lastObs.text && !archived && (
            <Grid item xs={3}>
              <Grid container justify="flex-end">
                <Button
                  startIcon={<ArchiveIcon />}
                  className={classes.archiveButton}
                  onClick={handleArchiveButton}
                >
                  Arquivar
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Typography className={classes.obsDate}>
          {lastObs.date && moment(lastObs.date).format('DD/MM/YY HH:mm')}
        </Typography>
      </div>
    </Modal>
  );
};

export default ModalObs;
