import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import {
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from '@material-ui/core';

import theme from 'styles/theme';
import { fetchAuthenticated } from 'services/fetch';
import { buildUrl, defaultQuery, buildQuery } from 'utils/query';
import { emitEvent } from 'utils/events';

import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import { KeyboardArrowDown } from '@material-ui/icons';

import Filter from 'components/filter/Filter';
import FilterHandler from 'utils/FilterHandler';
import FilterAutoComplete from 'components/filter/FilterAutoComplete';

import { useAccessLog } from 'hooks/logs';
import { logAction } from 'utils/logs';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
  paper: {
    borderRadius: theme.border.radius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  filters: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  button: {
    marginLeft: 20,
  },
  shrink: {
    color: theme.palette.gray.gray_5,
    fontWeight: 'bold',
    fontSize: 16,
    transform: 'translate(0, 1.5px)',
    whiteSpace: 'nowrap',
  },
}));

const formatStatus = status => {
  switch (status) {
    case 'normal':
      return 'Dentro dos limites';
    case 'below':
      return 'Abaixo do mínimo';
    case 'above':
      return 'Acima do limite';
    default:
      return '-';
  }
};

const FILTERS = [
  { label: 'Qualquer', value: 'all' },
  { label: 'Dentro dos limites', value: 'normal' },
  { label: 'Acima do limite', value: 'above' },
  { label: 'Abaixo do mínimo', value: 'below' },
];

const COMPANIES_PATH = 'company/equipment';

const ClientEquipmentCount = props => {
  useAccessLog('Acesso a ferramenta de equipamentos aguardando ativação');
  const classes = useStyles();
  const { location } = props;

  const [path, setPath] = useState(buildUrl(COMPANIES_PATH, location));
  const [query, setQuery] = useState(defaultQuery);
  const [companies, setCompanies] = useState(null);

  const [filter, setFilter] = useState(FILTERS[0].value);
  const filterHandler = new FilterHandler(query, setQuery, location);

  // Load data
  useEffect(() => {
    const queryPath = `${COMPANIES_PATH}${query.queryString || ''}`;
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const data = await (await fetchAuthenticated('get', queryPath)).json();
      setCompanies(data);
      emitEvent('hideGlobalLinearProgress');
    };
    fn();
  }, [query]);

  const COLUMNS = [
    {
      label: 'Empresa',
      sort: 'name',
      value: row => row.name,
    },
    {
      label: 'Nº de Equipamentos',
      sort: 'totalEquipment',
      value: row => row.totalEquipment || 0,
    },
    {
      label: 'Nº Mín. de Equipamentos',
      sort: 'minEquipment',
      value: row => row.minEquipment || '-',
    },
    {
      label: 'Nº Máx. de Equipamentos',
      sort: 'maxEquipment',
      value: row => row.maxEquipment || '-',
    },
    {
      label: 'Situação',
      value: row => formatStatus(row.status),
    },
  ];

  const handleCompanySelect = ({
    opt,
    filterHandler,
    setIsLoading,
    isLoading,
  }) => {
    if (opt) {
      filterHandler.updateQueryFilter({
        _id: { value: opt ? opt._id : '', toObjectId: false },
      });
    } else {
      filterHandler.removeFilter('_id');
    }
    setIsLoading({ ...isLoading, equipments: true });
  };

  const handleDaysFilter = event => {
    const filterVal = event.target.value;

    setFilter(filterVal);
    filterHandler.removeFilters(['status']);

    if (event.target.value === 'any') return;

    filterHandler.updateQueryFilter({
      status: filterVal,
    });
  };

  const handleExportXLS = async query => {
    try {
      const response = await fetchAuthenticated(
        'GET',
        `company/report/equipment-count/xls${query.queryString}`
      );

      let filterType = FILTERS.filter(opt => opt.value === filter)[0];
      filterType = filterType.label.replace(' ', '_');

      const blob = await response.blob();
      saveAs(blob, `Relatorio_${filterType}.xls`);
      logAction('Exportou relatório de equipamentos por cliente em XLS');
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const filterFields = [
    {
      name: 'companies',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Empresa"
          showPlaceholder
          hideLabel
          path="company?notPaginated=true"
          name="companies"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={handleCompanySelect}
        />
      ),
    },
  ];

  const EquipmentFilter = props => {
    const classes = useStyles();
    const { value, onChange, options, query } = props;
    return (
      <FormControl>
        <InputLabel classes={{ shrink: classes.shrink }}>Status</InputLabel>
        <Select
          disableUnderline
          IconComponent={KeyboardArrowDown}
          style={{ marginTop: 20 }}
          onChange={e => onChange(e, query)}
          value={value}
        >
          {options.map(o => (
            <MenuItem value={o.value} key={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <Filter
          containerStyle={{ paddingLeft: 0, paddingTop: 0 }}
          asyncFields={filterFields}
          noSearch
          hideButton
          path={path}
          query={query}
          setQuery={setQuery}
          filterHandler={filterHandler}
        />
      </Grid>

      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Table
          columns={COLUMNS}
          data={companies}
          query={query}
          setQuery={setQuery}
          HeaderComponent={
            <TableHeader
              headerFilters={[
                <EquipmentFilter
                  value={filter}
                  query={query}
                  options={FILTERS}
                  onChange={handleDaysFilter}
                />,
              ]}
              headerActions={[
                <TableHeaderAction
                  title="Exportar XLS"
                  IconProps={{ style: theme.custom.icon }}
                  Icon={props => <Typography {...props}>XLS</Typography>}
                  disabled={companies && companies.totalItems === 0}
                  onClick={() => handleExportXLS(query)}
                />,
              ]}
            />
          }
        />
      </Paper>
    </Grid>
  );
};

export default ClientEquipmentCount;
