import L from 'leaflet';

export const getField = (instance, fieldName, defaultValue = '', formatter = '') => {
  const fieldPath = fieldName.split('.');
  try {
    const value = fieldPath.reduce((a, b) => a[b], instance);
    if (!value && value !== 0) {
      if (typeof defaultValue === "function")
        return defaultValue(value);
      return defaultValue;
    }
    if (formatter) {
      return value + formatter;
    }
    return value;
  } catch (err) {
    if (typeof defaultValue === "function")
      return defaultValue(undefined);
    return defaultValue;
  }
};

export const formatTemperature = (instance, fieldName) => {
  const value = getField(instance, fieldName);
  if (!value) {
    return "Indisponível";
  }
  if (value > 0) {
    return `+${value} Cº`;
  }
  return `${value} Cº`;
}

export const boolToString = (value, truthy = 'Sim', falsy = 'Não', defaultValue = '') => {
  if (value === '' || value === undefined) {
    return defaultValue;
  }
  return value ? truthy : falsy;
};

export const isRfOn = (event) => {
  if (!event) return 'Indisponível';
  if (event.protocolVersion === 'V2') {
    return (
      ((event.status.transmitter434Mhz || event.status.transmitter928Mhz) && 'Ligado') ||
      'Desligado'
    );
  }
  return (event.rfTriangulation && 'Ligado') || 'Desligado';
};

export const isPositionValid = (event) => {
  const positionIndicator = getPositionIndicator(event);
  if (event.isPositionCellID && !event.isPositionValid) {
    return false;
  }
  if (event.latitude === '0' && event.longitude === '0') {
    return false;
  }
  if (positionIndicator === 'GPS' && !event.isPositionValid)
    return false;
  return true;
}

export const getPositionIndicator = (event, useFirst = false) => {
  if (event && event.positionIndicator) {
    /* the only way we know for sure this event is CellID...*/
    if (event.isPositionCellID) {
      return 'CellID';
    }
    if (event.positionIndicator[0] === 'LBS' && !useFirst) {
      if (event.positionIndicator[1] === 'Quectel') return `XGlobal`;
      else return event.positionIndicator[1];
    }
    if (event.positionIndicator[0] === 'Eseye') {
      return 'XGlobal 2';
    }

    return event.positionIndicator[0];
  }
};

export const getEventName = (event) => {
  const position = getPositionIndicator(event);
  if (!position) return '';
  return {
    'XGlobal': 'lastEventLbsXglobal',
    'Google': 'lastEventLbsGoogle',
    'CellID': 'lastEventLbsCellID',
    'XGlobal 2': 'lastEventEseye',
    'GPS': 'lastEventGps',
    // 'Safeloggy': 'lastEvent',
  }[position];
};

export const getBatteryStatus = (event) => {
  return event && event.status
    ? event.protocolVersion !== 'V2'
      ? !!event.batteryStatus
      : !!event.status.charging
    : undefined;
};

export const getStatus = (equipment, testPoff = false) => {
  if (testPoff) {
    if (equipment.poffCommands && equipment.poffCommands.length) {
      if (equipment.poffCommands[0].status === 'SCHEDULED') {
        return 'Aguardando ativação';
      }
    }
  }

  return {
    suspect: 'Em suspeita',
    emergency: 'Em emergência',
    active: 'Ativo',
  }[equipment.handlingStatus];
}

export const minutesToTimeString = (minutes, defaultValue = '00:00') => {
  let tString = defaultValue;

  if (minutes) {

    if (minutes.match(/\d\d:\d\d$/)) {
      return minutes;
    }

    const _minutes = parseInt(minutes, 10);
    const _hours = Math.floor(_minutes / 60);
    const h = `${_hours}`.padStart(2, '0');
    const m = `${_minutes - (_hours * 60)}`.padStart(2, '0');
    tString = h + ':' + m;
  }
  return tString;
};


export const addPointsToMap = (map, points, onClick) => {
  const defaultIcon = L.icon({
    iconUrl: require('assets/icon/Pin_blue.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const startIcon = L.icon({
    iconUrl: require('assets/icon/Pin_green.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const endIcon = L.icon({
    iconUrl: require('assets/icon/Pin_red.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const visiblePoints = {};
  const KEY_INDEXES = { first: 0, last: points.length - 1 };
  (points || []).forEach((p, index) => {
    const key = `${p.latitude},${p.longitude}`;
    if (visiblePoints[key]) {
      visiblePoints[key] = {
        data: [...visiblePoints[key].data, p],
        isLast: index === KEY_INDEXES.last,
        isFirst: visiblePoints[key].isFirst,
      };
    } else {
      visiblePoints[key] = {
        data: [p],
        isFirst: index === KEY_INDEXES.first,
        isLast: index === KEY_INDEXES.last,
      };
    }
  });

  const markers = [];
  Object.keys(visiblePoints).forEach((key) => {
    const coord = key.split(',');
    const value = visiblePoints[key];

    const m = L.marker(coord, {
      icon: value.isFirst ? startIcon : value.isLast ? endIcon : defaultIcon,
    });

    markers.push(m);
    m.on('click', (e) => {
      onClick(value);
    });
    m.addTo(map);
  });
  return markers;
};
