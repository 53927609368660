import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { saveAs } from 'file-saver';
import theme from 'styles/theme';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import Filter from 'components/filter/Filter';
import FilterAutoComplete from 'components/filter/FilterAutoComplete';

import { useQuery } from 'hooks/fetch';
import { useAccessLog } from 'hooks/logs';
import { usePaginatedData } from 'hooks/common';
import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';

import { REPORT_COLUMNS } from './columns';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
  filter: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const PATH = 'equipment';
const EquipmentReport = () => {
  useAccessLog('Acesso ao relatórios de equipamentos');
  const classes = useStyles();
  const [data, setData] = usePaginatedData();

  const [query, setQuery, isLoading] = useQuery(
    PATH,
    setData,
    ['shortImei', 'imei', 'iccid', 'currentCompany.name', 'lastEvent'],
    {}
  );
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const exportSpreadsheet = async query => {
    setIsGeneratingReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', {
        message: 'Exportação limitada a 5000 itens',
        type: 'info',
      });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/general/xls${query.queryString}`
    );
    logAction('Exportou relatório de equipamentos em XLS');
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Equipamentos.xls');
    setIsGeneratingReport(false);
  };

  const exportPdf = async query => {
    setIsGeneratingReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', {
        message: 'Exportação limitada a 5000 itens',
        type: 'info',
      });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/general/pdf${query.queryString}`
    );
    logAction('Exportou relatório de equipamentos em PDF');
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Equipamentos.pdf');
    setIsGeneratingReport(false);
  };

  const onCompanyChange = ({ opt, filterHandler, isLoading, setIsLoading }) => {
    setIsLoading({ ...isLoading, company: true });

    if (!opt) {
      filterHandler.removeFilters(['currentCompany._id']);
    } else {
      filterHandler.updateQueryFilter({
        'currentCompany._id': { value: opt._id, toObjectId: false },
      });
    }
  };

  const _filters = [
    {
      name: 'companies',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{ marginTop: 5 }}
          placeholder="Empresa"
          showPlaceholder
          path="company?notPaginated=true&fields=_id,name"
          name="companies"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={onCompanyChange}
        />
      ),
    },
  ];

  const _tableHeaderActions = [
    <TableHeaderAction
      title="Exportar XLS"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>XLS</Typography>}
      onClick={() => exportSpreadsheet(query)}
    />,
    <TableHeaderAction
      title="Exportar PDF"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>PDF</Typography>}
      onClick={() => exportPdf(query)}
    />,
  ];

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <Filter
          noSearch
          hideButton
          path={PATH}
          query={query}
          setQuery={setQuery}
          asyncFields={_filters}
          containerStyle={{ paddingLeft: 0, paddingTop: 0 }}
        />
      </Grid>
      <Table
        columns={REPORT_COLUMNS}
        data={data}
        query={query}
        setQuery={setQuery}
        containerStyle={{ marginTop: 0 }}
        isLoading={isLoading || isGeneratingReport}
        HeaderComponent={<TableHeader headerActions={_tableHeaderActions} />}
      />
    </Grid>
  );
};

export default EquipmentReport;
