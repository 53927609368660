// General imports
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';

// Material-ui imports
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Paper,
  InputAdornment,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';

// Project imports
import { fetchAuthenticated } from 'services/fetch';
import { buildUrl, defaultQuery } from 'utils/query';
import { emitEvent } from 'utils/events';
import { CheckboxColumn } from 'components/table/columns';
import { useEffectSkipFirst } from 'hooks/common';

// Project components
import Filter from "components/filter/Filter";
import SimpleTable from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import CircularLoading from 'components/loading/CircularLoading';

import FilterHandler from 'utils/FilterHandler';

import { useSimpleFilterContext } from 'components/filter/SimpleFilterProvider';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: theme.main.padding.top,
    paddingBottom: 25,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gray.gray_4,
    fontSize: 22,
    lineHeight: 'normal',
  },
  paper: {
    borderRadius: theme.border.radius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  btnRoot: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    padding: '8px 18px',
    boxShadow: 'none',
  },
  header: {
    marginBottom: theme.main.padding.bottom,
  },
  searchIcon: {
    color: theme.palette.gray.gray_5,
  },
  headerFiltersLabel: {
    color: theme.palette.gray.gray_4,
    fontSize: 15,
  }
}));


const isChecked = (value, checkedList) => checkedList.indexOf(value) > -1;

const toggleCheckbox = (value, checkedList, remove=true) => {
  const index = checkedList.indexOf(value);
  const newList = [...checkedList];

  if (index > -1) {
    if(remove) {
      newList.splice(index, 1);
    }
  } else {
    newList.push(value);
  }
  return newList;
};

function ActionList (props) {
  const filterContext = useSimpleFilterContext();

  const classes = useStyles();
  const { location, match, history, containerStyle, hideFilters, genericSearchFields } = props;
  const { headerActions, headerFilters } = props;

  const [ fetchPath, setFetchPath ] = useState(buildUrl(props.basePath, location));
  const [ query, setQuery ] = useState(defaultQuery);
  const [ checkedList, setCheckedList ] = useState([]);
  const [ data, setData ] = useState(null);
  const [ onCheckAll, setOnCheckAll ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isPageChecked, setIsPageChecked ] = useState(false);

  const [ modals, setModals ] = useState(props.modals);

  const filterHandler = new FilterHandler(query, setQuery, location);

  const [chips, setChips] = useState((filterContext && filterContext.filterTerms) || []);

  /* state that handle which modal is going to show up */
  const setVisibleModal = (modalName, visible) => {
    setModals({
      ...modals,
      [modalName]: {
        ...modals[modalName],
        isVisible: visible,
      }
    })
  }

  const getFetchData = async () => {
    emitEvent('showGlobalLinearProgress');
    const data = await (await fetchAuthenticated('get', fetchPath)).json();
    setData(data);
    emitEvent('hideGlobalLinearProgress');
  }

  // Load data
  useEffect(() => {
    const fn = async () => {
      await getFetchData();
    }; fn();
  }, [fetchPath]);

  // Update query string
  useEffect(() => {
    if (filterContext) {
      filterContext.updateFilter(query);
    }
    if (query.queryString) {
      setFetchPath(`${props.basePath}${query.queryString}`);
    }
  }, [query]);

  useEffect(() => {
    filterHandler.updateQueryFilter({
      searchText: chips,
      genericSearchFields: genericSearchFields.map(field => field.name),
    });
  }, [chips]);

  /* responsable for loading all data when checkAll checkbox is true */
  useEffectSkipFirst(() => {
    if(onCheckAll) {
      setIsLoading(true);
      const fn = async () => {
        let _fetchPath = fetchPath;
        if (fetchPath.includes('?')) { _fetchPath += '&notPaginated=true&fields=_id'}
        else { _fetchPath += '?notPaginated=true&fields=_id'}
        let data = await (await fetchAuthenticated('get', _fetchPath)).json()
        const newCheckedList = [];
        data.data.forEach(item => newCheckedList.push(item._id));
        setCheckedList(newCheckedList);
        setIsLoading(false);
      }
      fn();
    }
  }, [onCheckAll, fetchPath]);

  /* only clear when check all is off */
  useEffectSkipFirst(() => {
    if(!onCheckAll) {
      setCheckedList([]);
    }
  }, [onCheckAll]);


  useEffectSkipFirst(() => {
    if(data.data.length > 0) {
      let isChecked = true;
      data.data.forEach(item => {
        if(!checkedList.some(e => e === item._id)) {
          isChecked = false;
        }
      });
      setIsPageChecked(isChecked);
    }
  }, [checkedList, data]);


  const onChange = (value, checkedList) => {
    setCheckedList(toggleCheckbox(value, checkedList));
  };

  const onCheckAllInPage = (_checked, checkedList, allOptions) => {
    if(!_checked) {
      const newCheckedList = [];
      checkedList.forEach(item => {
        if(!allOptions.some(equipToUncheck => equipToUncheck === item)) {
          newCheckedList.push(item);
        }
      })
      setCheckedList(newCheckedList)
    } else {
      let newCheckedList = checkedList;
      allOptions.forEach(equipToCheck => {
        newCheckedList = toggleCheckbox(equipToCheck, newCheckedList, false);
      });
      setCheckedList(newCheckedList);
    }
  };

  const onCheckAllChange = async (e) => {
    const checked = e.target.checked;
    setOnCheckAll(checked);
  }

  const columns = [
    {
      component: row => (
        <CheckboxColumn
          onChange={() => {
            onChange(row._id, checkedList);
          }}
          checked={isChecked(row._id, checkedList)}
          key={row._id}
        />
      ),
      label: (index) => (
        <CheckboxColumn
          onChange={(e) => onCheckAllInPage(e, checkedList, data.data.map(e => e._id))}
          checked={isPageChecked}
          key={'checkbox'+ index}
        />
      ),
      cellStyle: {
        width: '30'
      }
    },
    ...props.columns
  ]


  return (
    <div className={classes.root} style={{...containerStyle}}>
      <CircularLoading isLoading={isLoading}/>
      <Grid container justify="space-between" alignItems="flex-start" className={classes.header}>
        {!hideFilters &&
        <Filter
          searchPlaceHolder="Buscar"
          path={props.basePath}
          query={query}
          setQuery={setQuery}
          containerStyle={{
            padding: 0,
            width: 254,
            display: 'flex',
            flexDirection: 'column',
          }}
          genericSearchFields={genericSearchFields}
          inputContainerStyle={{
            width: '100%',
            margin: 0,
          }}
          inputProps={{
            startAdornment: <InputAdornment position="start" className={classes.searchIcon}><Search /></InputAdornment>,
          }}
          hideButton={true}
          showPlaceholder={true}
          hideLabel={true}
          defaultChips={chips}
        />
        }
        {props.addButtonText && props.addUrl &&
          <Button
            classes={{root: classes.btnRoot}}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => history.push(props.addUrl)}
          >{props.addButtonText}</Button>
        }
      </Grid>
      <Paper elevation={0} className={classes.paper}>
        <SimpleTable
          columns={columns}
          // columns={props.columns}
          data={data}
          query={query}
          setQuery={setQuery}
          onRowClick={(row, col) => {history.push(`${match.path}/${row._id}`)}}
          HeaderComponent={(
            <TableHeader
              headerFilters={[
                <div
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  key='checkall'
                >
                  <Checkbox
                    style={{padding: 9 , marginLeft: 10}}
                    color="primary"
                    onChange={(v) => onCheckAllChange(v)}
                  />
                  <Typography className={classes.headerFiltersLabel}>
                    Selecionar todos <b>({checkedList.length} equipamentos)</b>
                  </Typography>

                </div>
              ]}
              headerActions={ headerActions.map((comp) => comp(setVisibleModal, checkedList))}
            >
            </TableHeader>
          )}
        >
        </SimpleTable>

      </Paper>
      {Object.entries(modals).map(([modalName, modal]) => {
        return modal.component({
          isVisible: modal.isVisible,
          /* when modal is closed he needs to our global visible state */
          setVisibleModal: setVisibleModal,
          equipments: checkedList,
          needReloadData: modal.needReloadData && getFetchData,
        });
      })}
    </div>
  );
}

ActionList.defaultProps = {
  containerStyle: {},
  genericSearchFields: [],
  hideFilters: false,
  modals: [],
  headerActions: [],
  headerFilters: [],
  filterContext: null,
}

export default withRouter(ActionList);
