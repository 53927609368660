import React, { useReducer } from 'react';
import NavBar from '../components/NavBar';
import Dashboard from './Dashboard/Dashboard';
import Mapa from './Mapa';
import Cercas from './Fences';
import Records from 'pages/Records';
import Tools from 'pages/Tools';
import Admin from './Admin';
import Relatorios from './Relatorios';
import EquipmentDetail from './EquipmentDetail';
import ProfileEdit from './ProfileEdit';
import CompanyEdit from './Records/Companies/Edit';
import DashboardEquipmentList from './Dashboard/DashboardEquipmentList';
import SubRoutes from '../components/SubRoutes';

import HomeFilterContext from 'pages/HomeFilterContext';

const ACTIONS = {
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATE_EQUIPMENT: 'UPDATE_EQUIPMENT',
  UPDATE_SEARCH_TERM: 'UPDATE_SEARCH_TERM',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_COMPANY:
      return { ...state, company: action.payload };
    case ACTIONS.UPDATE_EQUIPMENT:
      return { ...state, equipment: action.payload };
    case ACTIONS.UPDATE_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };
    default:
      return state;
  }
};

export const pages = [
  {
    name: 'Equipamentos',
    path: '/dashboard/:situation',
    component: DashboardEquipmentList,
    hidden: true,
  },
  { name: 'Dashboard', path: '/dashboard', component: Dashboard },
  { name: 'Mapa', path: '/mapa', component: Mapa, permission: 'ViewMap' },
  {
    name: 'Cercas', path: '/cercas', component: Cercas, permission: 'ViewFence',
  },
  { name: 'Relatórios', path: '/relatorios', component: Relatorios, permissions: [
    "ViewHistoryReport",
    "ViewObservationReport",
    "ViewBatteryReport",
    "ViewPositionReport",
    "ViewBatteryChartReport",
    "ViewTemperatureChartReport",
    "ViewPathReport",
  ]},
  { name: 'Cadastros', path: '/cadastros', component: Records, permissions: [
    "UserRecord",
    "CompanyRecord",
    "EquipmentRecord",
    "EquipmentModelRecord",
    "EquipmentPackingRecord",
    "EquipmentColorRecord",
    "EquipmentMobileOperatorRecord",
    "EquipmentExemplaryRecord",
  ]},
  { name: 'Ferramentas', path: '/ferramentas', component: Tools, permissions: [
    "Poff",
    "Transfer",
    "DisableRF",
    "BulkCommand",
  ]},
  { name: 'Administração', path: '/administracao', component: Admin, permissions: [
    "AccessProfile",
  ]},
  {
    name: 'Detalhes do Equipamento',
    path: '/detalhes-equipamento/:id',
    component: EquipmentDetail,
    hidden: true,
  },
  {
    name: 'Editar Perfil', path: '/editar-perfil/:id', component: ProfileEdit, hidden: true,
  },
  {
    name: 'Editar Empresa', path: '/editar-empresa/:id', component: CompanyEdit, hidden: true,
    basicEdit: true,
  },
];

function Home(props) {
  const initialFilters = {
    company: null,
    equipment: null,
    searchTerm: null,
  }

  const [filters, dispatch] = useReducer(reducer, initialFilters);

  const resetFilters = () => {
    updateCompany(initialFilters.company);
    updateEquipment(initialFilters.equipment);
    updateSearchTerm(initialFilters.searchTerm);
  }
  
  const updateCompany = (company) => {
    dispatch({ type: ACTIONS.UPDATE_COMPANY, payload: company });
  };
  
  const updateEquipment = (equipment) => {
    dispatch({ type: ACTIONS.UPDATE_EQUIPMENT, payload: equipment });
  };
  
  const updateSearchTerm = (searchTerm) => {
    dispatch({ type: ACTIONS.UPDATE_SEARCH_TERM, payload: searchTerm });
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', flex: 1, width: '100%',
    }}
    >
      <NavBar pages={pages} />
      <HomeFilterContext.Provider
        value={{
          ...filters,
          updateCompany,
          updateEquipment,
          updateSearchTerm,
          resetFilters,
        }}
      >
        <SubRoutes defaultPage={1} pages={pages} />
      </HomeFilterContext.Provider>
    </div>
  );
}

export default Home;
