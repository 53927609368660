import * as moment from 'moment';

export const datetimeFormatter = (datetime, format = 'DD/MM/YYYY HH:mm') => {
  if (datetime) {
    return moment(datetime).format(format);
  }
  return '-';
};

export const durationFormatter = (
  duration,
  format = 'HH:mm',
  unit = 'minutes'
) => {
  if (duration) {
    const _duration = moment.duration(Number(duration), unit);
    return moment.utc(_duration.as('milliseconds')).format(format);
  }
  return '-';
};

export const percentageFormatter = (percentage, defaultValue = '-') => {
  try {
    if (percentage || percentage == 0) {
      return Number(percentage).toLocaleString('pt-BR', {
        style: 'percent',
      });
    }
    return defaultValue;
  } catch (err) {
    return defaultValue;
  }
};

export const temperatureFormatter = temperature => {
  if (temperature || temperature == 0) {
    return `${parseFloat(temperature).toFixed(1)}ºC`;
  }
  return '-';
};

export const latlongFormatter = (latitude, longitude) => {
  latitude = String(latitude || '-').substring(0, 8);
  longitude = String(longitude || '-').substring(0, 8);
  return `${latitude} / ${longitude}`;
};

export const speedFormatter = speed => {
  if (speed || speed == 0) {
    return `${parseFloat(speed).toFixed(1)}Km/h`;
  }
  return '-';
};

export const defaultFormatter = (value, defaultValue = '-') => {
  if (!value || (typeof value === 'string' && !value.length)) {
    return defaultValue;
  }
  return value;
};

export const mapPoffStatus = poffCommand => {
  if (poffCommand) {
    switch (poffCommand.status) {
      case 'COMPLETED':
        return 'Expirado';
      case 'RECEIVED':
        return 'Agendado';
      case 'SCHEDULED':
        return 'Aguardando ativação';
      case 'CANCELED':
        return 'Cancelado';
      case 'RUNNING':
        return 'Em execução';
      default:
        return '-';
    }
  } else {
    return '-';
  }
};

export const imeiFormatter = equipment => {
  if (!equipment) return '-';
  return equipment.imei.substring(
    equipment.imei.length,
    equipment.imei.length - 8
  );
};

export const poffFormatter = equipment => {
  if (!equipment) return '- / -';
  if (!equipment.poffCommands || !equipment.poffCommands.length) return '- / -';
  return `${equipment.poffCommands[0].runningDays}/${equipment.poffCommands[0].totalDays}`;
};

export const lastValidPositionDateFormatter = (date, hour) => {
  const TIMEZONE_BR = 3;
  if (date && hour) {
    const myHour = hour.split(':');
    const currentHour = `${parseInt(myHour[0]) - TIMEZONE_BR}:${myHour[1]}`;
    return `${date} ${currentHour}`;
  }
  return '-';
};
