import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 2,
    display: 'inline-flex',
  },
  tag: {
    background: theme.palette.primary.main,
    padding: 8,
    borderRadius: 100,
    color: theme.palette.text.white,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontWeight: '400',
    fontFamily: 'Raleway',
    fontSize: 15,
    maxWidth: 200,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    marginLeft: 4,
  },
}));

const Tag = ({ content, deleteItem }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.tag}>
        <span className={classes.label}>{content}</span>
        {deleteItem && (
          <IconButton
            variant="contained"
            onClick={deleteItem}
            size="small"
            className={classes.icon}
          >
            <Close fontSize="small" color="secondary" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default Tag;
