import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/styles';
import { Typography, CircularProgress, Zoom, Grow, Slide, Fade } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';

import { put, get, fetchAuthenticated } from 'services/fetch';

import ReactSVG from 'react-svg'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 22,
    height: 138,
    cursor: 'pointer',
    backgroundColor: 'white',
    boxShadow: "0 6px 23px 2px rgba(111, 111, 111, 0.1)",
    display: 'flex',
    flexDirection: 'row',
  },
  cardInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardQuantity: {
    marginTop: 15,
    fontSize: props => 52 * props.windowWidth / 1920,
    fontWeight: 'bold',
    marginLeft: 18,
    color: theme.palette.gray.gray_4,
    lineHeight: 1,
  },
  cardLabel: {
    fontSize: props => Math.max(18 * props.windowWidth / 1920, 14),
    fontWeight: 'normal',
    marginLeft: 18,
    marginBottom: 23,
    marginRight: 10,
    color: theme.palette.gray.gray_4,
    marginTop: 8,
  },
}));

const styles = theme => ({
  cardIcon: {
    width: 43,
    height: 43,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    maxHeight: 63,
  },
});

function Card(props) {
  const {
    classes,
    icon,
    label,
    linkTo,
    url,
    filter,
    color,
    refresh,
    dimensions,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [quantity, setQuantity] = useState(0);

  const cardStyle = props.cardStyle || {};
  const hookClasses = useStyles({
    windowWidth: dimensions.width,
  });

  const onClick = () => {
    if (linkTo) {
      props.history.push(linkTo);
    }
  }

  useEffect(() => {
    // get current company
    const _fetch = async () => {
      const _data = await fetchAuthenticated(get, `${url}?filter=${JSON.stringify(filter)}`);
      setQuantity(_data.totalItems);
      setIsLoading(false);
    }
    _fetch();
  }, [refresh]);

  return (
    <Grid item xs={3}>
      <div onClick={onClick} className={hookClasses.card} style={{ ...cardStyle }}>
        <div style={{
          backgroundColor: color,
          minWidth: 80,
          borderBottomLeftRadius: 22,
          borderTopLeftRadius: 22,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <ReactSVG
            className={classes.cardIcon}
            src={icon}
            onClick={() => {
              console.log('wrapper onClick')
            }}
          />
        </div>
        <div className={hookClasses.cardInfoContainer}>
          {isLoading && (
            <div className={classes.loading}>
              <CircularProgress style={{ width: 35, height: 35, color: '#808492' }} />
            </div>
          )}
          {!isLoading && (
            <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
              <Typography className={hookClasses.cardQuantity}>{quantity}</Typography>
            </Grow>
          )}
          <Typography className={hookClasses.cardLabel}>{label}</Typography>
        </div>

      </div>
    </Grid>
  )
}

export default withRouter(withStyles(styles)(Card));
