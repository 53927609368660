import React, { createContext, useReducer, useContext } from 'react';

export const SimpleFilterContext = createContext();

export function useSimpleFilterContext() {
  const context = useContext(SimpleFilterContext);
  return context;
}

const ACTIONS = {
  UPDATE_FILTER: 'UPDATE_FILTER',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_FILTER:
      return {
        ...state,
        filterTerms:
          (action.payload.queryObject.filter && action.payload.queryObject.filter.searchText) || []
      };
    default:
      return state;
  }
};

const SimpleFilterProvider = ({ children }) => {
  console.warn(children)
  const [filters, dispatch] = useReducer(reducer, {
    queryFilter: null,
    filterTerms: [],
  });

  const updateFilter = (filter) => {
    dispatch({ type: ACTIONS.UPDATE_FILTER, payload: filter });
  };

  return (
    <SimpleFilterContext.Provider
      value={{
        ...filters,
        updateFilter,
      }}
    >
      {children}
    </SimpleFilterContext.Provider>
  );
};

export default SimpleFilterProvider;
