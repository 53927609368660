import React, { useState, useEffect } from 'react';
import CommandModal from './CommandModal';
import { MenuItem, Grid, TextField, InputAdornment, Button, FormControl, Typography } from '@material-ui/core';
import CircularLoading from 'components/loading/CircularLoading';
import { get, post } from 'services/fetch';
import { makeStyles } from '@material-ui/core/styles';

const MODIFY_PARAMETERS = 'c1c3';

// Parameter options
const PARAMETERS_OPTIONS = [
  { value: '00', label: 'Ignora' },
  { value: '01', label: 'SOS' },
  { value: '10', label: 'Bloqueio' },
]

// Actuators options
const ACTUATORS_OPTIONS = [
  { value: '00', label: 'A1 Sirene e A2 Pisca' },
  { value: '01', label: 'A1 Bloqueio e A2 Sirene' },
  { value: '10', label: 'A1 Bloqueio e A2 Pisca' },
  { value: '11', label: 'A1 Bloqueio e A2 Bloqueio' },
]

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: -15,
    color: theme.palette.gray.gray_5,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  value: {
    fontSize: 12,
  }
}))

// Helper functions
const loadCommands = async (seriesNumber) => {
  const { data } = await get(
    `equipment-event/command?filter={"seriesNumber":"${seriesNumber}","wasSent":false,"canceled":false,"safeloggy.type":"${MODIFY_PARAMETERS}"}&sort={"createdAt": -1}`
  );
  return data;
};

const getLabelByValue = (value, collection) => {
  const filtered = collection.filter(e => e.value === value);
  if (filtered.length === 0 || filtered.length > 1) {
    return ''
  } else {
    return filtered[0].label;
  }
};

const CommandSafeloggyModal = ({ isOpen, setCurrentAction, ...rest }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  const [command, setCommand] = useState({ type: MODIFY_PARAMETERS, parameters: {} });


  /** Handle close modal action */
  const handleClose = () => {
    setOpen(false);
    setCurrentAction && setCurrentAction(null);
  };

  // Valores de intervalo
  // IPS = Intervalo de posição em modo sleep (minuto 5 - 255)
  // IPN = Intervalo de posição em modo normal (segundos 15 - 120)
  // IPSOS =Intervalo de posição em modo SOS (segundos 15 - 120)
  // IIS = tempo para inicio modo sleep (minutos 0 - 15)
  const setValue = (e) => {
    setCommand({ ...command, [e.target.name]: e.target.value });
  };

  // Parâmetros de configuração
  // Jammer, Tamper, SimCard, Violação, Saída de Rota
  // Global Rotas/Cercas, Pânico, Atuadores, Odômetro
  const setParameterValue = (name, value) => {
    setCommand({ ...command, parameters: { ...command.parameters, [name]: value } })
  }

  /** Creates a new safeloggy command */
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      action: 'GPRS',
      seriesNumber: rest.seriesNumber,
      safeloggy: { ...command }
    }

    try {
      setLoading(true);
      const response = await post('equipment-event/command/safeloggy', data)
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      const commands = await loadCommands(rest.seriesNumber);

      if (commands.length) {
        setCommand({
          ...command,
          ...(commands[0].safeloggy || {}),
          type: MODIFY_PARAMETERS
        });
      }
      setLoading(false)
    })();

  }, [])


  return (
    <CommandModal title="Comandos Bloqueador" isOpen={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Grid container justify="flex-start">
          <Typography style={{ fontSize: 14, fontWeight: 'bold', width: '100%' }}>Temporizadores</Typography>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              type="number"
              name="positionSendIntervalSleep"
              label="Fpp sleep"
              style={{ width: '75%' }}
              onChange={setValue}
              value={command['positionSendIntervalSleep'] || ''}
              inputProps={{ min: 5, max: 255 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">minutos</InputAdornment>
              }}
            />
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{rest.repository.positionSendIntervalSleep} minuto(s)</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              type="number"
              name="positionSendInterval"
              label="Fpp normal"
              style={{ width: '75%' }}
              onChange={setValue}
              value={command['positionSendInterval'] || ''}
              inputProps={{ min: 15, max: 120 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">segundos</InputAdornment>
              }}
            />
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{rest.repository.positionSendInterval} segundos</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              type="number"
              name="positionSendIntervalSOS"
              label="Fpp SOS "
              style={{ width: '75%' }}
              onChange={setValue}
              value={command['positionSendIntervalSOS'] || ''}
              inputProps={{ min: 15, max: 120 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">segundos</InputAdornment>
              }}
            />
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{rest.repository.positionSendIntervalSOS} segundos</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              type="number"
              name="timeToStartSleepMode"
              label="Início sleep"
              style={{ width: '75%' }}
              onChange={setValue}
              value={command['timeToStartSleepMode'] || ''}
              inputProps={{ min: 0, max: 255 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">minutos</InputAdornment>
              }}
            />
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{rest.repository.timeToStartSleepMode} minuto(s)</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              type="number"
              label="T. Desb. Jammer"
              name="timeToAutomaticUnblockAfterJammer"
              style={{ width: '75%' }}
              onChange={setValue}
              value={command['timeToAutomaticUnblockAfterJammer'] || ''}
              inputProps={{ min: 0, max: 255 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">minutos</InputAdornment>
              }}
            // helperText="Valor 0 equivale a bloqueio 'para sempre'"
            />
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{rest.repository.timeToAutomaticUnblockAfterJammer} minuto(s)</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          <Typography style={{ fontSize: 14, fontWeight: 'bold', width: '100%', marginTop: 20 }}>Parâmetros</Typography>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Jammer"
              name="jammer"
              style={{ width: '75%' }}
              value={command.parameters['jammer'] || ''}
              onChange={(evt) => setParameterValue('jammer', evt.target.value)}
            >
              {PARAMETERS_OPTIONS.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
              ))}
            </TextField>
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{getLabelByValue(rest.repository.parameters.jammer, PARAMETERS_OPTIONS)}</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Tamper"
              name="tamper"
              style={{ width: '75%' }}
              value={command.parameters['tamper'] || ''}
              onChange={(evt) => setParameterValue('tamper', evt.target.value)}
            >
              {PARAMETERS_OPTIONS.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
              ))}
            </TextField>
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{getLabelByValue(rest.repository.parameters.tamper, PARAMETERS_OPTIONS)}</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="SimCard"
              name="simcard"
              style={{ width: '75%' }}
              value={command.parameters['simcard'] || ''}
              onChange={(evt) => setParameterValue('simcard', evt.target.value)}
            >
              {PARAMETERS_OPTIONS.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
              ))}
            </TextField>
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{getLabelByValue(rest.repository.parameters.simcard, PARAMETERS_OPTIONS)}</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Violação"
              name="violation"
              style={{ width: '75%' }}
              value={command.parameters['violation'] || ''}
              onChange={(evt) => setParameterValue('violation', evt.target.value)}
            >
              {PARAMETERS_OPTIONS.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
              ))}
            </TextField>
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{getLabelByValue(rest.repository.parameters.violation, PARAMETERS_OPTIONS)}</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Saída Rota"
              name="routeExit"
              style={{ width: '75%' }}
              value={command.parameters['routeExit'] || ''}
              onChange={(evt) => setParameterValue('routeExit', evt.target.value)}
            >
              {PARAMETERS_OPTIONS.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
              ))}
            </TextField>
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{getLabelByValue(rest.repository.parameters.routeExit, PARAMETERS_OPTIONS)}</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Global Rotas/Cercas"
              name="globalRoute"
              style={{ width: '75%' }}
              value={command.parameters['globalRoute'] || ''}
              onChange={(evt) => setParameterValue('globalRoute', evt.target.value)}
            >
              <MenuItem value='0'>Desabilitado</MenuItem>
              <MenuItem value='1'>Habilitado</MenuItem>
            </TextField>
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{rest.repository.parameters.globalgeo ? 'Habilitado' : 'Desabilitado'}</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Pânico"
              name="panic"
              style={{ width: '75%' }}
              value={command.parameters['panic'] || ''}
              onChange={(evt) => setParameterValue('panic', evt.target.value)}
            >
              {PARAMETERS_OPTIONS.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
              ))}
            </TextField>
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{getLabelByValue(rest.repository.parameters.panic, PARAMETERS_OPTIONS)}</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Atuadores"
              name="actuators"
              style={{ width: '75%' }}
              value={command.parameters['actuators'] || ''}
              onChange={(evt) => setParameterValue('actuators', evt.target.value)}
            >
              {ACTUATORS_OPTIONS.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
              ))}
            </TextField>
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{getLabelByValue(rest.repository.parameters.actuators, ACTUATORS_OPTIONS)}</Typography>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              select
              label="Odômetro"
              name="odometer"
              style={{ width: '75%' }}
              value={command.parameters['odometer'] || ''}
              onChange={(evt) => setParameterValue('odometer', evt.target.value)}
            >
              <MenuItem value='0'>Manter valor</MenuItem>
              <MenuItem value='1'>Redefinir valor</MenuItem>
            </TextField>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              required
              type="number"
              label="Novo valor do Odômetro"
              name="newOdometerValue"
              style={{ width: '75%' }}
              onChange={setValue}
              value={command['newOdometerValue'] || ''}
              inputProps={{ min: 0 }}
            />
            <Grid className={classes.wrapper}>
              <Typography display="inline" className={classes.label}>Status atual: </Typography>
              <Typography display="inline" className={classes.value}>{rest.repository.odometer}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ padding: '15px 40px' }}
          >Enviar comando</Button>
        </Grid>
      </form>
      <CircularLoading isLoading={loading} />
    </CommandModal>
  );
};

CommandSafeloggyModal.defaultProps = {
};

export default CommandSafeloggyModal;
