import React, { useState, useEffect } from 'react';
import {
  DialogContentText, Grid, InputAdornment, Divider,
} from '@material-ui/core';
import Modal from 'components/modals/Modal';
import RadioGroupField from 'components/fields/RadioGroup.field';
import TextField from 'components/fields/Text.field';
import Fade from '@material-ui/core/Fade';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { useFields } from 'hooks/fields';

function PoffRecycleModal(props) {
  const { equipments, onClose } = props;
  const [optionSelected, setOptionSelected] = useState();
  const fields = useFields(['poffPeriod', 'otherPeriod']);

  const handleChange = (event) => {
    setOptionSelected(event.target.value);
  };

  const handleSubmit = async (equipments, fields) => {
    const action = 'schedule';
    let period = fields.poffPeriod.current.raw;
    if (optionSelected === 'other') {
      period = fields.otherPeriod.current.raw;
      if (!period) {
        period = '0';
      }
    }

    const response = await fetchAuthenticated('post', 'equipment/recycle', {
      period,
      equipments,
    });

    if (response.ok) {
      emitEvent('showSnack', { message: 'Ação realizada com sucesso', type: 'success' });
    } else {
      emitEvent('showSnack', { message: 'Erro ao realizar ação', type: 'error' });
    }

    // Handle modal close
    onClose(true);
  };

  const MODAL_DEFAULT_PROPS = {
    title: 'Reativar equipamento',
    buttonLabel: 'Confirmar',
    onClose: props.onClose,
    isVisible: props.isVisible,
    handleSubmit: () => handleSubmit(equipments, fields),
  };

  const POFF_OPTIONS = [
    { label: '30 dias', value: '30' },
    { label: '60 dias', value: '60' },
    { label: '90 dias', value: '90' },
    { label: 'Outro', value: 'other' },
  ];

  return (
    <Modal {...MODAL_DEFAULT_PROPS} disableButton={!optionSelected}>
      <RadioGroupField
        title="Período"
        ref={fields.poffPeriod}
        options={POFF_OPTIONS}
        onChange={handleChange}
        labelStyle={{ fontWeight: 'bold' }}
        formControlLabelProps={{
          style: { marginLeft: 0, padding: 0 },
        }}
      />
      <Grid item xs={3} style={{ paddingLeft: 15 }}>
        <TextField
          type="number"
          ref={fields.otherPeriod}
          fullWidth
          label="Outro período"
          name="otherPeriod"
          disabled={optionSelected !== 'other'}
          InputProps={{
            endAdornment: <InputAdornment position="end">dias</InputAdornment>,
          }}
        />
      </Grid>
      <Divider style={{ marginBottom: 5 }} />
      <DialogContentText>Os seguintes equipamentos serão reativados</DialogContentText>
      {equipments.map(imei => (
        <DialogContentText>IMEI: {imei}</DialogContentText>
      ))}
    </Modal>
  );
}

PoffRecycleModal.defaultProps = {
  isVisible: false,
  equipments: [],
  onClose: () => null,
};

export default PoffRecycleModal;
