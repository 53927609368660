import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 16,
    height: 16,
  },
  label: {
    fontSize: theme.text.content.fontSize,
    fontWeight: 600,
    color: theme.palette.text.red,
    height: 16,
    lineHeight: '16px',
    marginLeft: 6,
  },
}));

const IconText = ({ children, icon }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img src={icon} className={classes.icon} />
      <Typography className={classes.label}>{children}</Typography>
    </div>
  );
};

export default IconText;
