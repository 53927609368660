import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage  } from 'formik';

export default function _ErrorMessage(props) {
  return (
    <div style={{color: '#f44336', fontFamily: 'Raleway,sans-serif', fontSize: '0.75rem'}}>
    <ErrorMessage {...props} style={{
      color: 'red'
    }}></ErrorMessage>
    </div>
  )
}


export function MyErrorMessage(props) {
  return (
    <>
    {props.isVisible &&
      <div style={{color: '#f44336', marginTop: -30, position: 'absolute', fontFamily: 'Raleway,sans-serif', fontSize: '0.75rem'}}>
        <p style={{color: 'red'}}>
          {props.children}
        </p>
      </div>
    }
    </>
  )
}


