import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { TextField } from '@material-ui/core';
import { Rule, validate } from '../../utils/formValidator';

const NumberInput = forwardRef(({ minValue, maxValue, ...props }, ref) => {
  const inputRef = useRef(null);

  const [value, setValue] = useState('');
  const [rules, setRules] = useState([]);

  const [validations, setValidations] = useState({
    rules: rules,
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const generateErrorMessage = conditions => {
    if (!Object.keys(conditions).length) return '';

    if (!!conditions.min && !!conditions.max)
      return `O valor deve ser entre ${conditions.min} e ${conditions.max}`;

    return `O valor deve ser no ${
      !!conditions.min ? `mínimo ${conditions.min}` : `máximo ${conditions.max}`
    }`;
  };

  useEffect(() => {
    const rulesList = [
      Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
    ];

    const conditions = {};

    // dinamically adding rules
    if (!!minValue) conditions['min'] = minValue;
    if (!!maxValue) conditions['max'] = maxValue;

    rulesList.push(
      Rule('isInt', [conditions], true, generateErrorMessage(conditions))
    );

    setRules(rulesList);
  }, [minValue, maxValue]);

  const onChange = e => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    validateField();
  };

  const validateField = () => {
    let currentRules = props.required || value ? rules : [];
    setValidations({
      rules: rules,
      alreadyValidated: true,
      ...validate(value, currentRules),
    });
  };

  const setFieldValue = value => {
    if (value) {
      setValue(value);
    }
  };

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: value,
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue,
  }));

  return (
    <TextField
      {...props}
      ref={inputRef}
      type="number"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={validations.isInvalid}
      helperText={(validations.isInvalid && validations.message) || ''}
    />
  );
});

export default NumberInput;
