import React, { useState, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'services/fetch';

import { Grid, Button, Divider } from '@material-ui/core';

import CommandToggle from './CommandToggle';
import { CommandTextField } from './Commands';
import { MyCheckbox as Checkbox } from 'components/lists/CheckboxList';
import { minutesToTimeString } from 'utils/helpers';
import { useEffectSkipFirst } from 'hooks/common';

import Permission from 'components/permissions/Permission';

export const getDefaultEnabled = (value, command) => {
  console.log({ value });
  let enabledCommands = {};
  if (command) {
    enabledCommands = Object.keys(command.status || {}).reduce(
      (acc, command) => ({ ...acc, ...{ [command]: true } }),
      {}
    );
    const notStatusFields = [
      'positionSendInterval',
      'positionSendIntervalSleep',
      'positionSendIntervalMotion',
    ];

    if (
      Number.isFinite(parseInt(command.highRangeTemperature)) &&
      Number.isFinite(parseInt(command.lowRangeTemperature))
    ) {
      enabledCommands.rangeTemperature = true;
    }
    enabledCommands = {
      ...enabledCommands,
      ...notStatusFields
        .filter(c => command[c])
        .reduce((acc, command) => ({ ...acc, ...{ [command]: true } }), {}),
    };
  }

  return {
    ...{
      temperatureAlarmMode: value,
      movementSensorMode: value,
      transmitter434Mhz: value,
      transmitter928Mhz: value,
      gpsMode: value,
      hibernationMode: value,
      positionSendInterval: value,
      positionSendIntervalSleep: value,
      positionSendIntervalMotion: value,
      rangeTemperature: value,
      rangeHumidity: value,
    },
    ...enabledCommands,
  };
};

const CommandPv2GprsFields = props => {
  const {
    classes,
    handleSubmit,
    containerStyle,
    disableSubmit,
    handleModifyCommands,
    defaultEnableCommands,
  } = props;

  // missing DecouplingSensorMode
  // missing Beacon alarm
  const { showStatus, canDisable, getData, setData } = props;

  const [positionSendInterval, setPositionSendInterval] = useState(
    minutesToTimeString(props.positionSendInterval)
  );
  const [imei, setImei] = useState(props.imei || '');
  const [positionSendIntervalSleep, setPositionSendIntervalSleep] = useState(
    minutesToTimeString(props.positionSendIntervalSleep)
  );
  const [positionSendIntervalMotion, setPositionSendIntervalMotion] = useState(
    minutesToTimeString(props.positionSendIntervalMotion)
  );
  const [highRangeTemperature, setHighRangeTemperature] = useState(
    props.highRangeTemperature || 0
  );
  const [lowRangeTemperature, setLowRangeTemperature] = useState(
    props.lowRangeTemperature || 0
  );
  // const [lowRangeHumidity, setLowRangeHumidity] = useState(0);
  // const [highRangeHumidity, setHighRangeHumidity] = useState(0);

  const [lastCommand, setLastCommand] = useState({});

  // missing DecouplingSensorMode
  // missing Beacon alarm
  const [status, setStatus] = useState(
    props.status || {
      temperatureAlarmMode: false,
      movementSensorMode: false,
      transmitter434Mhz: false,
      transmitter928Mhz: false,
      gpsMode: false,
      hibernationMode: false,
    }
  );

  const [enabledCommands, setEnabledCommands] = useState(
    getDefaultEnabled(!!defaultEnableCommands)
  );

  useEffect(() => {
    const fetch = async () => {
      const lastCommands = await get(
        `equipment-event/queued-command?imei=${imei}`
      );
      if (lastCommands.length > 0) {
        setLastCommand(lastCommands[0]);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (props.defaultEnabledCommands) {
      setEnabledCommands(props.defaultEnabledCommands);
    }
  }, [props.defaultEnabledCommands]);

  useEffect(() => {
    if (lastCommand.imei) {
      if (lastCommand.positionSendInterval) {
        setPositionSendInterval(lastCommand.positionSendInterval);
      }
      if (lastCommand.positionSendIntervalSleep) {
        setPositionSendIntervalSleep(lastCommand.positionSendIntervalSleep);
      }
      if (lastCommand.positionSendIntervalMotion) {
        setPositionSendIntervalMotion(lastCommand.positionSendIntervalMotion);
      }
      if (lastCommand.highRangeTemperature) {
        setHighRangeTemperature(lastCommand.highRangeTemperature);
      }
      if (lastCommand.lowRangeTemperature) {
        setLowRangeTemperature(lastCommand.lowRangeTemperature);
      }
      // if (lastCommand.lowRangeHumidty) { setLowRangeHumidity(lastCommand.lowRangeHumidity); }
      // if (lastCommand.highRangeHumidty) { setHighRangeHumidity(lastCommand.highRangeHumidity); }
      if (lastCommand.status) {
        setStatus(lastCommand.status);
      }
    }
  }, [lastCommand]);

  useEffect(() => {
    if (props.initialValues) {
      const { initialValues } = props;
      if (initialValues.positionSendInterval) {
        setPositionSendInterval(initialValues.positionSendInterval);
      }
      if (initialValues.positionSendIntervalSleep) {
        setPositionSendIntervalSleep(initialValues.positionSendIntervalSleep);
      }
      if (initialValues.positionSendIntervalMotion) {
        setPositionSendIntervalMotion(initialValues.positionSendIntervalMotion);
      }
      if (initialValues.highRangeTemperature) {
        setHighRangeTemperature(initialValues.highRangeTemperature);
      }
      if (initialValues.lowRangeTemperature) {
        setLowRangeTemperature(initialValues.lowRangeTemperature);
      }
      // if (lastCommand.lowRangeHumidty) { setLowRangeHumidity(lastCommand.lowRangeHumidity); }
      // if (lastCommand.highRangeHumidty) { setHighRangeHumidity(lastCommand.highRangeHumidity); }
      if (initialValues.status) {
        setStatus(initialValues.status);
      }
    }
  }, [props.initialValues]);

  const makeCommand = () => {
    const _data = { imei };
    let commandsLogs = {};

    if (enabledCommands.positionSendInterval) {
      _data.positionSendInterval = positionSendInterval;
      commandsLogs = {
        ...commandsLogs,
        positionSendInterval: {
          value: positionSendInterval,
          label: 'Período envio de dados',
        },
      };
    }
    if (enabledCommands.positionSendIntervalSleep) {
      _data.positionSendIntervalSleep = positionSendIntervalSleep;
      commandsLogs = {
        ...commandsLogs,
        positionSendIntervalSleep: {
          value: positionSendIntervalSleep,
          label: 'Período envio modo sleep',
        },
      };
    }
    if (enabledCommands.positionSendIntervalMotion) {
      _data.positionSendIntervalMotion = positionSendIntervalMotion;
      commandsLogs = {
        ...commandsLogs,
        positionSendIntervalMotion: {
          value: positionSendIntervalMotion,
          label: 'Período envio modo motion',
        },
      };
    }
    if (enabledCommands.rangeTemperature) {
      _data.highRangeTemperature = highRangeTemperature;
      commandsLogs = {
        ...commandsLogs,
        rangeTemperature: {
          value: `${lowRangeTemperature}:${highRangeTemperature}`,
          label: 'Range temperatura',
        },
      };
    }
    if (enabledCommands.rangeTemperature) {
      _data.lowRangeTemperature = lowRangeTemperature;
      commandsLogs = {
        ...commandsLogs,
        rangeTemperature: {
          value: `${lowRangeTemperature}:${highRangeTemperature}`,
          label: 'Range temperatura',
        },
      };
    }

    const _status = {};
    if (enabledCommands.temperatureAlarmMode) {
      _status.temperatureAlarmMode = status.temperatureAlarmMode || false;
      commandsLogs = {
        ...commandsLogs,
        temperatureAlarmMode: {
          value: status.temperatureAlarmMode ? 'Ligado' : 'Desligado',
          label: 'Alarme Temperatura',
        },
      };
    }
    if (enabledCommands.movementSensorMode) {
      _status.movementSensorMode = status.movementSensorMode || false;
      commandsLogs = {
        ...commandsLogs,
        movementSensorMode: {
          value: status.movementSensorMode ? 'Ligado' : 'Desligado',
          label: 'Alarme Movimento',
        },
      };
    }
    if (enabledCommands.transmitter434Mhz) {
      _status.transmitter434Mhz = status.transmitter434Mhz || false;
      commandsLogs = {
        ...commandsLogs,
        transmitter434Mhz: {
          value: status.transmitter434Mhz ? 'Ligado' : 'Desligado',
          label: 'RF(434MH)',
        },
      };
    }
    if (enabledCommands.transmitter928Mhz) {
      _status.transmitter928Mhz = status.transmitter928Mhz || false;
      commandsLogs = {
        ...commandsLogs,
        transmitter928Mhz: {
          value: status.transmitter928Mhz ? 'Ligado' : 'Desligado',
          label: 'RF(928MH)',
        },
      };
    }
    if (enabledCommands.gpsMode) {
      _status.gpsMode = status.gpsMode || false;
      commandsLogs = {
        ...commandsLogs,
        gpsMode: {
          value: status.gpsMode ? 'Ligado' : 'Desligado',
          label: 'Modo GPS',
        },
      };
    }
    if (enabledCommands.hibernationMode) {
      _status.hibernationMode = status.hibernationMode || false;
      commandsLogs = {
        ...commandsLogs,
        hibernationMode: {
          value: status.hibernationMode ? 'Ligado' : 'Desligado',
          label: 'Ativar Hibernação',
        },
      };
    }

    if (handleModifyCommands) {
      handleModifyCommands(commandsLogs);
    }

    _data.status = _status;

    return { ..._data };
  };

  const _handleSubmit = () => {
    const _data = makeCommand();

    handleSubmit({
      ..._data,
    });

    return { ..._data };
  };

  useEffect(() => {
    setData(makeCommand());
  }, [
    status,
    enabledCommands,
    positionSendInterval,
    positionSendIntervalSleep,
    positionSendIntervalMotion,
    highRangeTemperature,
    lowRangeTemperature,
  ]);

  return (
    <div style={containerStyle}>
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.gpsMode}
        data={{ label: 'Modo GPS', name: 'gpsMode' }}
        status={status}
        setStatus={setStatus}
        isEnabled={enabledCommands.gpsMode}
        toggleEnabled={
          canDisable
            ? value =>
                setEnabledCommands({ ...enabledCommands, gpsMode: value })
            : null
        }
      />
      <Permission names={['PermissionConfigureRF']}>
        <CommandToggle
          showStatus={showStatus}
          currentStatus={props.status && props.status.transmitter434Mhz}
          data={{ label: 'RF(434 MH)', name: 'transmitter434Mhz' }}
          status={status}
          setStatus={setStatus}
          isEnabled={enabledCommands.transmitter434Mhz}
          toggleEnabled={
            canDisable
              ? value =>
                  setEnabledCommands({
                    ...enabledCommands,
                    transmitter434Mhz: value,
                  })
              : null
          }
        />
        <CommandToggle
          showStatus={showStatus}
          currentStatus={props.status && props.status.transmitter928Mhz}
          data={{ label: 'RF(928 MH)', name: 'transmitter928Mhz' }}
          status={status}
          setStatus={setStatus}
          isEnabled={enabledCommands.transmitter928Mhz}
          toggleEnabled={
            canDisable
              ? value =>
                  setEnabledCommands({
                    ...enabledCommands,
                    transmitter928Mhz: value,
                  })
              : null
          }
        />
      </Permission>

      <Permission names={['PermissionConfigureAlarmMovement']}>
        <CommandToggle
          showStatus={showStatus}
          currentStatus={props.status && props.status.movementSensorMode}
          data={{ label: 'Alarme Movimento', name: 'movementSensorMode' }}
          status={status}
          setStatus={setStatus}
          isEnabled={enabledCommands.movementSensorMode}
          toggleEnabled={
            canDisable
              ? value =>
                  setEnabledCommands({
                    ...enabledCommands,
                    movementSensorMode: value,
                  })
              : null
          }
        />
      </Permission>
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.temperatureAlarmMode}
        data={{ label: 'Alarme Temperatura', name: 'temperatureAlarmMode' }}
        status={status}
        setStatus={setStatus}
        isEnabled={enabledCommands.temperatureAlarmMode}
        toggleEnabled={
          canDisable
            ? value =>
                setEnabledCommands({
                  ...enabledCommands,
                  temperatureAlarmMode: value,
                })
            : null
        }
      />
      <CommandToggle
        showStatus={showStatus}
        currentStatus={props.status && props.status.hibernationMode}
        data={{ label: 'Ativar hibernação', name: 'hibernationMode' }}
        status={status}
        setStatus={setStatus}
        isEnabled={enabledCommands.hibernationMode}
        toggleEnabled={
          canDisable
            ? value =>
                setEnabledCommands({
                  ...enabledCommands,
                  hibernationMode: value,
                })
            : null
        }
      />
      <Grid container>
        <Grid
          container
          alignItems="center"
          style={{
            width: 'auto',
            opacity: enabledCommands.positionSendInterval ? 1 : 0.4,
          }}
        >
          {canDisable && (
            <Checkbox
              setValue={value =>
                setEnabledCommands({
                  ...enabledCommands,
                  positionSendInterval: value,
                })
              }
              checked={enabledCommands.positionSendInterval}
              value={enabledCommands.positionSendInterval}
            />
          )}
          <Grid item>
            <Grid item className={classes.textFieldContainer}>
              <p className={classes.inputLabel}>Período envio de dados</p>
              <Grid container>
                <Grid item style={{ width: '100%' }}>
                  <CommandTextField
                    name="positionSendInterval"
                    value={positionSendInterval}
                    setValue={setPositionSendInterval}
                    currentStatus={minutesToTimeString(
                      props.positionSendInterval
                    )}
                    showStatus={showStatus}
                    timeMask={true}
                    disabled={!enabledCommands.positionSendInterval}
                    style={{ marginRight: 0, width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          style={{
            width: 'auto',
            opacity: enabledCommands.positionSendIntervalSleep ? 1 : 0.4,
          }}
        >
          {canDisable && (
            <Checkbox
              setValue={value =>
                setEnabledCommands({
                  ...enabledCommands,
                  positionSendIntervalSleep: value,
                })
              }
              checked={enabledCommands.positionSendIntervalSleep}
              value={enabledCommands.positionSendIntervalSleep}
            />
          )}
          <Grid item>
            <Grid item className={classes.textFieldContainer}>
              <p className={classes.inputLabel}>Período envio modo sleep</p>
              <Grid container>
                <Grid item style={{ width: '100%' }}>
                  <CommandTextField
                    name="positionSendIntervalSleep"
                    value={positionSendIntervalSleep}
                    setValue={setPositionSendIntervalSleep}
                    currentStatus={minutesToTimeString(
                      props.positionSendIntervalSleep
                    )}
                    showStatus={showStatus}
                    timeMask={true}
                    disabled={!enabledCommands.positionSendIntervalSleep}
                    style={{ marginRight: 0, width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          style={{
            width: 'auto',
            opacity: enabledCommands.positionSendIntervalMotion ? 1 : 0.4,
          }}
        >
          {canDisable && (
            <Checkbox
              setValue={value =>
                setEnabledCommands({
                  ...enabledCommands,
                  positionSendIntervalMotion: value,
                })
              }
              checked={enabledCommands.positionSendIntervalMotion}
              value={enabledCommands.positionSendIntervalMotion}
            />
          )}
          <Grid item>
            <Grid item className={classes.textFieldContainer}>
              <p className={classes.inputLabel}>Período envio modo motion</p>
              <Grid container>
                <Grid item style={{ width: '100%' }}>
                  <CommandTextField
                    name="positionSendIntervalMotion"
                    value={positionSendIntervalMotion}
                    setValue={setPositionSendIntervalMotion}
                    currentStatus={minutesToTimeString(
                      props.positionSendIntervalMotion
                    )}
                    showStatus={showStatus}
                    timeMask={true}
                    disabled={!enabledCommands.positionSendIntervalMotion}
                    style={{ marginRight: 0, width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid
          container
          alignItems="center"
          style={{
            width: 'auto',
            opacity: enabledCommands.rangeTemperature ? 1 : 0.4,
          }}
        >
          {canDisable && (
            <Checkbox
              setValue={value =>
                setEnabledCommands({
                  ...enabledCommands,
                  rangeTemperature: value,
                })
              }
              checked={enabledCommands.rangeTemperature}
              value={enabledCommands.rangeTemperature}
            />
          )}
          <Grid item>
            <Grid
              container
              direction="column"
              className={classes.textFieldContainer}
            >
              <p className={classes.inputLabel}>Range temperatura</p>
              <Grid container>
                <Grid item className={classes.textFieldContainer}>
                  <CommandTextField
                    name="lowRangeTemperature"
                    value={lowRangeTemperature}
                    setValue={setLowRangeTemperature}
                    currentStatus={props.lowRangeTemperature}
                    showStatus={showStatus}
                    disabled={!enabledCommands.rangeTemperature}
                  />
                </Grid>
                <Grid item className={classes.textFieldContainer}>
                  <CommandTextField
                    name="highRangeTemperature"
                    value={highRangeTemperature}
                    setValue={setHighRangeTemperature}
                    currentStatus={props.highRangeTemperature}
                    showStatus={showStatus}
                    disabled={!enabledCommands.rangeTemperature}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!disableSubmit && (
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: 50, marginBottom: 10 }}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.sendCommandBtn}
            onClick={_handleSubmit}
          >
            Enviar comando
          </Button>
        </Grid>
      )}
    </div>
  );
};
const styles = theme => ({
  inputLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  timePicker: {
    borderBottom: theme.input.borderStyle.borderBottom,
    minHeight: 'unset',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  divider: {
    height: 1,
    marginTop: 26,
  },
  input: {
    borderBottom: theme.input.borderStyle.borderBottom,
  },
  sendCommandBtn: {
    fontWeight: 600,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
  },
  textFieldContainer: {
    width: 'max-content',
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
    },
  },
  textField: {
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
    },
  },
});

CommandPv2GprsFields.defaultProps = {
  disableSubmit: false,
  getData: false,
  defaultEnabledCommands: null,
  setData: () => {},
};

export default withStyles(styles)(CommandPv2GprsFields);
