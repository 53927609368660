// General imports
import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import SubBar from 'components/SubBar';

// Material-ui imports
import { Typography, Button, Grid, Paper, withStyles } from '@material-ui/core';

// icon imports
import StableIcon from 'assets/icon/dashboard/stable.png';
import PositionIcon from 'assets/icon/dashboard/position.png';
import BatteryIcon from 'assets/icon/dashboard/battery.png';
import EmergencyIcon from 'assets/icon/dashboard/emergency.png';
import NoPositionIcon from 'assets/icon/dashboard/no_position.png';

// filter imports
import { InputAdornment } from '@material-ui/core';
import Filter from 'components/filter/Filter';
import FilterAutoComplete from 'components/filter/FilterAutoComplete';
import Search from '@material-ui/icons/Search';
import { buildUrl, defaultQuery } from 'utils/query';
import Permission from 'components/permissions/Permission';

// hook imports
import { useAuthenticated, useFetch } from 'hooks/fetch';
import { useEffectSkipFirst } from 'hooks/common';

// utils
import { updateQueryFilter } from 'utils/query';
import { getEquipmentRowActions } from 'utils/equipment';
import {
  datetimeFormatter,
  percentageFormatter,
  latlongFormatter,
  poffFormatter,
} from 'utils/formatters';

// general project imports
import { CommandGprs, CommandAdmin } from 'components/commands/Commands';
import SimpleTable from 'components/table/Table';
import { StatusColumn } from 'components/table/columns';
import ModalObs from 'components/modals/ModalObs';
import TableMenu, { TableColumn } from 'components/table/TableMenu';
import {
  isRfOn,
  getPositionIndicator,
  minutesToTimeString,
} from 'utils/helpers';
import { emitEvent } from 'utils/events';
import { getBreadcrumbsFor } from 'utils/breadcrumbs';
import { useAccessLog } from 'hooks/logs';

import theme from 'styles/theme';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';

import { saveAs } from 'file-saver';
import { logAction } from 'utils/logs';
import { fetchAuthenticated } from 'services/fetch';

import HomeFilterContext from 'pages/HomeFilterContext';

const EquipmentList = props => {
  const filterContext = useContext(HomeFilterContext);

  const genericSearchFields = [
    { label: 'Imei', name: 'imei' },
    { label: 'Código', name: 'code' },
    { label: 'Descrição', name: 'description' },
    { label: 'Tipo', name: 'equipmentType.name' },
    { label: 'Nome da Empresa', name: 'currentCompany.name' },
    { label: 'Cidade', name: 'currentCompany.addressCity.nome' },
    { label: 'Estado', name: 'currentCompany.addressState.nome' },
    { label: 'Veículo', name: 'vehicle' },
  ];

  const { classes, location, match, history } = props;
  const situation = match.params.situation;
  const situationInfo = {
    'total-ativos': { label: 'Total de equipamentos', color: '#538cc1' },
    estaveis: {
      situation: 'STABLE',
      label: 'Equipamentos estáveis',
      color: '#5bf293',
    },
    'bateria-baixa': {
      situation: 'LOW_BATTERY',
      label: 'Equipamentos com bateria baixa',
      color: '#fbe622',
    },
    'sem-posicionar': {
      situation: 'NO_POSITIONING',
      label: 'Equipamentos sem posicionar',
      color: '#ffc500',
    },
    'posicoes-atrasadas': {
      situation: 'DELAYED_POSITIONS',
      label: 'Equipamentos com posições atrasadas',
      color: '#fd8f44',
    },
    'aguardando-ativacao': {
      situation: 'SCHEDULED',
      label: 'Equipamentos aguardando ativação',
      color: '#ffa263',
    },
    expirados: {
      situation: 'COMPLETED',
      label: 'Equipamentos expirados',
      color: '#e58744',
    },
    'sem-bateria': {
      situation: 'NO_BATTERY',
      label: 'Equipamentos sem bateria',
      color: '#ec6a69',
    },
    'jammer-detectado': {
      situation: 'JAMMING_DETECTED',
      label: 'Equipamentos em emergência',
      color: '#e44a4a',
    },
  };

  useAccessLog(
    `Acesso a lista de ${situationInfo[situation].label.toLowerCase()}`
  );

  const breadcrumbs = getBreadcrumbsFor(location.pathname);

  const [reload, setReload] = useState(false);
  const onModalObsSubmit = () => {
    setReload(!reload);
  };

  const [chips, setChips] = useState(
    (filterContext && filterContext.searchTerm) || []
  );

  let queryObject = {
    sort: { 'lastEvent.timestamp': -1 },
    filter: {
      'currentCompany._id': {
        ...(filterContext.company
          ? { value: filterContext.company._id, toObjectId: false }
          : { $exists: true }),
      },
      lastEvent: { $exists: true },
      isActive: true,
      ...(filterContext.equipment && { _id: filterContext.equipment._id }),
      ...(filterContext.searchTerm && {
        searchText: filterContext.searchTerm,
        genericSearchFields: genericSearchFields.map(field => field.name),
      }),
    },
  };

  if (situationInfo[situation].situation) {
    // set default query
    queryObject.filter = {
      ...queryObject.filter,
      ...(situationInfo[situation].additionalFilters || {}),
      situation: situationInfo[situation].situation,
    };
  }

  const [query, setQuery] = useState({
    queryString: `?filter=${JSON.stringify(
      queryObject.filter
    )}&sort=${JSON.stringify(queryObject.sort)}`,
    queryObject,
  });

  const basePath = 'equipment/situation';
  const [fetchPath, setFetchPath] = useState(
    buildUrl(basePath, location) + query.queryString
  );

  // Load equipments from server
  const { data } = useAuthenticated(useFetch, fetchPath, {
    beforeFetch: () => {
      emitEvent('showGlobalLinearProgress');
    },
    afterFetch: () => {
      emitEvent('hideGlobalLinearProgress');
    },
    reload,
  });

  // Update query string
  // necessary to filters to work
  useEffectSkipFirst(() => {
    if (filterContext) {
      filterContext.updateSearchTerm(query.queryObject.filter.searchText);
    }

    if (query.queryString) {
      setFetchPath(`${basePath}${query.queryString}`);
    }
  }, [query]);

  const handleExportXLS = async query => {
    try {
      const response = await fetchAuthenticated(
        'GET',
        `equipment/report/xls${query.queryString}`
      );

      const blob = await response.blob();
      saveAs(blob, `Relatorio_Equipamentos_${situation}.xls`);
      logAction('Exportou relatório de expirados em XLS');
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const onMenuItemClick = () => {
    setReload(!reload);
  };
  const rowStatusActions = getEquipmentRowActions(onMenuItemClick);
  const rowActions = [
    {
      label: 'Comandos GPRS',
      component: props => {
        return <CommandGprs showStatus={true} isVisible={true} {...props} />;
      },
    },
    {
      label: 'Comandos Administrativos',
      component: props => {
        return <CommandAdmin showStatus={true} isVisible={true} {...props} />;
      },
    },
    {
      label: 'Observações',
      component: props => {
        return (
          <ModalObs
            onSubmit={onModalObsSubmit}
            equipment={props}
            isVisible={true}
            {...props}
          />
        );
      },
    },
  ];

  const columns = [
    { label: 'Equip.', value: row => row.shortImei, sort: 'shortImei' },
    { label: 'Placa', value: row => row.vehicle, sort: 'vehicle' },
    {
      label: 'Tipo',
      value: row => row.equipmentType && row.equipmentType.name,
      sort: 'equipmentType.name',
    },
    {
      label: 'Empresa',
      value: row => row.currentCompany && row.currentCompany.name,
      sort: 'currentCompany.name',
    },
    {
      label: 'Bat.',
      value: row =>
        (row.lastEvent &&
          `${percentageFormatter(
            Number(
              (row.lastEvent.adjustedInternalBatteryPercentage ||
                row.lastEvent.internalBatteryPercentage) / 100
            )
          )}`) ||
        '-',
      sort: 'lastEvent.internalBatteryPercentage',
    },
    { label: 'Exp.', value: row => poffFormatter(row) },
    {
      label: 'Transmissão',
      value: row => row.lastEvent && getPositionIndicator(row.lastEvent, true),
    },
    {
      label: 'Sinal',
      value: row =>
        row.lastEvent &&
        row.lastEvent.gsmModelSignal &&
        `${Math.round(
          (parseInt(row.lastEvent.gsmModelSignal, 10) / 31) * 100
        )}%`,
      sort: 'lastEvent.gsmModelSignal',
    },
    {
      label: 'Data/hora',
      value: row =>
        (row.lastEvent && `${datetimeFormatter(row.lastEvent.timestamp)}`) ||
        '',
      sort: 'lastEvent.timestamp',
    },
    { label: 'RF', value: row => isRfOn(row.lastEvent) },
    {
      label: 'FPP.',
      value: row =>
        row.lastEvent &&
        minutesToTimeString(row.lastEvent.positionSendInterval),
      sort: 'lastEvent.positionSendInterval',
    },
    {
      label: 'Local',
      value: row => row.lastEvent && row.lastEvent.local,
      sort: 'lastEvent.local',
    },
    {
      label: 'Lat/Long',
      value: row =>
        (row.lastEvent &&
          `${latlongFormatter(
            row.lastEvent.latitude,
            row.lastEvent.longitude
          )}`) ||
        '',
      sort: '',
    },
    {
      label: 'Status',
      component: row => (
        <Permission
          key={'Status' + row._id}
          name="EditEquipmentStatus"
          ifNoPermission={
            <TableColumn>
              <StatusColumn edit={false} status={row.handlingStatus} />
            </TableColumn>
          }
        >
          <TableMenu
            row={row}
            rowActions={rowStatusActions}
            icon={<StatusColumn status={row.handlingStatus} />}
          />
        </Permission>
      ),
    },
    {
      label: '',
      component: (row, col) => (
        <Permission
          key={'Config' + row._id}
          names={[
            'SendGPRSCommand',
            'SendAdminCommand',
            'EditEquipmentObservations',
          ]}
        >
          <TableMenu row={row} rowActions={rowActions}></TableMenu>
        </Permission>
      ),
    },
  ];

  const filterAsyncFields = [
    {
      name: 'companies',
      component: args => {
        return (
          <FilterAutoComplete
            {...args}
            menuContainerStyle={{
              marginTop: 5,
            }}
            placeholder="Empresa"
            showPlaceholder={true}
            hideLabel={true}
            path="company?notPaginated=true"
            name="companies"
            getOptionLabel={opt => opt.name}
            loadingMessage="Carregando empresas..."
            noOptionsMessage="Nenhuma empresa encontrada."
            onChange={args => {
              const { opt, fields, setIsLoading, isLoading, filterHandler } =
                args;
              // reset equipments filter
              fields.equipments.current.resetField();
              setIsLoading({ ...isLoading, equipments: true });
              filterContext.updateCompany(opt);
              if (!opt) {
                filterHandler.removeFilter('currentCompany._id');
              } else {
                // update equipments available for filtering
                filterHandler.updateQueryFilter({
                  'currentCompany._id': { value: opt._id, toObjectId: false },
                });
              }
            }}
            value={filterContext.company}
          />
        );
      },
    },
    {
      name: 'equipments',
      component: args => {
        return (
          <FilterAutoComplete
            {...args}
            menuContainerStyle={{
              marginTop: 5,
            }}
            placeholder="Equipamentos"
            showPlaceholder={true}
            hideLabel={true}
            path={
              'equipment/list?fields=_id,imei&' + query.queryString.substr(1)
            }
            name="equipments"
            getOptionLabel={opt => opt.imei}
            loadingMessage="Carregando equipamentos..."
            noOptionsMessage="Nenhum equipamento encontrado."
            onChange={args => {
              const { opt, filterHandler } = args;
              filterContext.updateEquipment(opt);
              if (!opt) {
                filterHandler.removeFilter('_id');
              } else {
                filterHandler.updateQueryFilter({
                  _id: opt._id,
                });
              }
            }}
            whenIsLoading={({
              fetchUrl,
              path: defaultPath,
              setFetchUrl,
              fields,
            }) => {
              const companyField = fields.companies.current.value;
              if (companyField) {
                setFetchUrl(
                  updateQueryFilter(fetchUrl, {
                    'currentCompany._id': {
                      value: companyField._id,
                      toObjectId: false,
                    },
                  })
                );
              } else {
                setFetchUrl(defaultPath);
              }
            }}
            value={filterContext.equipment}
          />
        );
      },
    },
  ];

  return (
    <div>
      <SubBar breadcrumbs={breadcrumbs} items={[]} match={match}></SubBar>
      <div
        style={{
          backgroundColor: situationInfo[situation].color,
          height: 5,
          width: '100%',
        }}
      ></div>
      <div className={classes.root}>
        {/* <div className={classes.header}> */}
        {/* <img src={situationInfo[situation].icon} className={classes.cardIcon}></img> */}
        {/* <Typography className={classes.headerText}>{situationInfo[situation].label}</Typography> */}
        {/* </div> */}
        <Filter
          searchPlaceHolder="Buscar"
          query={query}
          setQuery={setQuery}
          containerStyle={{ paddingLeft: 0 }}
          asyncFields={filterAsyncFields}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.searchIcon}>
                <Search />
              </InputAdornment>
            ),
          }}
          showPlaceholder={true}
          hideLabel={true}
          genericSearchFields={genericSearchFields}
          defaultChips={chips}
        />
        <Paper elevation={0} className={classes.paper}>
          <SimpleTable
            columns={columns}
            data={data}
            query={query}
            setQuery={setQuery}
            onRowClick={(row, col) => {
              history.push(`/detalhes-equipamento/${row._id}`, {
                from: location,
              });
            }}
            HeaderComponent={
              <TableHeader
                headerActions={[
                  <TableHeaderAction
                    title="Exportar XLS"
                    IconProps={{ style: theme.custom.icon }}
                    Icon={props => <Typography {...props}>XLS</Typography>}
                    disabled={data && data.totalItems === 0}
                    onClick={() => handleExportXLS(query)}
                  />,
                ]}
              />
            }
          />
        </Paper>
      </div>
    </div>
  );
};

const styles = theme => ({
  root: {
    // paddingTop: theme.main.padding.top,
    // paddingBottom: theme.main.padding.bottom,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gray.gray_4,
    fontSize: 22,
    lineHeight: 'normal',
  },
  paper: {
    display: 'flex',
    borderRadius: theme.border.radius,
  },
  button: {
    textTransform: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: theme.main.padding.bottom,
  },
  headerText: {
    marginLeft: 16,
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_1,
  },
  cardIcon: {
    width: 34,
    height: 34,
  },
  searchIcon: {
    color: theme.palette.gray.gray_5,
  },
});

export default withRouter(withStyles(styles)(EquipmentList));
