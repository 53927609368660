import React, { useState } from "react";
import { postWithErrorHandler } from "services/fetch";
import CommandModal from "./CommandModal";
import CommandPv1GprsFields from "./CommandPv1GprsFields";

const CommandPv1Gprs = (props) => {
  const [imei, setImei] = useState(props.imei || "");

  const [showModal, setShowModal] = useState(true);

  const [commandsV1ToLog, setCommandsV1ToLog] = useState();

  const createLogMessage = () => {
    const message = [];

    for (const c in commandsV1ToLog) {
      message.push(`${commandsV1ToLog[c].label}: ${commandsV1ToLog[c].value}`);
    }

    return message.join(" / ");
  };

  const handleModifyV1Commands = (newCommandsState) => {
    setCommandsV1ToLog(newCommandsState);
  };

  const handleSubmit = async (data) => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }

    const logMessage = `Comando GPRS enviado ao equipamento ${imei.slice(-8)}`;
    const successMessage = `Comando enviado com sucesso.`;
    const errorMessage = `Erro ao tentar enviar comando.`;
    const response = await postWithErrorHandler(
      "equipment-event/command",
      {
        imei,
        ...data,
        action: "GPRS",
        logMessage: createLogMessage(),
      },
      {
        successMessage,
        errorMessage,
        logMessage,
      }
    );
  };

  const onClose = () => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }
  };

  return (
    <CommandModal
      isOpen={showModal}
      onClose={onClose}
      title="Comandos GPRS Protocolo 1"
    >
      <CommandPv1GprsFields
        {...props}
        handleSubmit={handleSubmit}
        handleModifyCommands={handleModifyV1Commands}
        defaultEnableCommands
      />
    </CommandModal>
  );
};

export default CommandPv1Gprs;
