import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import AlertIcon from "../../assets/img/alert.png";
import Card from "./Card";

const styles = (theme) => ({
  dashboardCardsContainer: {
    width: "100%",
    display: "flex",
    'flex-wrap': 'wrap',
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  grid: {
    flexGrow: 1,
    marginTop: 10,
  }
});

function CardContainer(props) {
  const { classes, refresh } = props;
  const [cards] = useState(props.cards);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className={classes.grid}>
      <Grid container spacing={3}>
        {cards.map((item, index) => {
          return (
            <Card
              refresh={refresh}
              dimensions={windowDimensions}
              {...item}
              key={item._id}
            />
          );
        })}
      </Grid>
      {/* <div className={classes.dashboardCardsContainer}>
      {cards.map((item, index) => {
        return (
          <Card
            refresh={refresh}
            dimensions={windowDimensions}
            cardStyle={{
              marginTop: 20,
            }}
            {...item}
            key={item._id}
          />
        );
      })}
    </div> */}
    </div>
  );
}

export default withStyles(styles)(CardContainer);
