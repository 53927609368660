// Form validation based on
// https://medium.com/code-monkey/client-side-form-validation-in-react-40e367de47ba
import validator from 'validator';
import PasswordValidator from 'password-validator';

const passwordSchema = new PasswordValidator();

passwordSchema
  .is()
  .min(8) // Checks if the password has at least 8 characters
  .has()
  .letters() // Checks if the password has at least one letter (case-insensitive)
  .has()
  .digits() // checks if the password has at least one number
  .has()
  .symbols() // checks if the password has at least one symbol
  .has()
  .not()
  .spaces(); // checks if it has no spaces

export const Rule = (method, args, validWhen, message, formatFn) => {
  return {
    method,
    args,
    validWhen,
    message,
    run(value) {
      const m = typeof method === 'string' ? validator[method] : method;
      formatFn = formatFn ? formatFn : value => value.toString();
      if (m(formatFn(value), ...args) !== validWhen) {
        return { isInvalid: true, message: message };
      } else {
        return { isInvalid: false, message: '' };
      }
    },
  };
};

export const validate = (value, rules) => {
  for (const rule of rules) {
    const validation = rule.run(value);
    if (validation.isInvalid) {
      return validation;
    }
  }
  return { isInvalid: false, message: '' };
};

export const validatePassword = password => {
  return passwordSchema.validate(password);
};
