import React, { useState, useEffect, useContext, useRef } from 'react';
import { ResponsiveLine } from '@nivo/line';
import * as moment from 'moment';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import theme from 'styles/theme';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';

import { makeStyles } from '@material-ui/core/styles';

import ChartContainer from 'components/charts/ChartContainer';
import ReportFilters from 'components/filter/ReportFilters';

import { useQuery } from 'hooks/fetch';
import { useAccessLog } from 'hooks/logs';
import { emitEvent } from 'utils/events';
import { getField } from 'utils/helpers';
import { getTemperatureColor } from 'utils/colors';
import { fetchAuthenticated } from 'services/fetch';
import { logAction } from 'utils/logs';

import ReportFilterContext from 'pages/Reports/ReportFilterContext';
import { getDateFiltersFromContext } from 'pages/Reports/helpers';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
}));

const counters = {
  'Temp. Baú': 0,
  'Temperatura': 0,
  'Umidade': 0,
};

const MAX_EVENTS=250;

const PATH = 'equipment-event/temperature-chart-report';
function TemperatureChartReport() {
  useAccessLog('Acesso ao relatório gráfico de temperaturas');
  const classes = useStyles();
  const filterContext = useContext(ReportFilterContext);
  const [dataSet, setDataSet] = useState([]);
  const [tickValues, setTickValues] = useState('every minute');
  const [data, setData] = useState([]);
  const chartRef = useRef(null);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const defaultFilter = {
    'imei': filterContext.equipment ? filterContext.equipment.imei : undefined,
    $expr: { $and: getDateFiltersFromContext(filterContext, '$timestamp') },
  }
  if (filterContext.company) {
    defaultFilter['equipment.currentCompany._id'] = {
      value: filterContext.company._id,
      toObjectId: false,
    }
  }

  const [dataInitalDateFilled, setDataInitalDateFilled] = useState(false);
  const [dataFinalDateFilled, setDataFinalDateFilled] = useState(false);

  const [query, setQuery, isLoading] = useQuery(
    PATH,
    setData,
    ['imei', 'timestamp', 'temperature', 'temperatureHumidity'],
    {
      filter: defaultFilter,
      sort: {
        timestamp: -1,
      },
      limit: MAX_EVENTS,
    },
    (q) => {
      if(!dataInitalDateFilled|| !dataFinalDateFilled) {
        return false;
      }
      const qObject = q.queryObject;
      return Boolean(qObject && qObject.filter && qObject.filter.imei);
    },
  );

  useEffect(() => {
    const _raw = Array.isArray(data) ? data : data.data || [];
    const _set = {};

    if (_raw.length === MAX_EVENTS) {
      emitEvent('showSnack', {
        message: `Exibidas somente as últimas ${MAX_EVENTS} informações`,
        type: 'info',
      });
    }

    if (_raw.length) {
      const dInitial = moment(_raw[0].timestamp);
      const dFinal = moment(_raw[_raw.length - 1].timestamp);
      const duration = moment.duration(dInitial.diff(dFinal));

      if (Math.floor(Math.abs(duration.as('years'))) > 2) {
        setTickValues('every year');
      } else if (Math.floor(Math.abs(duration.as('years'))) > 0) {
        setTickValues('every month');
      } else if (Math.floor(Math.abs(duration.as('months'))) > 3) {
        setTickValues('every month');
      } else if (Math.floor(Math.abs(duration.as('months'))) > 0) {
        setTickValues('every week');
      } else if (Math.floor(Math.abs(duration.as('days'))) > 10) {
        setTickValues('every 2 days');
      } else if (Math.floor(Math.abs(duration.as('days'))) > 3) {
        setTickValues('every day');
      } else if (Math.floor(Math.abs(duration.as('days'))) > 0) {
        setTickValues('every 5 hours');
      } else if (Math.floor(Math.abs(duration.as('hours'))) > 6) {
        setTickValues('every 2 hours');
      } else if (Math.floor(Math.abs(duration.as('hours'))) > 2) {
        setTickValues('every 30 minutes');
      } else if (Math.floor(Math.abs(duration.as('hours'))) > 0) {
        setTickValues('every 10 minutes');
      } else if (Math.floor(Math.abs(duration.as('minutes'))) > 15) {
        setTickValues('every 5 minutes');
      }
    }

    if (_raw.length) {
      _set.Temperatura = { data: [] };
      _set.Umidade = { data: [] };
      _set['Temp. Baú'] = { data: [] };
    }
    _raw.forEach((entry) => {
      _set.Temperatura.data.push({
        x: entry.timestamp,
        y: entry.temperature,
        unit: 'º C',
      });
      _set.Umidade.data.push({
        x: entry.timestamp,
        y: getField(entry, 'temperatureHumidity.humidity', 0),
        unit: '%',
      });
      _set['Temp. Baú'].data.push({
        x: entry.timestamp,
        y: getField(entry, 'temperatureHumidity.temperature', 0),
        unit: 'ºC',
      });
    });

    const _dataset = Object.keys(_set).reduce(
      (previous, current) => [
        ...previous,
        {
          id: current,
          data: _set[current].data,
        },
      ],
      [],
    );

    Object.keys(counters).forEach((k) => {
      counters[k] = 0;
    });

    setDataSet(_dataset);
  }, [data]);

  const onDateChange = async ({ date, filterHandler }, comparator) => {
    let _andClause = [];

    if (query && query.queryObject) {
      const _obj = { ...query.queryObject.filter };

      // Check if filter already exists and removes it
      if (_obj && _obj.$expr && _obj.$expr.$and) {
        _andClause = _obj.$expr.$and.filter(exp => !exp.hasOwnProperty(`${comparator}`));
      }
    }

    if (date) {
      _andClause.push({
        [`${comparator}`]: ['$timestamp', { $dateFromString: { dateString: date } }],
      });
    }

    // It removes add an empty _andClause if no value is supplied and the
    // correct value otherwise
    filterHandler.updateQueryFilter({
      $expr: { $and: _andClause },
    });
  };

  const onCompanyChange = async ({ opt, filterHandler: fh }) => {
    filterContext.updateCompany(opt);
    const _key = 'equipment.currentCompany._id';

    if (opt) {
      fh.updateQueryFilter({ [_key]: {
        value: filterContext.company._id,
        toObjectId: false,
      }});
    } else {
      fh.removeFilter(_key);
    }
  };

  const onEquipmentChange = async ({ opt, filterHandler: fh }) => {
    filterContext.updateEquipment(opt);
    const _key = 'imei';

    if (opt) {
      fh.updateQueryFilter({ [_key]: opt.imei });
    } else {
      fh.removeFilter(_key);
    }
  };

  const exportPdf = () => {
    const metadata = {};

    console.log(filterContext);
    if (filterContext.company) {
      metadata.company = filterContext.company.name;
    }

    if (filterContext.equipment) {
      metadata.equipment = filterContext.equipment.code;
      metadata.imei = filterContext.equipment.imei;
      metadata.company = filterContext.equipment.currentCompany.name;
    }

    if (filterContext.initialDate) {
      metadata.from = moment(filterContext.initialDate).format('DD/MM/YYYY HH:mm');
    }

    if (filterContext.finalDate) {
      metadata.to = moment(filterContext.finalDate).format('DD/MM/YYYY HH:mm');
    }

    setIsGeneratingPdf(true);
    const element = chartRef.current;
    const cloneElement = element.cloneNode(true);

    // GAMBI: Retira o elemento da parte visivel da tela;
    element.style.position = 'absolute';
    element.style.width = '1920px';
    element.style.height = '720px';
    element.style.left = '-9999px';
    element.style.pointerEvents = 'none';

    // const div = document.createElement('div');

    element.parentNode.replaceChild(cloneElement, element);
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(element);

    // GAMBI: Timeout utilizado pra permitir que o component termine de renderizar
    setTimeout(() => {
      try {
        html2canvas(element, { width: 1920, height: 1080 }).then((canvas) => {
          const chart = canvas.toDataURL('images/png');

          (async () => {
            const response = await fetchAuthenticated(
              'post',
              'equipment/report/temperature/chart',
              { chart, metadata: JSON.stringify(metadata) },
            );
            const blob = await response.blob();
            saveAs(blob, 'Relatorio_Grafico_Temperatura.pdf');
            logAction('Exportou relatório gráfico de temperatura em PDF');
          })();
        });
      } catch (err) {
        console.log(err);
      } finally {
        // GAMBI: Recoloca o elemento no local origianal
        element.style.position = '';
        element.style.width = '';
        element.style.height = '';
        element.style.left = '';
        element.style.pointerEvents = '';
        cloneElement.parentNode.replaceChild(element, cloneElement);
        setIsGeneratingPdf(false);
      }
    }, 500);
  };

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <ReportFilters
          path={PATH}
          query={query}
          setQuery={setQuery}
          values={filterContext}
          onCompanyChange={onCompanyChange}
          onEquipmentChange={onEquipmentChange}
          onInitialDateChange={({ date, filterHandler }) => {
            setDataInitalDateFilled(date ? true : false);
            filterContext.updateInitialDate(date);
            onDateChange({ date, filterHandler }, '$gte');
          }}
          onFinalDateChange={({ date, filterHandler }) => {
            setDataFinalDateFilled(date ? true : false);
            filterContext.updateFinalDate(date);
            onDateChange({ date, filterHandler }, '$lte');
          }}
        />
      </Grid>
      <ChartContainer
        ref={chartRef}
        isLoading={isLoading || isGeneratingPdf}
        HeaderComponent={(
          <TableHeader
            headerActions={[
              <TableHeaderAction
                title="Exportar PDF"
                IconProps={{ style: theme.custom.icon }}
                Icon={props => <Typography {...props}>PDF</Typography>}
                onClick={() => exportPdf()}
              />,
            ]}
          />
        )}
      >
        <ResponsiveLine
          data={dataSet}
          margin={{
            top: 50,
            right: 50,
            bottom: 50,
            left: 50,
          }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%dT%H:%M:%S.%f%Z',
            precision: 'minute',
          }}
          xFormat="time:%d/%m/%Y %H:%M:%S"
          yScale={{
            type: 'linear',
            stacked: false,
            min: -10,
            max: 100,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: '%d/%m/%Y %H:%M',
            tickValues,
            legend: '',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Temperatura',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          colors={{ scheme: 'nivo' }}
          pointSize={10}
          pointColor={(args) => {
            const { data, id } = args;
            let color = 'transparent';
            try {
              if (id !== 'Umidade') {
                color = getTemperatureColor(data[counters[id]].data.y);
                counters[id] += 1;
              }
            } catch (err) {
              counters[id] += 1;
            }

            if (counters[id] > data.length) {
              counters[id] =  0;
            }

            return color;
          }}
          pointBorderWidth={2}
          pointBorderColor="transparent"
          pointLabel="y"
          pointLabelYOffset={-12}
          enableSlices="x"
          useMesh={true}
          sliceTooltip={({ slice }) => {
            return (
              <div style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px', padding: '5px 9px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>Data: <strong>{slice.points[0].data.xFormatted.toString()}</strong></div>
                  {/* <div>Data: <strong>teste</strong></div> */}
                  { slice.points.map((p, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{
                        marginRight: 5,
                        width: 10,
                        height: 10,
                        backgroundColor: p.id.startsWith('Umidade') ? p.serieColor : getTemperatureColor(p.data.y),
                        display: 'inline-block',
                      }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ display: 'inline-block' }}>
                        {p.serieId}: <strong>{p.data.yFormatted} {p.data.unit}</strong>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 50,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 150,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </ChartContainer>
    </Grid>
  );
}

TemperatureChartReport.defaultProps = {};

export default TemperatureChartReport;
