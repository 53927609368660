import React, { useState, useMemo } from 'react';

import { Grid, Paper, Typography } from '@material-ui/core';

import ModalConfirm from 'components/modals/ModalConfirm';
import CommandPv2GprsFields from 'components/commands/CommandPv2GprsFields';
import { AutocompleteField } from 'components/fields';
import DelimeterTextField, {
  DELIMETERS,
} from 'components/fields/DelimeterTextField';

import { fetchAuthenticated } from 'services/fetch';
import { logAction } from 'utils/logs';
import { emitEvent } from 'utils/events';
import { useAccessLog } from 'hooks/logs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    paddingTop: theme.main.padding.top,
    paddingBottom: 25,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  main: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 15,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 15,
    },
    flex: 1,
  },
  paperRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 22,
    padding: '25px 32px',
  },
  title: {
    color: theme.palette.gray.gray_4,
    fontWeight: theme.typography.fontWeightBold,
  },
  subtitle: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  modalTypography: {
    fontSize: 16,
    color: '#41475a',
  },
}));

function FotaCommands(props) {
  useAccessLog('Acesso a ferramenta de FOTA');
  const classes = useStyles();

  const [confirmCommand, setConfirmCommand] = useState(false);
  const [dataToSend, setDataToSend] = useState({});
  const [delimeter, setDelimeter] = useState(DELIMETERS[0]);
  const [isSendingCommand, setIsSendingCommand] = useState(false);

  const [commandsV2ToLog, setCommandsV2ToLog] = useState();
  const [equipmentList, setEquipmentList] = useState([]);

  // dinamically informs the user about the amount of equipment to be altered
  const confirmModalLabel = useMemo(() => {
    if (!equipmentList.length) return 'Nenhum equipamento será afetado';

    return equipmentList.length > 1
      ? `${equipmentList.length} equipamentos serão afetados`
      : `${equipmentList.length} equipamento será afetado`;
  }, [equipmentList]);

  const handleModifyV2Commands = newCommandsState => {
    setCommandsV2ToLog(newCommandsState);
  };

  const confirmAction = data => {
    setConfirmCommand(true);
    setDataToSend(data);
  };

  const createLogMessage = () => {
    const message = [];
    for (const command in commandsV2ToLog) {
      message.push(
        `${commandsV2ToLog[command].label}: ${commandsV2ToLog[command].value}`
      );
    }

    return message.join(' / ');
  };

  const handleSubmit = async data => {
    try {
      const bulk = {
        bulk: true,
        imeis: equipmentList,
        logMessage: createLogMessage(),
        ...data,
      };
      setIsSendingCommand(true);
      const response = fetchAuthenticated(
        'post',
        'equipment-event/command',
        bulk
      );
      setIsSendingCommand(false);
      setConfirmCommand(false);

      if (response.status >= 400) {
        emitEvent('showSnack', {
          message: 'Não foi possível realizar esta ação',
          type: 'error',
        });
      } else {
        logAction(`Enviou comandos em massa`);
        emitEvent('showSnack', {
          message: 'Ação realizada com sucesso',
          type: 'success',
        });
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      {/* Main Content */}
      <Grid container item style={{ flex: 1 }} alignItems="flex-start">
        <Grid container item sm={12} md={5} xl={4} style={{ flex: 1 }}>
          <Paper
            classes={{ root: classes.paperRoot }}
            elevation={0}
            style={{ maxHeight: 'fit-content' }}
          >
            <Typography variant="h6" className={classes.title}>
              Equipamentos
            </Typography>
            <Typography className={classes.subtitle}>
              Insira os IMEIs completos de cada equipamento, separando-os com o
              delimitador selecionado
            </Typography>
            <Grid container item style={{ flex: 1 }} direction="column">
              <DelimeterTextField
                tags={equipmentList}
                setTags={setEquipmentList}
                delimeter={delimeter}
              />
              <Grid item style={{ marginTop: 32 }}>
                <AutocompleteField
                  label="Delimitador"
                  value={delimeter}
                  onChange={value => setDelimeter(value)}
                  options={DELIMETERS}
                  getOptionLabel={opt => opt.label}
                  isClearable={false}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item className={classes.main}>
          <Paper classes={{ root: classes.paperRoot }} elevation={0}>
            <Typography variant="h6" className={classes.title}>
              Comandos
            </Typography>
            <Grid container item direction="column">
              <CommandPv2GprsFields
                handleSubmit={confirmAction}
                canDisable
                handleModifyCommands={handleModifyV2Commands}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {confirmCommand && (
        <ModalConfirm
          title="Confirmar envio de comando"
          isOpen={confirmCommand}
          setIsOpen={setConfirmCommand}
          onCancel={() => setConfirmCommand(false)}
          onConfirm={() => handleSubmit(dataToSend)}
          confirmButtonLabel="Sim, confirmar"
          isLoading={isSendingCommand}
        >
          <Typography className={classes.modalTypography}>
            Tem certeza que deseja enviar este comando?
          </Typography>
          <Typography className={classes.modalTypography}>
            {confirmModalLabel}
          </Typography>
        </ModalConfirm>
      )}
    </Grid>
  );
}

export default FotaCommands;
