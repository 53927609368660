import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';

import theme from 'styles/theme';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import ReportFilters from 'components/filter/ReportFilters';

import { REPORT_COLUMNS } from './columns';
import { useQuery } from 'hooks/fetch';
import { usePaginatedData } from 'hooks/common';
import { useAccessLog } from 'hooks/logs';
import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import ReportFilterContext from 'pages/Reports/ReportFilterContext';
import { getDateFiltersFromContext } from 'pages/Reports/helpers';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
}));

const PATH = 'equipment-event';
function HistoryReport(props) {
  useAccessLog('Acesso ao relatório de histórico');
  const classes = useStyles();
  const filterContext = useContext(ReportFilterContext);
  const [data, setData] = usePaginatedData();

  const [dataInitalDateFilled, setDataInitalDateFilled] = useState(false);
  const [dataFinalDateFilled, setDataFinalDateFilled] = useState(false);

  const defaultFilter = {
    imei: filterContext.equipment ? filterContext.equipment.imei : undefined,
    $expr: { $and: getDateFiltersFromContext(filterContext, '$timestamp') },
  };
  if (filterContext.company) {
    defaultFilter['equipment.currentCompany._id'] = {
      value: filterContext.company._id,
      toObjectId: false,
    };
  }

  const [query, setQuery, isLoading] = useQuery(
    PATH,
    setData,
    [
      'equipment.shortImei',
      'equipment.currentCompany',
      'timestamp',
      'positionSendInterval',
      'internalBatteryPercentage',
      'adjustedInternalBatteryPercentage',
      'gpsSpeed',
      'gpsSatelliteNumber',
      'positionIndicator',
      'rfTriangulation',
      'temperatureHumidity',
      'temperature',
      'gsmModelSignal',
      'kmDistance',
      'local',
      'imei',
      'latitude',
      'longitude',
      'status',
      'jamming',
      'protocolVersion',
      'lastValidPositionDate',
      'lastValidPositionHour',
      'communicationType',
    ],
    {
      filter: defaultFilter,
      sort: {
        timestamp: -1,
      },
    },
    q => {
      if (!dataInitalDateFilled || !dataFinalDateFilled) {
        return false;
      }
      const obj = q.queryObject;
      return Boolean(obj && obj.filter && obj.filter.imei);
    },
    false,
    {
      removeFilters: ['equipment.currentCompany._id'],
    }
  );

  const [isGeneretingReport, setIsGeneratinReport] = useState(false);

  const exportSpreadsheet = async query => {
    setIsGeneratinReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', {
        message: 'Exportação limitada a 5000 itens',
        type: 'info',
      });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/history/xls${query.queryString}`
    );
    logAction('Exportou relatório de histório em XLS');
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Historico.xls');
    setIsGeneratinReport(false);
  };

  const exportPdf = async query => {
    setIsGeneratinReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', {
        message: 'Exportação limitada a 5000 itens',
        type: 'info',
      });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/history/pdf${query.queryString}`
    );

    logAction('Exportou relatório de histório em PDF');
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Historico.pdf');
    setIsGeneratinReport(false);
  };

  const onCompanyChange = async ({ opt, filterHandler }) => {
    filterContext.updateCompany(opt);
    if (!opt) {
      filterHandler.removeFilter('equipment.currentCompany._id');
    } else {
      filterHandler.updateQueryFilter({
        'equipment.currentCompany._id': {
          value: opt._id,
          toObjectId: false,
        },
      });
    }
  };

  const onEquipmentChange = async ({ opt, filterHandler }) => {
    filterContext.updateEquipment(opt);
    if (!opt) {
      filterHandler.removeFilter('imei');
    } else {
      filterHandler.updateQueryFilter({ imei: opt.imei });
    }
  };

  const onDateChange = async ({ date, filterHandler }, comparator) => {
    let _andClause = [];

    if (query && query.queryObject) {
      const _obj = { ...query.queryObject.filter };

      // Check if filter already exists and removes it
      if (_obj && _obj.$expr && _obj.$expr.$and) {
        _andClause = _obj.$expr.$and.filter(
          exp => !exp.hasOwnProperty(`${comparator}`)
        );
      }
    }

    if (date) {
      _andClause.push({
        [`${comparator}`]: [
          '$timestamp',
          { $dateFromString: { dateString: date } },
        ],
      });
    }

    // It removes add an empty _andClause if no value is supplied and the
    // correct value otherwise
    filterHandler.updateQueryFilter({
      $expr: { $and: _andClause },
    });
  };

  const _tableHeaderActions = [
    <TableHeaderAction
      title="Exportar XLS"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>XLS</Typography>}
      onClick={() => exportSpreadsheet(query)}
    />,
    <TableHeaderAction
      title="Exportar PDF"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>PDF</Typography>}
      onClick={() => exportPdf(query)}
    />,
  ];

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <ReportFilters
          path={PATH}
          query={query}
          setQuery={setQuery}
          values={filterContext}
          onCompanyChange={onCompanyChange}
          onEquipmentChange={onEquipmentChange}
          onInitialDateChange={({ date, filterHandler }) => {
            setDataInitalDateFilled(date ? true : false);
            filterContext.updateInitialDate(date);
            onDateChange({ date, filterHandler }, '$gte');
          }}
          onFinalDateChange={({ date, filterHandler }) => {
            setDataFinalDateFilled(date ? true : false);
            filterContext.updateFinalDate(date);
            onDateChange({ date, filterHandler }, '$lte');
          }}
        />
      </Grid>
      <Table
        columns={REPORT_COLUMNS}
        data={data}
        query={query}
        setQuery={setQuery}
        containerStyle={{
          marginTop: 0,
        }}
        HeaderComponent={<TableHeader headerActions={_tableHeaderActions} />}
        isLoading={isLoading || isGeneretingReport}
      />
    </Grid>
  );
}

HistoryReport.defaultProps = {};

export default HistoryReport;
