import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TagsInput from 'react-tagsinput';
import Tag from 'components/Tag';

export const DELIMETERS = [
  {
    label: 'Vírgula',
    value: ',',
    code: 188, // comma key code
  },
  {
    label: 'Ponto',
    value: '.',
    code: 190,
  },
  {
    label: 'Quebra de Linha',
    value: '\n',
    code: 13,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 16,
    height: 500,
    maxHeight: 500,
    border: 'solid 1px #808492',
    borderRadius: 22,
    outline: 'none',
    padding: 22,
    overflowY: 'overlay',
  },
  textInput: {
    fontWeight: '400',
    outline: 'none',
    border: 'none',
    fontFamily: 'Raleway',
    color: theme.palette.text.black,
    fontSize: 15,
  },
}));

const DelimeterTextField = ({ tags, setTags, delimeter = DELIMETERS[0] }) => {
  const classes = useStyles();

  const handleChange = newTags => {
    setTags(newTags);
  };

  const handlePastedText = data => {
    const formattedDataArray = [];
    const formattedDataString = data.trim();
    const rawDataArray = formattedDataString.split(delimeter.value);

    for (const item of rawDataArray) {
      const trimmedItem = item.trim();

      if (!trimmedItem.length) return;

      formattedDataArray.push(trimmedItem);
    }

    return formattedDataArray;
  };

  const handleTagDelete = tag => () => {
    setTags(tags.filter(item => item != tag));
  };

  const renderInputComponent = props => {
    return (
      <input
        {...props}
        autoFocus
        placeholder=""
        className={classes.textInput}
        type="text"
      />
    );
  };

  const renderTag = ({ tag, key }) => (
    <Tag content={tag} key={key} deleteItem={handleTagDelete(tag)} />
  );

  return (
    <TagsInput
      addOnPaste
      onlyUnique
      addKeys={[delimeter.code]}
      value={tags}
      onChange={handleChange}
      renderTag={renderTag}
      renderInput={renderInputComponent}
      pasteSplit={handlePastedText}
      className={classes.root}
    />
  );
};

export default DelimeterTextField;
