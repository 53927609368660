import React, { useRef, useState, useEffect, useContext } from 'react';
import * as moment from 'moment';

import L from 'leaflet';
import 'leaflet.motion/dist/leaflet.motion';
import 'leaflet-simple-map-screenshoter';

import { saveAs } from 'file-saver';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import theme from 'styles/theme';
import TableHeaderAction from 'components/table/TableHeaderAction';

import { makeStyles } from '@material-ui/core/styles';

import PathReportMap from './map';
import ChartContainer from 'components/charts/ChartContainer';
import ReportFilters from 'components/filter/ReportFilters';

import { addPointsToMap } from 'utils/helpers';
import { useQuery } from 'hooks/fetch';
import { useAccessLog } from 'hooks/logs';
import { fetchAuthenticated } from 'services/fetch';
import { logAction } from 'utils/logs';

import ReportFilterContext from 'pages/Reports/ReportFilterContext';
import { getDateFiltersFromContext } from 'pages/Reports/helpers';

const MAP_INFO = {
  tileLayer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  attribution:
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
};

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
}));

const PATH = 'equipment-event/path-report';
function PathReport() {
  useAccessLog('Acesso ao relatório de trajetos');
  const classes = useStyles();
  const filterContext = useContext(ReportFilterContext);

  const [data, setData] = useState([]);
  const [points, setPoints] = useState([]);
  const chartRef = useRef(null);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const defaultFilter = {
    imei: filterContext.equipment ? filterContext.equipment.imei : undefined,
    $expr: { $and: getDateFiltersFromContext(filterContext, '$timestamp') },
    timestamp: { $exists: true },
  };

  const [dataInitalDateFilled, setDataInitalDateFilled] = useState(false);
  const [dataFinalDateFilled, setDataFinalDateFilled] = useState(false);

  const [query, setQuery, isLoading] = useQuery(
    PATH,
    setData,
    // Removido isPositionValid pois quando tem ele não aparece itens com posicionamento cellId
    ['imei', 'timestamp', 'latitude', 'longitude'],
    {
      filter: defaultFilter,
      sort: {
        timestamp: 1,
      },
      limit: 1000,
    },
    q => {
      if (!dataInitalDateFilled || !dataFinalDateFilled) {
        return false;
      }
      const qObject = q.queryObject;
      return Boolean(qObject && qObject.filter && qObject.filter.imei);
    }
  );

  const onDateChange = async ({ date, filterHandler }, comparator) => {
    let _andClause = [];

    if (query && query.queryObject) {
      const _obj = { ...query.queryObject.filter };

      // Check if filter already exists and removes it
      if (_obj && _obj.$expr && _obj.$expr.$and) {
        _andClause = _obj.$expr.$and.filter(
          exp => !exp.hasOwnProperty(`${comparator}`)
        );
      }
    }

    if (date) {
      _andClause.push({
        [`${comparator}`]: [
          '$timestamp',
          { $dateFromString: { dateString: date } },
        ],
      });
    }

    // It removes add an empty _andClause if no value is supplied and the
    // correct value otherwise
    filterHandler.updateQueryFilter({
      $expr: { $and: _andClause },
    });
  };

  const onCompanyChange = async ({ opt, filterHandler: fh }) => {
    filterContext.updateCompany(opt);
  };

  const onEquipmentChange = async ({ opt, filterHandler: fh }) => {
    filterContext.updateEquipment(opt);
    const _key = 'imei';
    opt ? fh.updateQueryFilter({ [_key]: opt.imei }) : fh.removeFilter(_key);
  };

  useEffect(() => {
    const _points = [];
    data.forEach(d => {
      // Removido d.isPositionValid pois quando existe cellId o isPositionValid não fica true
      if (d.latitude !== '0' && d.longitude !== '0') {
        _points.push(d);
      }
    });
    setPoints(_points);
  }, [data]);

  const exportPdf = () => {
    setIsGeneratingPdf(true);
    const metadata = {};

    if (filterContext.company) {
      metadata.company = filterContext.company.name;
    }

    if (filterContext.equipment) {
      metadata.equipment = filterContext.equipment.code;
      metadata.imei = filterContext.equipment.imei;
      metadata.company = filterContext.equipment.currentCompany.name;
    }

    if (filterContext.initialDate) {
      metadata.from = moment(filterContext.initialDate).format(
        'DD/MM/YYYY HH:mm'
      );
    }

    if (filterContext.finalDate) {
      metadata.to = moment(filterContext.finalDate).format('DD/MM/YYYY HH:mm');
    }

    const mapElement = document.createElement('div');
    const mapDiv = document.createElement('div');
    mapDiv.id = 'pdfmap';

    // mapElement.style.left = '-9999px';
    mapElement.style.width = '1920px';
    mapElement.style.height = '720px';
    mapElement.style.position = 'absolute';
    mapElement.style.left = '-9999px';

    mapDiv.style.width = '100%';
    mapDiv.style.height = '100%';
    mapDiv.style.borderRadius = '22px';

    mapElement.appendChild(mapDiv);
    document.getElementsByTagName('body')[0].appendChild(mapElement);

    const pdfMap = L.map('pdfmap', {
      zoom: 1,
      zoomControl: true,
    }).setView([-14.3908906, -53.6396429], 4);

    const tiles = L.tileLayer(MAP_INFO.tileLayer, {
      attribution: MAP_INFO.attribution,
    }).addTo(pdfMap);

    if (points && points.length) {
      const coords = (points || []).map(p => [p.latitude, p.longitude]);
      const bounds = new L.LatLngBounds(coords);

      pdfMap.fitBounds(bounds.pad(0.5), { maxZoom: 15 });
      addPointsToMap(pdfMap, points);

      L.motion
        .polyline(
          coords,
          {
            color: '#41475A',
          },
          {
            auto: true,
            duration: 0,
          },
          {
            removeOnEnd: true,
            icon: L.divIcon({ html: '<span />', iconSize: { x: 0, y: 0 } }),
          }
        )
        .addTo(pdfMap);
    }

    const printMap = L.simpleMapScreenshoter().addTo(pdfMap);

    let time = null;

    tiles.on('load', () => {
      if (!time) {
        time = setTimeout(() => {
          printMap
            .takeScreen('image')
            .then(chart => {
              try {
                (async () => {
                  const response = await fetchAuthenticated(
                    'post',
                    'equipment/report/path/chart',
                    { chart, metadata: JSON.stringify(metadata) }
                  );
                  const blob = await response.blob();
                  saveAs(blob, 'Relatorio_Trajeto.pdf');

                  document
                    .getElementsByTagName('body')[0]
                    .removeChild(mapElement);

                  logAction('Exportou relatório de trajeto em PDF');
                  setIsGeneratingPdf(false);
                })();
              } catch (err) {
                console.log('error');
                console.log(err);
                setIsGeneratingPdf(false);
              }
            })
            .catch(e => {
              console.log('catch');
              console.log(e);
              setIsGeneratingPdf(false);
            });
        }, 1000);
      }
    });
  };

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <ReportFilters
          path={PATH}
          query={query}
          setQuery={setQuery}
          values={filterContext}
          onCompanyChange={onCompanyChange}
          onEquipmentChange={onEquipmentChange}
          onInitialDateChange={({ date, filterHandler }) => {
            setDataInitalDateFilled(date ? true : false);
            filterContext.updateInitialDate(date);
            onDateChange({ date, filterHandler }, '$gte');
          }}
          onFinalDateChange={({ date, filterHandler }) => {
            setDataFinalDateFilled(date ? true : false);
            filterContext.updateFinalDate(date);
            onDateChange({ date, filterHandler }, '$lte');
          }}
        />
      </Grid>
      <ChartContainer
        ref={chartRef}
        isLoading={isLoading || isGeneratingPdf}
        paperStyle={{ padding: 0 }}
      >
        <TableHeaderAction
          noHover
          style={{ right: 32, top: 15, position: 'absolute', zIndex: 401 }}
          title="Exportar PDF"
          IconProps={{ style: theme.custom.icon }}
          variant="dark"
          Icon={props => <Typography {...props}>PDF</Typography>}
          onClick={() => exportPdf()}
        />
        <PathReportMap points={points} />
      </ChartContainer>
    </Grid>
  );
}

PathReport.defaultProps = {};

export default PathReport;
