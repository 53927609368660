import {
  lastValidPositionDateFormatter,
  defaultFormatter,
  percentageFormatter,
} from 'utils/formatters';

export const REPORT_COLUMNS = [
  {
    label: 'IMEI',
    value: ({ shortImei }) => defaultFormatter(shortImei),
  },
  {
    label: 'IMEI Completo',
    value: ({ imei }) => defaultFormatter(imei),
  },
  {
    label: 'CCID',
    value: ({ iccid }) => defaultFormatter(iccid),
  },
  {
    label: 'Empresa',
    value: ({ currentCompany }) =>
      defaultFormatter(currentCompany && currentCompany.name),
  },
  {
    label: 'Operadora',
    value: ({ lastEvent }) =>
      defaultFormatter(lastEvent && lastEvent.simCardOperator.name),
  },
  {
    label: 'Sinal',
    value: ({ lastEvent }) =>
      `${percentageFormatter(
        Number(lastEvent && lastEvent.gsmModelSignal / 31)
      )}`,
  },
  {
    label: 'Última posição válida',
    value: ({ lastEvent }) =>
      lastValidPositionDateFormatter(
        lastEvent && lastEvent.lastValidPositionDate,
        lastEvent && lastEvent.lastValidPositionHour
      ),
    sort: 'lastEvent.lastValidPositionDate',
  },
];
